import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const ScheduleConsultDialog = (props) => {
  return (
    <Dialog
      fullWidth={true}
      open={props.open}
    >
      <DialogTitle>Schedule Consultation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have not entered a facilitator and/or site ID. Because you purchased a product that includes
          the guidance of an Advance Care Planning Educator, you will need to schedule an appointment before proceeding.

          You may go back now and enter the IDs or you can continue to schedule the consultation.
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{display: "flex", justifyContent:"space-between"}}>
        <button
          onClick={props.handleClose}
          className="mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          onClick={props.handleScheduling}
          className="mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Schedule Consult
        </button>
      </DialogActions>
    </Dialog>
  )
}