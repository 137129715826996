import React, { useRef, useState, useEffect } from "react";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikSelect, FormikTextInput } from "../../../../Features/Forms";
import { submitDirectivesInformation } from "../../../../../data/mutations/NewPatient/submitDirectivesInformation";
import { LivingWill } from "./LivingWill";
import { POLST } from "./POLST";
import { PowerOfAttorney } from "./PowerOfAttorney";
import { uploadToCloudStorage } from "./uploadToCloudStorage";
import { unlockFile } from "./unlockFile";
import { uploadToPublicCloudStorage } from "./uploadToPublicCloudStorage";
import {
  Typography,
  TextField,
  Stack,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";

import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { submitGoalsInformation } from "../../../../../data/mutations/NewPatient/submitGoalsInformation";
import { UploadingIndicator } from "./UploadingIndicator";
import { submitOtherDocumentsInformation } from "../../../../../data/mutations/NewPatient/submitOtherDocumentsInformation";
export const Directives = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [livingWillURI, setLivingWillURI] = useState("");
  const [POLSTExists, setPOLSTExists] = useState(false);
  const [POLSTURI, setPOLSTURI] = useState("");
  const [powerOfAttorneyExists, setPowerOfAttorneyExists] = useState(false);
  const [powerOfAttorneyURI, setPowerOfAttorneyURI] = useState("");
  const [token, setToken] = useState();

  const [livingWill, setLivingWill] = useState(false);
  const [toggleAdvancedDirective, setToggleAdvancedDirective] = useState(null);
  const [toggleOrganDonor, setToggleOrganDonor] = useState(null);
  const [togglednr, setTogglednr] = useState(null);
  const [togglepolst, setTogglepolst] = useState(null);

  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  const handleLivingWill = async (e) => {
    setUploading(true);
    const blob = e.target.files[0];
    const file = new File([blob], "livingWill", { type: blob.type });
    const payload = {
      uid: patientId,
      folder: "files",
      file: file,
      token: token,
    };
    const tempURI = await uploadToPublicCloudStorage(payload);
    setLivingWill(true);
    setLivingWillURI(tempURI);
    setUploading(false);
  };

  const handlePOLST = async (e) => {
    setUploading(true);
    const blob = e.target.files[0];
    const file = new File([blob], "POLST", { type: blob.type });
    const payload = {
      uid: patientId,
      folder: "files",
      file: file,
      token: token,
    };
    const tempURI = await uploadToPublicCloudStorage(payload);
    setPOLSTURI(tempURI);
    setUploading(false);
  };

  useEffect(() => {
    console.log(chart?.LIVING_WILL);
    if (
      chart?.LIVING_WILL?.livingWillURI !== "" &&
      chart?.LIVING_WILL?.livingWillURI
    ) {
      setLivingWill(true);
      setLivingWillURI(chart?.LIVING_WILL?.livingWillURI);
    }
    if (chart?.LIVING_WILL?.POLSTURI !== "" && chart?.LIVING_WILL?.POLSTURI) {
      setPOLSTExists(true);
      setPOLSTURI(chart?.LIVING_WILL?.POLSTURI);
    }
    if (
      chart?.LIVING_WILL?.powerOfAttorneyURI !== "" &&
      chart?.LIVING_WILL?.powerOfAttorneyURI
    ) {
      setPowerOfAttorneyExists(true);
      setPowerOfAttorneyURI(chart?.LIVING_WILL?.powerOfAttorneyURI);
    }
  }, [patientId, chart]);

  console.log("livingWill", livingWill);

  const handlePowerOfAttorney = async (e) => {
    setUploading(true);
    const blob = e.target.files[0];
    const file = new File([blob], "powerOfAttorney", { type: blob.type });
    const payload = {
      uid: patientId,
      folder: "files",
      file: file,
      token: token,
    };
    const tempURI = await uploadToPublicCloudStorage(payload);
    setPowerOfAttorneyExists(true);
    setPowerOfAttorneyURI(tempURI);
    setUploading(false);
  };

  useEffect(() => {
    if (
      chart?.LIVING_WILL?.organDonor === "true" ||
      chart?.LIVING_WILL?.organDonor === true
    ) {
      setToggleOrganDonor(true);
    } else if (
      chart?.LIVING_WILL?.organDonor === "false" ||
      chart?.LIVING_WILL?.organDonor === false
    ) {
      setToggleOrganDonor(false);
    } else if (chart && chart?.LIVING_WILL?.organDonor === "TBD") {
      setToggleOrganDonor("TBD");
    } else {
      setToggleOrganDonor(null);
    }

    if (
      chart?.LIVING_WILL?.livingWill === "true" ||
      chart?.LIVING_WILL?.livingWill
    ) {
      setLivingWill(true);
    } else if (
      chart?.LIVING_WILL?.livingWill === "false" ||
      chart?.LIVING_WILL?.livingWill === false
    ) {
      setLivingWill(false);
    } else {
      setLivingWill(null);
    }

    if (
      chart?.LIVING_WILL?.advanceDirective === "true" ||
      chart?.LIVING_WILL?.advanceDirective
    ) {
      setToggleAdvancedDirective(true);
    } else if (
      chart?.LIVING_WILL?.advanceDirective === "false" ||
      chart?.LIVING_WILL?.advanceDirective === false
    ) {
      setToggleAdvancedDirective(false);
    } else {
      setToggleAdvancedDirective(null);
    }

    if (
      chart?.LIVING_WILL?.dnr === "true" ||
      chart?.LIVING_WILL?.dnr === true
    ) {
      setTogglednr(true);
    } else if (
      chart?.LIVING_WILL?.dnr === "false" ||
      chart?.LIVING_WILL?.dnr === false
    ) {
      setTogglednr(false);
    } else {
      setTogglednr(null);
    }

    if (
      chart?.LIVING_WILL?.polst === "true" ||
      chart?.LIVING_WILL?.polst === true
    ) {
      setTogglepolst(true);
    } else if (
      chart?.LIVING_WILL?.polst === "false" ||
      chart?.LIVING_WILL?.polst === false
    ) {
      setTogglepolst(false);
    } else {
      setTogglepolst(null);
    }
  }, [chart]);

  return (
    <div>
      <UploadingIndicator
        open={uploading}
        size={"10em"}
        thickness={5}
        fontSize="2em"
      />
      {chart ? (
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            uid: patientId === null ? undefined : patientId,
            hca: chart?.HEALTHCARE_AGENT_INFO?.agent || "",
            powerOfAttorney: chart?.LIVING_WILL?.powerOfAttorney || "",
            powerOfAttorneyURI: chart?.LIVING_WILL?.powerOfAttorneyURI,
            organDonor: chart?.LIVING_WILL?.organDonor,
            livingWill: chart?.LIVING_WILL?.livingWill || "",
            livingWillURI: chart?.livingWillURI,
            POLSTURI: chart?.LIVING_WILL?.POLSTURI,
            religion: chart?.LIVING_WILL?.religion || "",
            religiousInfo: chart?.LIVING_WILL?.religiousInfo || "",
            advanceDirective: chart?.LIVING_WILL?.advanceDirective || "",
            dnr: chart?.LIVING_WILL?.dnr || "",
            polst: chart?.LIVING_WILL?.polst || "",
          }}
          // validationSchema={PatientInfoSchema}
          onSubmit={(values, { setSubmitting }) => {
            formRef.current.values.POLSTURI = POLSTURI;
            formRef.current.values.livingWillURI = livingWillURI;
            formRef.current.values.powerOfAttorneyURI = powerOfAttorneyURI;
            const newChartData = { ...previousChartData, ...values };
            setNewPatientChartData(newChartData);
            submitDirectivesInformation(values);
            submitOtherDocumentsInformation(values);
            chart?.FACILITATOR_INFO?.facilitator === ("true" || true)
              ? navigate("/signup/aobSignatures")
              : navigate("/signup/consentCareSignatures");
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
            <Form>
              <Typography
                variant="h4"
                sx={{
                  color: "#1B405A",
                  fontSize: "32px",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 700,
                  margin: "29px 0 29px 0",
                  width: "fit-content",
                }}
              >
                Healthcare Directive or Living Will
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#1B405A",
                  fontSize: "16px",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                  margin: "16px 0",
                  width: "fit-content",
                }}
              >
                What documents do you already have and what would you like more
                information about?
              </Typography>
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "20px",
                  padding: 2,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "7px 0 0 0",
                    width: "fit-content",
                  }}
                >
                  Do you have a living will?
                </Typography>
                <Stack direction="row" sx={{ width: "270px", margin: "7px 0" }}>
                  <Button
                    name="livingWill"
                    value={true}
                    onClick={(e) => {
                      handleChange(e);
                      setLivingWill(true);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color: livingWill === true ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        livingWill === true ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    name="livingWill"
                    value={false}
                    onClick={(e) => {
                      handleChange(e);
                      setLivingWill(false);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color: livingWill === false ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        livingWill === false ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    No
                  </Button>
                </Stack>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "29px 0 31px 0",
                    width: "fit-content",
                  }}
                >
                  Would you like information about creating a living will or
                  advance directive?
                </Typography>
                <Stack direction="row" sx={{ width: "270px", margin: "7px 0" }}>
                  <Button
                    name="advanceDirective"
                    value={true}
                    onClick={(e) => {
                      handleChange(e);
                      setToggleAdvancedDirective(true);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color:
                        toggleAdvancedDirective === true
                          ? "#FFFFFF"
                          : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleAdvancedDirective === true
                          ? "#4B8596"
                          : "#FFFFFF",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    name="advanceDirective"
                    value={false}
                    onClick={(e) => {
                      handleChange(e);
                      setToggleAdvancedDirective(false);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color:
                        toggleAdvancedDirective === false
                          ? "#FFFFFF"
                          : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleAdvancedDirective === false
                          ? "#4B8596"
                          : "#FFFFFF",
                    }}
                  >
                    No
                  </Button>
                </Stack>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "29px 0 31px 0",
                    width: "fit-content",
                  }}
                >
                  Are you an organ donor?
                </Typography>
                <Stack direction="row" sx={{ width: "270px", margin: "7px 0" }}>
                  <Button
                    name="organDonor"
                    value="true"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleOrganDonor(true);
                    }}
                    variant="contained"
                    sx={{
                      width: "89px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color:
                        toggleOrganDonor === true ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleOrganDonor === true ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    name="organDonor"
                    value="false"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleOrganDonor(false);
                    }}
                    variant="contained"
                    sx={{
                      width: "89px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "0px",
                      textTransform: "none",
                      color:
                        toggleOrganDonor === false ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleOrganDonor === false ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    No
                  </Button>
                  <Button
                    name="organDonor"
                    value="TBD"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleOrganDonor("TBD");
                    }}
                    variant="contained"
                    sx={{
                      width: "89px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color:
                        toggleOrganDonor === "TBD" ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleOrganDonor === "TBD" ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    TBD
                  </Button>
                </Stack>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "29px 0 31px 0",
                    width: "fit-content",
                  }}
                >
                  Do you know what a Do Not Resusscitate (DNR) order is?
                </Typography>
                <Stack direction="row" sx={{ width: "270px", margin: "7px 0" }}>
                  <Button
                    name="dnr"
                    value="true"
                    onClick={(e) => {
                      handleChange(e);
                      setTogglednr(true);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color: togglednr === true ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        togglednr === true ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    name="dnr"
                    value="false"
                    onClick={(e) => {
                      handleChange(e);
                      setTogglednr(false);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color: togglednr === false ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        togglednr === false ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    No
                  </Button>
                </Stack>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "16px",
                    fontFamily: "Helvetica Neue",
                    textAlign: "center",
                    fontWeight: 500,
                    margin: "29px 0 31px 0",
                    width: "fit-content",
                  }}
                >
                  Do you know what Physician's Order for LIfe Sustaining
                  Treatment (POLST) is?
                </Typography>
                <Stack direction="row" sx={{ width: "270px", margin: "7px 0" }}>
                  <Button
                    name="polst"
                    value="true"
                    onClick={(e) => {
                      handleChange(e);
                      setTogglepolst(true);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color: togglepolst === true ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        togglepolst === true ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    name="polst"
                    value="false"
                    onClick={(e) => {
                      handleChange(e);
                      setTogglepolst(false);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "45px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color: togglepolst === false ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        togglepolst === false ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    No
                  </Button>
                </Stack>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "16px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "29px 0 31px 0",
                    width: "fit-content",
                  }}
                >
                  Please indicate your religious tradition:
                </Typography>
                <FormikSelect
                  name="religion"
                  className="mt-12"
                  handleChange={handleChange}
                >
                  <option value="">Prefer Not To Say</option>
                  <option value="Christianity">Christianity</option>
                  <option value="Islam">Islam</option>
                  <option value="Hinduism">Hinduism</option>
                  <option value="Buddhism">Buddhism</option>
                  <option value="Taoism">Taoism</option>
                  <option value="Shinto">Shinto</option>
                  <option value="Falun Gong">Falun Gong</option> Gong
                  <option value="Judaism">Judaism</option>
                  <option value="Confucianism">Confucianism</option>
                  <option value="Other...">Other...</option>...
                </FormikSelect>
                <FormikTextInput
                  label="Additional Religious Info: "
                  name="religiousInfo"
                  type="text"
                  handleChange={handleChange}
                />
                <Stack
                  direction="column"
                  spacing={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                    padding: 2,
                    marginTop: "32px",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#720D0F",
                      fontSize: "24px",
                      TypographyAlign: "center",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      margin: "16px auto 0px auto",
                      width: "fit-content",
                    }}
                  >
                    Personal Documents
                  </Typography>
                  <Typography
                    sx={{
                      color: "#1B405A",
                      fontSize: "18px",
                      margin: "8px 0px",
                    }}
                  >
                    Use the buttons below to upload the applicable documents if
                    you have them. You can use a picture of the FRONT of your
                    POLST. After uploading a document, you can click on the text
                    next to the icon to view the document.
                  </Typography>
                  <LivingWill
                    handleLivingWill={handleLivingWill}
                    livingWillURI={livingWillURI}
                    livingWill={livingWill}
                  />
                  <POLST
                    handlePOLST={handlePOLST}
                    POLSTURI={POLSTURI}
                    POLSTExists={POLSTExists}
                  />
                  <PowerOfAttorney
                    handlePowerOfAttorney={handlePowerOfAttorney}
                    powerOfAttorneyURI={powerOfAttorneyURI}
                    powerOfAttorneyExists={powerOfAttorneyExists}
                  />
                  {/* removed in deference to photo capture by webcam */}
                  {/* <PhotoForCard handlePortraitURI={handlePortraitURI} portraitURI={portraitURI} /> */}
                </Stack>
                <div style={{ margin: "0 auto", width: "fit-content" }}>
                  <button
                    type="submit"
                    disabled={
                      !(
                        toggleOrganDonor !== null &&
                        togglednr !== null &&
                        togglepolst !== null &&
                        livingWill !== null &&
                        toggleAdvancedDirective !== null
                      )
                    }
                    className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue{" "}
                    <ChevronRightIcon sx={{ fontSize: "32px" }} />
                  </button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
