export const getDomainValid = (apiUri, domain) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
  
    const uri = encodeURI(`${apiUri}/config/discountCodeUris/${domain}`);
    
    return fetch(uri, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
  }