import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitSampleCardInformation = async (values) => {
  const basePatientId = values.uid;

  const SAMPLE_CARD = {
    facilitatorID: values.facilitatorID,
    returning: true,
    siteID: values.siteID,
    submitDate: values.submitDate,
  };

  const scDocRef = doc(db, "users", basePatientId, "Form", "SAMPLE_CARD");
  await setDoc(scDocRef, SAMPLE_CARD);
};
