import React from "react";
import { useField } from "formik";

export const FormikTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()] which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  let disabled;
  if (props.editable === "true") {
    disabled = false;
  } else {
    disabled = true;
  }

  return (
    <>
      {/* <label htmlFor={props.id || props.name}>{label}</label> */}
      <textarea
        {...field}
        placeholder={label}
        required={(props.required !== null && props.required) || false}
        name={props.name}
        type={props.type}
        onChange={(e) => {
          props.handleChange(e);
          props.handleuichange(e);
        }}
        disabled={false}
        rows={props.rows}
        style={props.style || { borderRadius: '4px', margin: '7px 0', width: '270px', border: "1px solid #0000003B", }}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
