import React from 'react';
import { Stack } from '@mui/system';
import Webcam from 'react-webcam';
import { Box } from '@mui/material';

export const TakeIdPicture = (props) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    props.handlePatientTakenPhoto(imageSrc);
    console.log(imageSrc);
  }, [webcamRef]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      <Box>
        <button
          className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          type="button"
          onClick={capture}
        >
          Capture photo
        </button>
      </Box>
    </Stack>
  );
};
