import { getDomainValid } from "./getDomainValid";
import { createDiscountCode } from "./createDisountCode";
import { getDiscountCode } from "./getDiscountCode";
import { useDiscountCode } from "./useDiscountCode";
import { getProduct } from "./getProduct";
import { listProducts } from "./listProducts";

//export const apiUrl = Config.APIURI;

const baseURL = {
  production: "https://us-central1-web-console-mideoid.cloudfunctions.net",
  development: "https://us-central1-dev-mideo.cloudfunctions.net"
}
const apiUrl = `${process.env.REACT_APP_ENVIRONMENT ? baseURL[process.env.REACT_APP_ENVIRONMENT] : baseURL.production}/api`;

export const getDomainValidApi = (domain) => {
  return getDomainValid(apiUrl, domain);
};

export const createDiscountCodeApi = (uid, domain, product) => {
  return createDiscountCode(apiUrl, uid, domain, product)
}

export const getDiscountCodeApi = (uid) => {
  return getDiscountCode(apiUrl, uid)
}

export const useDiscountCodeApi = (uid) => {
  return useDiscountCode(apiUrl, uid)
}

export const getProductApi = (uid) => {
  return getProduct(apiUrl, uid)
}

export const listProductsApi = () => {
  return listProducts(apiUrl)
}