import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Routes, Route, useMatch } from "react-router-dom";
import { LogOutBtn } from "../Auth/components/LogOutBtn";
import { FormIntro } from "./layouts/SampleForm/components/FormIntro";
import { NewPatientFormManager } from "./layouts/SampleForm/NewPatientFormManager";
import { HeaderMenu } from "./HeaderMenu";
import { Footer } from "./Footer";

export const Home = () => {
  const queryClient = new QueryClient();
  const onHomePage = useMatch({ path: "/", end: true });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <HeaderMenu />
        
        {onHomePage ? <FormIntro /> : null}
        <Routes>
          <Route path="signup/*" element={<NewPatientFormManager />} />
        </Routes>
        <Footer />
        <ReactQueryDevtools initialIsOpen={true} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
};
