import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";
import { castAsBoolean } from "../../helpers";

export const submitDirectivesInformation = async (values) => {
  const basePatientId = values.uid;

  const LIVING_WILL = {
    organDonor: values.organDonor,
    livingWill: castAsBoolean(values.livingWill),
    advanceDirective: castAsBoolean(values.advanceDirective),
    dnr: castAsBoolean(values.dnr),
    polst: castAsBoolean(values.polst),
    religion: values.religion,
    religiousInfo: values.religiousInfo,
    POLSTURI: values.POLSTURI,
    livingWillURI: values.livingWillURI,
    powerOfAttorneyURI: values.powerOfAttorneyURI,
  };

  const lwDocRef = doc(db, "users", basePatientId, "Form", "LIVING_WILL");
  await setDoc(lwDocRef, LIVING_WILL);

  /* const hcaDocRef2 = doc(db,"users",basePatientId,"Chart","OTHER_DOCUMENTS");
  await setDoc(hcaDocRef2, LIVING_WILL); */
};
