import { Backdrop, CircularProgress, Typography } from "@mui/material"
import { Box } from "@mui/system"

export const UploadingIndicator = (props) => {

  if (props.value) {
    return (
      <Backdrop 
        open={props.open}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: "#fff",
              borderRadius: "50%",
              zIndex: -1
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary" {...props}>
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    )
  }

  return (
    <Backdrop 
      open={props.open}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress  {...props} />
      </Box>
    </Backdrop>
  )
}