import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";
import { sendEnvelope } from "../../api/sendEnvelope";

export const submitFinalSignaturesInformation = async (values) => {
  const basePatientId = values.uid;

  const WITNESS_SIGNATURE = {
    signature1DataURI: values.witnessSignatureDataURI || "",
    signature2DataURI: values.witness2SignatureDataURI || "",
    witnessName: values.witnessName || "",
    witness2Name: values.witness2Name || "",
    witnessEmail: values.witnessEmail || "",
    witness2Email: values.witness2Email || "",
  };

  console.log(values);
  const PATIENT_SIGNATURE = {
    signature1DataURI: values.patientSignatureDataURI,
  };

  const witnessDocRef = doc(
    db,
    "users",
    basePatientId,
    "Form",
    "WITNESS_SIGNATURE"
  );
  await setDoc(witnessDocRef, WITNESS_SIGNATURE);

  if (values.witnessEmail && values.witnessEmail !== "") {
    sendEnvelope(
      values.uid,
      "witness",
      `${values.PATIENT_INFO_1.firstName} ${values.PATIENT_INFO_1.lastName}`,
      `${values.email}`,
      values.witnessName,
      values.witnessEmail,
      `${values.SCRIPT_REVIEW.telePrompterScript}`
    );
  }

  if (values.witness2Email && values.witness2Email !== "") {
    sendEnvelope(
      values.uid,
      "witness2",
      `${values.PATIENT_INFO_1.firstName} ${values.PATIENT_INFO_1.lastName}`,
      `${values.email}`,
      values.witness2Name,
      values.witness2Email,
      `${values.SCRIPT_REVIEW.telePrompterScript}`
    );
  }

  const patientDocRef = doc(
    db,
    "users",
    basePatientId,
    "Form",
    "PATIENT_SIGNATURE"
  );
  await setDoc(patientDocRef, PATIENT_SIGNATURE);
};
