import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitSelectedTreatmentInformation = async (values) => {
  const basePatientId = values.uid;

  const SELECTED_TREATMENT_OPTION = {
    code: values.code,
    description: values.description,
  };

  const stoDocRef = doc(
    db,
    "users",
    basePatientId,
    "Form",
    "SELECTED_TREATMENT_OPTION"
  );
  await setDoc(stoDocRef, SELECTED_TREATMENT_OPTION);
};
