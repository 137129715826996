import React, { useState, useEffect } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import qrCode from '../../../../../assets/QRcode.png';
import { ContentCopy } from '@mui/icons-material';
import { getPatientChart } from '../../../../../data/mutations/NewPatient/getPatientChart';
import { currentUser } from '../../../../../data/localState/currentUser';
const cardMaxWidth = '400px';
const cardWidth = '400px';
const cardHeight = cardWidth * (2.125 / 3.375);

const fontSizeSmall = '12px';
const smallLineHeight = '13px';
const fontSizeMedium = '12px';
const mediumLineHeight = '15px';
const fontSizeHeading = '15px';
const HeadingLineHeight = '15px';



const sxs4 = {
  pageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '40px',
    height: '100%',
  },

  cardShadowContainer: {
    borderRadius: '12px',
    backgroundColor: 'white',
    width: cardWidth,
    height: cardHeight,
    minHeight: cardHeight,
    shadowColor: '#000000',
    shadowOffset: {
      width: '0px',
      height: '3px',
    },
    shadowRadius: '5px',
    shadowOpacity: '1',
  },

  cardContainer: {
    //display: 'flex',
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    //justifyContent: 'center',
    //marginLeft: auto
    overflow: 'hidden',
  },

  cardHeader: {
    height: '35px',
    transform: 'translateY(-10px)',
    backgroundColor: '#800000',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardHeaderTypography: {
    marginTop: '8px',
    marginLeft: '16px',
    marginRight: 'auto',
    fontSize: fontSizeHeading,
    fontWeight: '900',
    color: 'white',
  },

  cardInfo: {
    paddingLeft: '11px',
    width: '100%',
    backgroundColor: 'white',
    flexDirection: 'row',
  },
  cardLeft: {
    width: '80%',
  },

  cardName: {
    fontSize: '16px',
    fontWeight: '800',
  },

  cardOrganDonation: {
    fontSize: fontSizeSmall,
    lineHeight: smallLineHeight,
    fontWeight: 'bold',
  },

  cardCodeStatus: {
    fontSize: fontSizeMedium,
    lineHeight: mediumLineHeight,
    fontWeight: '700',
    maxWidth: '60%',
  },
  cardLivingWill: {
    fontSize: fontSizeSmall,
    lineHeight: mediumLineHeight,
    fontWeight: 'bold',
  },

  cardAgent: {
    fontSize: fontSizeSmall,
    lineHeight: smallLineHeight,
    fontWeight: 'bold',
  },
  cardPolst: {
    fontSize: fontSizeSmall,
    lineHeight: smallLineHeight,
    fontWeight: 'bold',
  },

  cardDOB: {
    textAlign: 'center',
    paddingRight: '10px',
    width: '100%',
    fontSize: '12px', //fontSizeSmall,
    lineHeight: smallLineHeight,
    fontWeight: 'bold',
  },
  cardExpirty: {
    fontSize: fontSizeHeading,
    textAlign: 'right',
  },

  cardimg: {
    height: '200px',
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  cardMedicalAlerts: {
    fontSize: fontSizeMedium,
    lineHeight: mediumLineHeight,
  },

  expiry: {
    alignItems: 'flex-end',
    marginLeft: 40,
  },

  cardPassword: {
    alignSelf: 'flex-end',
    color: 'red',
    textAlign: 'right',
  },

  cardIssued: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    fontSize: fontSizeMedium,
    lineHeight: mediumLineHeight,
  },

  cardExpiry: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    fontSize: fontSizeMedium,
    lineHeight: mediumLineHeight,
    paddingBottom: '10px',
  },

  cardPortrait: {
    alignSelf: 'flex-end',
    marginTop: '4px',
    marginRight: '12px',
    width: 0.21 * cardWidth,
    height: 0.21 * cardWidth * 1.22,
  },
  cardQrCode: {
    width: 0.21 * cardWidth,
    height: 0.21 * cardWidth,
    alignSelf: 'flex-end',
    position: 'relative',
    bottom: '0px',
    right: '0px',
  },
  cardRight: {
    width: '20%',
  },
};

export const SampleCard = () => {
  
  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  
  const firstName = chart?.firstName;
  const lastName = chart?.lastName;
  const code = chart?.code;
  const organDonor = chart?.organDonor;
  //const hasPolst = chart?.polst
  const polstUri = chart?.POLSTURI;
  const [hasPolst, setHasPolst] = useState('No');
  const organDonationStr =
    organDonor === true ? 'Yes' : organDonor === false ? 'No' : 'TBD';
  const [attorneyName, setAttorneyName] = useState(false);
  const [attorneyPhone, setAttorneyPhone] = useState(false);
  const [doctor, setDoctor] = useState('');
  const [drPhone, setDrPhone] = useState('');
  const [imgWidth, setimgWidth] = useState(0);
  const [imgHeight, setimgHeight] = useState(0);
  const DOB = chart?.DOB;
  const portraitURI = chart?.portraitURI;
  const notes = undefined;
  const medicalAlerts = undefined;
  const agentName = chart?.agent;
  const agentCellPhone = chart?.agentCellPhone;

  

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);
  

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId)
      setChart( oldChart.chart )
    })();
  }, [patientId]);


  const codeStatusStr = (code) => {
    if (code == 'fullCode') {
      return('Full Code');
    } else if (code == 'fullCodeBipapInt') {
      return('Full Code with trial of Bipap then Intubation');
    } else if (code == 'fullCodeBipap') {
      return('Full Code Trial of Bipap and no intubation');
    } else if (code == 'fullCodeACLS15') {
      return('Full Code and Trial of ACLS x 15 mins');
    } else if (code == 'fullCodeACLS15BipapInt') {
      return(
        'Full Code and Trial of ACLS x 15 mins with Bipap then Intubation'
      );
    } else if (code == 'fullCodeACLS15Bipap') {
      return(
        'Full Code and Trial of ACLS x 15 mins with Bipap and No intubation'
      );
    } else if (code == 'aggressiveBipapInt') {
      return(
        'Full and Aggressive up until cardiac arrest with Bipap then intubation'
      );
    } else if (code == 'aggressiveBipap') {
      return(
        'Full and Aggressive up until cardiac arrest with Bipap only, no intubation'
      );
    } else if (code == 'clarifications') {
      return('Clarification in Treatment DNR');
    } else if (code == 'eol') {
      return('End of Life/DNR/Allow Natural Death');
    } else {
      return('DEFAULT');
    }
  }
  useEffect(() => {
    if (chart?.livingWillURI !== null) {
      setAttorneyName(chart?.attorney);
      setAttorneyPhone(chart?.attorneyPhone);
    }

    if (polstUri !== undefined && polstUri !== null) {
      setDoctor(chart?.doctor);
      setDrPhone(chart?.drPhone);
      setHasPolst('Yes');
    }

    // Get code Typography
    
  }, [chart]);

  const nameStr = firstName + ' ' + lastName;
  //const codeStatusStr = "Code Status: " + code
  const livingWillStr =
    chart?.livingWillURI != undefined && chart?.livingWillURI != null
      ? 'Yes'
      : 'No'; //attorneyName ? `Living Will: ${attorneyName} ${attorneyPhone}` : null
  const attorneyStr = ''; // {attorneyName ? `Living Will: ${attorneyName}  ${attorneyPhone}` : null}
  const healthcareAgentStr = ''; //{`Healthcare Agent: ${agentName}  ${agentCellPhone}`}
  const cardPolstStr = hasPolst; //{doctor ? `Requires Decision Support Approval` : null}</Typography>
  const cardPost2Str = ''; // {doctor ? `24/7 Decision Support: ${doctor} ${drPhone}` : null}</Typography>
  const cardAlertStr = ''; //Medical Alerts: None
  const notesStr = ''; // {notes ? notes : null}
  const passwwordStr = ''; //PASSWORD: 911
  const cardIssuesStr = ''; // Issued: 01/01/2020
  const cardExpiryStr = ''; // Expires: 01/01/2025
  const cardDobStr = `DOB:\n${DOB}`;

  return (
    <>
      <Box sx={sxs4.container}>
        <Box sx={sxs4.pageContainer}>
          <Box sx={sxs4.cardShadowContainer}>
            <Box sx={sxs4.cardContainer}>
              <Box sx={sxs4.cardHeader}>
                <Typography sx={sxs4.cardHeaderTypography}>
                  MIDEO® - My Informed Decision on vidEO
                </Typography>
              </Box>

              <Stack
                direction="row"
                sx={{
                  paddingLeft: '11px',
                  width: '100%',
                  backgroundColor: 'white',
                }}
              >
                <Box
                  sx={{
                    width: '80%',
                  }}
                >
                  <Typography sx={sxs4.cardName}>{chart?.PATIENT_INFO_1?.firstName + " " + chart?.PATIENT_INFO_1.lastName}</Typography>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', width: '95%' }}
                  >
                    <Typography
                      sx={[
                        sxs4.cardCodeStatus,
                        { fontWeight: '900', marginRight: 3 },
                      ]}
                    >
                      Code Status:
                    </Typography>
                    <Typography sx={sxs4.cardCodeStatus}>
                      {codeStatusStr(chart?.SELECTED_TREATMENT_OPTION?.code)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', width: '95%' }}
                  >
                    <Typography
                      sx={[
                        sxs4.cardOrganDonation,
                        { fontWeight: '900', marginRight: 3 },
                      ]}
                    >
                      Organ Donation:
                    </Typography>
                    <Typography sx={sxs4.cardOrganDonation}>
                      {chart?.LIVING_WILL?.organDonor}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', width: '95%' }}
                  >
                    <Typography
                      sx={[
                        sxs4.cardLivingWill,
                        { fontWeight: '900', marginRight: 3 },
                      ]}
                    >
                      Living Will:
                    </Typography>
                    <Typography sx={sxs4.cardLivingWill}>
                      {chart?.LIVING_WILL?.livingWill === (true || 'true') || chart?.LIVING_WILL?.livingWillURI !== (null || '') ? "Yes" : "No"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', width: '95%' }}
                  >
                    <Typography
                      sx={[
                        sxs4.cardAgent,
                        { fontWeight: '900', marginRight: 3 },
                      ]}
                    >
                      Healthcare Agent:
                    </Typography>
                    <Typography
                      sx={sxs4.cardAgent}
                    >{`${chart?.HEALTHCARE_AGENT_INFO?.agent} ${chart?.HEALTHCARE_AGENT_INFO?.cellPhone}`}</Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', width: '95%' }}
                  >
                    <Typography
                      sx={[
                        sxs4.cardPolst,
                        { fontWeight: '900', marginRight: 3 },
                      ]}
                    >
                      POLST:
                    </Typography>
                    <Typography sx={sxs4.cardPolst}>

                    {chart?.LIVING_WILL?.polst === (true || 'true') || chart?.LIVING_WILL?.POLSTURI !== (null || '') ? "Yes" : "No"}
                    </Typography>
                  </Box>

                  <Typography>
                    {medicalAlerts ? medicalAlerts : null}
                  </Typography>
                  <Typography>{notes ? notes : null}</Typography>
                </Box>
                <Box
                  sx={{
                    width: '20%',
                    height: 'fit-content',
                  }}
                >
                  <img
                    alt="sample card"
                    style={sxs4.cardPortrait}
                    src={chart?.PHOTO_FOR_CARD?.portraitURI}
                  />
                  <Typography sx={sxs4.cardDOB}>{`DOB:\n${chart?.PATIENT_INFO_1?.DOB}`}</Typography>
                </Box>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginX: '8px',
                  alignItems: 'center',
                }}
              >
                <img
                  alt="sample card veried"
                  style={sxs4.verified}
                  src={require('../../../../../assets/verified.png')}
                />
                <Box
                  sx={{
                    alignSelf: 'flex-end',
                    textAlign: 'right',
                    fontSize: fontSizeMedium,
                    lineHeight: mediumLineHeight,
                    paddingBottom: '2px',
                  }}
                >
                  <Typography sx={sxs4.cardPassword}>PASSWORD: 911</Typography>
                  <Typography sx={sxs4.cardIssued}>
                    Issued: 01/01/2020
                  </Typography>
                  <Typography sx={sxs4.cardExpiry}>
                    Expires: 01/01/2025
                  </Typography>
                </Box>
                <img
                  alt="sample card qr code"
                  style={sxs4.cardQrCode}
                  src={qrCode}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
