import React, { useRef, useEffect, useState } from "react";
import { Formik, Form } from "formik";

import { unlockFile } from "./unlockFile";
import {
  FormikSelect,
  FormikTextInput,
  FormikTextArea,
} from "../../../../Features/Forms";
import { submitPatientInformation } from "../../../../../data/mutations/NewPatient/submitPatientInformation";
import {
  newPatientId,
  setNewPatientId,
} from "../../../../../data/localState/newPatientId";
import { useNavigate } from "react-router-dom";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import {
  Typography,
  TextField,
  Stack,
  useMediaQuery,
  Button,
} from "@mui/material";
import "./PatientInformation.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { updateFilterOptions } from "../../../../../data/mutations/NewPatient/updateFilter";
import * as Yup from "yup";
import {
  lettersAndSpaces,
  phoneRegExp,
  zipRegExp,
} from "../../../../../constants";

import { parse, isDate, subYears, format } from "date-fns";
import { getNavigation } from "../../../../../data/mutations/NewPatient/getNavigation";
import { AlertDialog } from "./AlertDialog";

export const PatientInformation = () => {
  const [patientId, setPatientId] = useState();
  const [toggleGender, setToggleGender] = useState(null);
  const [toggleSelfReporting, setToggleSelfReporting] = useState(null);
  const navigate = useNavigate();
  const formRef = useRef(null);
  //
  // const patientId = newPatientId.use();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [lastSectionState, setLastSectionState] = useState();
  const isSlim = useMediaQuery("(max-width:1040px)");
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const parseDateString = (value, originalValue) => {
    console.log(originalValue);
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "MM/dd/yyyy", new Date());
    console.log(parsedDate);
    return parsedDate;
  };
  var userEmailAddress = "";

  if (patient) {
    userEmailAddress = patient.email;
    console.log(userEmailAddress);
  }

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  console.log(toggleSelfReporting);

  useEffect(() => {
    if (chart && chart?.PATIENT_INFO_1?.gender === "male") {
      setToggleGender("male");
    } else if (chart && chart?.PATIENT_INFO_1?.gender === "female") {
      setToggleGender("female");
    } else if (chart && chart?.PATIENT_INFO_1?.gender === "non-binary") {
      setToggleGender("non-binary");
    } else {
      setToggleGender(null);
    }

    if (
      chart &&
      (chart?.PATIENT_INFO_1?.selfReporting === "true" ||
        chart?.PATIENT_INFO_1?.selfReporting)
    ) {
      setToggleSelfReporting(true);
      formRef.current.setFieldValue("selfReporting", true);
    } else if (chart && chart?.PATIENT_INFO_1?.selfReporting === "false") {
      setToggleSelfReporting(false);
      formRef.current.setFieldValue("selfReporting", false);
    } else {
      setToggleSelfReporting(null);
    }
  }, [chart]);

  const formatDOBforDB = (date) => {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    return format(parsedDate, "MM/dd/yyyy");
  };
  const formatDOBforFormik = (date) => {
    try {
      const parsedDate = parse(date, "MM/dd/yyyy", new Date());
      return format(parsedDate, "yyyy-MM-dd");
    } catch {
      return date;
    }
  };

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
      const tmpSections = await getNavigation(patientId);
      const activeSection = tmpSections.sections.filter(
        (item) => item.active
      )[0];
      if (activeSection) {
        setLastSectionState(activeSection);
        setAlertOpen(true);
      }
    })();
  }, [patientId]);

  return (
    <div className="flex flex-col items-center">
      <AlertDialog
        openState={{ open: alertOpen, setOpen: setAlertOpen }}
        options={{
          title: "Continue From Last Saved?",
          description:
            "We have detected that you have a previously saved form. Would you like to continue from the last page you visited?",
          closeText: "Continue From Here",
          continueText: "Continue From Last Save",
        }}
        handleContinue={() => navigate(lastSectionState.paths[0])}
      />
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: patientId === null ? undefined : patientId,
          firstName: chart?.PATIENT_INFO_1?.firstName || "",
          middleInitial: chart?.PATIENT_INFO_1?.middleInitial || "",
          lastName: chart?.PATIENT_INFO_1?.lastName || "",
          DOB: chart?.PATIENT_INFO_1?.DOB
            ? formatDOBforFormik(chart?.PATIENT_INFO_1?.DOB)
            : "",
          gender: chart?.PATIENT_INFO_1?.gender || "",
          streetAddress: chart?.PATIENT_INFO_2?.streetAddress || "",
          city: chart?.PATIENT_INFO_2?.city || "",
          state: chart?.PATIENT_INFO_2?.state || "",
          ZIP: chart?.PATIENT_INFO_2?.ZIP || "",
          country: chart?.PATIENT_INFO_2?.country || "",
          homePhone: chart?.PATIENT_INFO_2?.homePhone || "",
          cellPhone: chart?.PATIENT_INFO_2?.cellPhone || "",
          email: chart?.PATIENT_INFO_2?.email || "",

          email: chart?.PATIENT_INFO_2?.email
            ? chart?.PATIENT_INFO_2?.email
            : userEmailAddress,
          selfReporting: chart?.PATIENT_INFO_1?.selfReporting || "",
          witnesses: chart?.PATIENT_INFO_1?.witnesses || "",
          returning: chart?.PATIENT_INFO_1?.returning || false,
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          console.log("values", values);
          const returnedId = await submitPatientInformation({
            ...values,
            DOB: formatDOBforDB(formRef.current.values.DOB),
          });
          setNewPatientId(returnedId);
          setNewPatientChartData({
            ...values,
            DOB: formatDOBforDB(formRef.current.values.DOB),
          });
          updateFilterOptions({
            DOB: formatDOBforDB(formRef.current.values.DOB),
            gender: formRef.current.values.gender,
            state: formRef.current.values.state,
            country: formRef.current.values.country,
            createdOn: Date.now(),
          });
          navigate("/signup/contacts");
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .trim()
            .required("First name is required")
            .min(
              2,
              ({ min }) => `First name should be at least ${min} characters`
            )
            .max(
              30,
              ({ max }) => `First name should be shorter than ${max} characters`
            )
            .matches(lettersAndSpaces, "Field cannot contain numbers"),
          middleInitial: Yup.string()
            .trim()
            .min(1, ({ min }) => `Middle initial should be one letter.`)
            .max(1, ({ max }) => `Middle initial should be one letter.`)
            .matches(lettersAndSpaces, "Field cannot contain numbers"),
          lastName: Yup.string()
            .trim()
            .required("Last name is required")
            .min(
              2,
              ({ min }) => `Last name should be at least ${min} characters`
            )
            .max(
              30,
              ({ max }) => `Last name should be shorter than ${max} characters`
            )
            .matches(lettersAndSpaces, "Field cannot contain numbers"),
          gender: Yup.mixed()
            .oneOf(["male", "female", "non-binary"])
            .required("Gender selection is required"),
          witnesses: Yup.string()
            .trim()
            .required(
              "At least one witness must be present for the completion of this form"
            ),
          selfReporting: Yup.mixed()
            .oneOf(["true", "false", true, false])
            .required(
              'If you are not currently working with an Advance Care Planning Educator, you should select "I am completing MIDEO for me"'
            ),
          streetAddress: Yup.string()
            .required(" Street Address is required")
            .min(
              2,
              ({ min }) =>
                ` Street Address should be at least ${min} characters`
            )
            .max(
              50,
              ({ max }) =>
                ` Street Address should be shorter than ${max} characters`
            ),
          city: Yup.string()
            .required("City name is required")
            .min(
              2,
              ({ min }) => `City name should be at least ${min} characters`
            )
            .max(
              50,
              ({ max }) => `City name should be shorter than ${max} characters`
            )
            .matches(lettersAndSpaces, "Field cannot contain numbers"),
          state: Yup.string()
            .required("State is required")
            .min(
              2,
              ({ min }) =>
                `Please use your state's two letter postal designation`
            )
            .max(
              2,
              ({ max }) =>
                `Please use your state's two letter postal designation`
            )
            .matches(lettersAndSpaces, "State cannot contain numbers"),
          ZIP: Yup.string()
            .required("Postal Code is required")
            .matches(zipRegExp, "Zip code is not valid"),
          country: Yup.string()
            .required("Country selection is required")
            .matches(lettersAndSpaces, "Field cannot contain numbers"),
          homePhone: Yup.string().matches(
            phoneRegExp,
            "Phone number is not valid"
          ),
          cellPhone: Yup.string()
            .required("Cell phone number required for emergency contact.")
            .matches(phoneRegExp, "Phone number is not valid"),
          email: Yup.string().email("Email Invalid"),
        })}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form style={{ width: "100%" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#1B405A",
                fontSize: "32px",
                fontFamily: "Helvetica Neue",
                fontWeight: 700,
                margin: "29px 0 35px 0",
              }}
            >
              Patient Information
            </Typography>
            <Stack
              direction={isSlim ? "column" : "row"}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Stack direction="column" className="">
                <FormikTextInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  required={true}
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Middle Initial"
                  name="middleInitial"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Last Name"
                  name="lastName"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <Typography>
                  Enter your Date of Birth using either a keyboard or the
                  calendar selector
                </Typography>
                <FormikTextInput
                  label="Date of Birth"
                  name="DOB"
                  required={true}
                  type="date"
                  handleChange={handleChange}
                />
                <Stack direction="row" sx={{ width: "270px", margin: "7px 0" }}>
                  <Button
                    name="gender"
                    value="male"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleGender("male");
                    }}
                    variant="contained"
                    sx={{
                      width: "89px",
                      height: "56px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color: toggleGender === "male" ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleGender === "male" ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    Male
                  </Button>
                  <Button
                    name="gender"
                    value="female"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleGender("female");
                    }}
                    variant="contained"
                    sx={{
                      width: "89px",
                      height: "56px",
                      padding: 0,
                      borderRadius: "0px",
                      textTransform: "none",
                      color:
                        toggleGender === "female" ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleGender === "female" ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    Female
                  </Button>
                  <Button
                    name="gender"
                    value="non-binary"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleGender("non-binary");
                    }}
                    variant="contained"
                    sx={{
                      width: "89px",
                      height: "56px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color:
                        toggleGender === "non-binary" ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleGender === "non-binary" ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    Non-binary
                  </Button>
                </Stack>
                {/*  <FormikSelect
                    name="gender"
                    
                    handleChange={handleChange}
                  >
                    <option value="">Select a gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-Binary</option>
                  </FormikSelect> */}
                <FormikTextArea
                  label="Those present at the time of completion."
                  name="witnesses"
                  required={true}
                  type="textarea"
                  editable={true}
                  rows="6"
                  handleChange={handleChange}
                />
                <Stack direction="row" sx={{ width: "270px", margin: "7px 0" }}>
                  <Button
                    name="selfReporting"
                    value="true"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleSelfReporting(true);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "80px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color:
                        toggleSelfReporting === true ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleSelfReporting === true ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    I’m completing MIDEO for me
                  </Button>
                  <Button
                    name="selfReporting"
                    value="false"
                    onClick={(e) => {
                      handleChange(e);
                      setToggleSelfReporting(false);
                    }}
                    variant="contained"
                    sx={{
                      width: "135px",
                      height: "80px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color:
                        toggleSelfReporting === false ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleSelfReporting === false ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    I’m completing MIDEO as an agent
                  </Button>
                </Stack>
                {/* <FormikSelect
                      name="selfReporting"
                      handleChange={handleChange}
                    >
                      <option value="">Select...</option>
                      <option value="true">Patient</option>
                      <option value="false">HCA</option>
                    </FormikSelect> */}
              </Stack>
              <Stack direction="column" sx={{}}>
                <FormikTextInput
                  label="Street Address"
                  required={true}
                  name="streetAddress"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="City"
                  required={true}
                  name="city"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="State"
                  required={true}
                  name="state"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="ZIP Code"
                  required={true}
                  name="ZIP"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  required={true}
                  label="Country"
                  name="country"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Home Phone (optional)"
                  name="homePhone"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Cell Phone"
                  required={true}
                  name="cellPhone"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Company or Secondary Email"
                  name="email"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <div>
                  <button
                    type="button"
                    disabled={false}
                    onClick={() => {
                      console.log(formRef.current);
                      handleSubmit();
                    }}
                    className="ml-12 mb-12 mt-6 relative bg-mideoformblue inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue{" "}
                    <ChevronRightIcon sx={{ fontSize: "32px" }} />
                  </button>
                </div>
                {/* <FormikTextInput
                    label="Driver's License #"
                    name="licenseNumber"
                    type="text"
                    handleChange={handleChange}
                    
                  /> */}
                {/*  <FormikSelect
                    className="w-12"
                    label="Organ Donor: "
                    name="organDonor"
                    handleChange={handleChange}
                    
                  >
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </FormikSelect>
                  <FormikSelect
                    className="w-12"
                    label="Who will be reading the MIDEO statement?"
                    name="speaker"
                    handleChange={handleChange}
                  >
                    <option value="">Select...</option>
                    <option value="patient">The patient</option>
                    <option value="HCA">The patient's HCA</option>
                  </FormikSelect>
                  <FormikSelect
                    className="w-12"
                    label="Does the patient have a POLST?"
                    name="POLST"
                    handleChange={handleChange}
                  >
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </FormikSelect>
                  <FormikSelect
                    className="w-12"
                    label="Does the patient have a Living Will?"
                    name="livingWill"
                    handleChange={handleChange}
                  >
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </FormikSelect> */}
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};
