import { newPatientChartData } from '../../../../../data/localState/newPatientChartData';
import { format, parse } from 'date-fns';
import React, { useRef, useEffect, useState } from 'react';
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from '../../../../../data/localState/currentUser';

export const teleprompterScript = (chart) => {
  //const chart = newPatientChartData.use();
  const name = `${chart?.PATIENT_INFO_1?.firstName} ${chart?.PATIENT_INFO_1?.middleInitial} ${chart?.PATIENT_INFO_1?.lastName}`;
  const tempDOB = chart?.PATIENT_INFO_1?.DOB;

  const reformatDate = (dateStr) => {
  var dArr = dateStr.split("-");  // ex input: "2010-01-18"
  return dArr[1]+ "/" +dArr[2]+ "/" +dArr[0]; //ex output: "18/01/10"
}
  const formatMonth = (num) => {
    switch(num) {
      case 1:
        return "January"
        break;
      case 2:
        return "February"
        break;
      case 3:
        return "March"
        break;
      case 4:
        return "April"
        break;
      case 5:
        return "May"
        break;
      case 6:
        return "June"
        break;
      case 7:
        return "July"
        break;
      case 8:
        return "August"
        break;
      case 9:
        return "September"
        break;
      case 10:
        return "October"
        break;
      case 11:
        return "November"
        break;
      case 12:
        return "December"
        break;
      default:
        return num;
    }
  }
  const format = (inputDate) => {
    console.log('inputDate', reformatDate(inputDate))
    var date = new Date(reformatDate(inputDate));
    if (!isNaN(date.getTime())) {
        // Months and Days use 0 index.
        console.log('date.getMont', date.getMonth() )
        return formatMonth(date.getMonth() + 1) + ' ' + (date.getDate()) + ', ' + date.getFullYear();
    }
}
  const DOB = format(tempDOB);
  let patientToAgent;
  let agentToPatient;
  let agentName;
  let agentPhone;
  let Possessive;
  let possessive;
  let Pronoun;
  let pronoun;
  let object;
  let script;
  let refuses;
  let consents;
  let toBe;

  if (chart?.HEALTHCARE_AGENT_INFO?.agent) {
    patientToAgent = chart?.HEALTHCARE_AGENT_INFO?.patientToAgent;
    agentToPatient = chart?.HEALTHCARE_AGENT_INFO?.agentToPatient;
    agentName = chart?.HEALTHCARE_AGENT_INFO?.agent;
    agentPhone = chart?.HEALTHCARE_AGENT_INFO?.cellPhone;
  } else {
    patientToAgent = null;
    agentToPatient = null;
    agentName = null;
    agentPhone = null;
  }

  if (chart?.PATIENT_INFO_1?.gender === 'male') {
    Possessive = 'His';
    possessive = 'his';
    pronoun = 'he';
    Pronoun = 'He';
    object = 'him';
    consents = 'consents';
    refuses = 'refuses';
    toBe = 'is';
  } else if (chart?.PATIENT_INFO_1?.gender === 'female') {
    Possessive = 'Her';
    possessive = 'her';
    pronoun = 'she';
    Pronoun = 'She';
    object = 'her';
    consents = 'consents';
    refuses = 'refuses';
    toBe = 'is';
  } else {
    possessive = 'their';
    Possessive = 'Their';
    pronoun = 'they';
    Pronoun = 'They';
    object = 'them';
    consents = 'consent';
    refuses = 'refuse';
    toBe = 'are';
  }

  const scripts = {
    lw: {
      fullCode: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS if my Cardiac Arrest is witnessed!\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is Full Code and the provision of CPR & ACLS if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeBipapInt: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS if my Cardiac Arrest is witnessed!\n\nIf I have respiratory failure then I consent to treatment with BIPAP initially and if that fails then Intubation.\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName}) at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP initially and if that fails then Intubation.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeBipap: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS if my Cardiac Arrest is witnessed!\n\nIf I have respiratory failure then I consent to treatment with BIPAP only and do not consent to intubation.\n\nEven if it results in my death.\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP only and ${refuses} intubation, even if it causes ${possessive} death.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeACLS15: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS  for 15 minutes if my Cardiac Arrest is witnessed!\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS for 15 minutes if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeACLS15BipapInt: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS for 15 minutes if my Cardiac Arrest is witnessed!\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP initially and if that fails then intubation.\n\nIf my Cardiac Arrest is un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS for 15 minutes if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP initially and if that fails then intubation.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeACLS15Bipap: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS for 15 minutes if my Cardiac Arrest is witnessed!\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP only and do not consent to intubation.\n\nEven if it results in my death.\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS for 15 minutes if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP only and ${refuses} intubation, even if it causes ${possessive} death.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      aggressiveBipapInt: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of my Cardiac Arrest.\n\nAt the point of Cardiac Arrest, I am directing that all aggressive treatment be Stopped to facilitate and allow for my Natural Death.\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP initially and if that fails then intubation.\n\nI would not want long-term mechanical ventilation.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease initially follow all Emergency Treatment Protocols until my cardiac arrest including ATLS, ACLS, Sepsis and Stroke Resuscitation.\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of ${possessive} Cardiac Arrest.\n\nAt the point of Cardiac Arrest, ${pronoun} ${toBe} directing that all aggressive treatment be Stopped to facilitate and Allow for ${possessive} Natural Death.\n\nAlso, if ${pronoun} were to have respiratory failure then ${pronoun}  ${consents} to treatment with BIPAP initially and if that fails then intubation.\n\n${Pronoun} would not want long-term mechanical ventilation.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      aggressiveBipap: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of my Cardiac Arrest.\n\nAt the point of Cardiac Arrest, I am directing that all aggressive treatment be Stopped to facilitate and allow for my Natural Death.\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP only and do not consent to intubation.\n\nEven if it results in my death.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease initially follow all Emergency Treatment Protocols until my cardiac arrest including ATLS, ACLS, Sepsis and Stroke Resuscitation.\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of ${possessive} Cardiac Arrest.\n\nAt the point of Cardiac Arrest, ${pronoun} ${toBe} directing that all aggressive treatment be Stopped to facilitate and Allow for ${possessive} Natural Death.\n\nAlso, if ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP only and does not consent to intubation.\n\nEven if it results in ${possessive} death.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please see the instructions contained in ${possessive} living will and contact me for further treatment direction at ${agentPhone}.`,
      },
      clarifications: {
        patient: `Hello, I am ${name} and this is My Informed Decision on Video.\n\nMy Emergency Treatment Choices are as follows: If I am Found in Cardiac Arrest, then there is to be No CPR/DNR & Allow My Natural Death!\n\nWhen I am not in Cardiac Arrest and there is a change in My Condition, then Please Contact my HealthCare Agent for further guidance and treatment.\n\nPlease Do Not Transfer me to an Emergency Room unless you have contacted my HealthCare Agent First.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nThis MIDEO safety statement is not limiting my treatment other than for cardiac arrest.\n\n At this time My Goals of Care are more aligned with preventing pain and facilitating comfort.\n\nAll other conditions and potential treatments will be evaluated on a case by case basis and will depend upon My Goals.\n\nHowever, you do have my permission to consult Palliative Care.\n\nIf there is a change in My condition or you require direction as how to treat My condition, then please see the instructions contained in my living will and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Treatment Choices are as follows: If ${pronoun} were found in Cardiac Arrest, then there is to be No CPR, ${pronoun} ${toBe} a DNR & Allow ${possessive} Natural Death!\n\nWhen ${pronoun} ${toBe} not in Cardiac Arrest and there is a change in ${possessive} Condition, then please Contact me for further guidance and treatment.\n\nPlease Do Not Transfer ${object} to an Emergency Room unless you have contacted me first.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nThis MIDEO safety statement is not limiting ${possessive} treatment other than for cardiac arrest.\n\nAt this time ${possessive} Goals of Care are more aligned with preventing pain and facilitating comfort.\n\nAll other conditions and potential treatments will be evaluated on a case by case basis and will depend upon ${possessive} Goals.\n\nHowever, you do have ${possessive} permission to consult Palliative Care.\n\nIf there is a change in ${possessive} condition or you require direction as how to treat ${possessive} condition, then please see the instructions contained in ${possessive} living will and contact me at ${agentPhone}.`,

        patientPOLST: `Hello, I am ${name} and this is My Informed Decision on Video.\n\nMy Emergency Treatment Choices are as follows: If I am Found in Cardiac Arrest, then there is to be No CPR/DNR & Allow My Natural Death!\n\nWhen I am not in Cardiac Arrest and there is a change in My Condition, then Please Contact my HealthCare Agent for further guidance and treatment.\n\nPlease Do Not Transfer me to an Emergency Room unless you have contacted my HealthCare Agent First.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nThis MIDEO safety statement is not limiting my treatment other than for cardiac arrest.\n\n At this time My Goals of Care are more aligned with preventing pain and facilitating comfort.\n\nAll other conditions and potential treatments will be evaluated on a case by case basis and will depend upon My Goals.\n\nHowever, you do have my permission to consult Palliative Care.\n\nIf there is a change in My condition or you require direction as how to treat My condition, then please see the instructions contained in my living will as well as my POLST and contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,

        hcaPOLST: `Hello, this is a My Informed decision on Video  for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Treatment Choices are as follows: If ${pronoun} were found in Cardiac Arrest, then there is to be No CPR, ${pronoun} ${toBe} a DNR & Allow ${possessive} Natural Death!\n\nWhen ${pronoun} ${toBe} not in Cardiac Arrest and there is a change in ${possessive} Condition, then please Contact me for further guidance and treatment.\n\nPlease Do Not Transfer ${object} to an Emergency Room unless you have contacted me first.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nThis MIDEO safety statement is not limiting ${possessive} treatment other than for cardiac arrest.\n\nAt this time ${possessive} Goals of Care are more aligned with preventing pain and facilitating comfort.\n\nAll other conditions and potential treatments will be evaluated on a case by case basis and will depend upon ${possessive} Goals.\n\nHowever, you do have ${possessive} permission to consult Palliative Care.\n\nIf there is a change in ${possessive} condition or you require direction as how to treat ${possessive} condition, then please see the instructions contained in ${possessive} living will as well as ${possessive} POLST and contact me at ${agentPhone}.`,
      },
      eol: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is No CPR/DNR and Allow My Natural Death.\n\nPlease Do Not Transfer me to an Emergency Room unless it is to control pain.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nIf you are reviewing this message, then the time has come to withhold or withdraw all treatment that attempts to prolong my life.\n\nMy goals of care at this time are to attain a dignified, comfortable and peaceful natural death.\n\nPlease Do Not Follow Your Emergency Treatment Protocols such as ATLS, ACLS, Sepsis or Stroke Resuscitation.\n\nHowever, You do have my permission to consult Palliative Care or Hospice.\n\nIf there is a change in my condition or you require further direction, assurance or clarification of my goals, then please see the instructions contained in my living will and contact my HealthCare Agent ${agentName} at ${agentPhone}.`,

        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is No CPR ${pronoun} ${toBe} a DNR and Allow ${possessive}  Natural Death.\n\nPlease Do Not Transfer ${object} to an Emergency Room unless you have contacted me first and it is to control pain.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nIf you are reviewing this message, then the time has come to withhold or withdraw all treatment that attempts to prolong ${possessive} life.\n\n${Possessive} goals of care at this time are to attain a dignified, comfortable and peaceful natural death.\n\nPlease Do Not Follow Your Emergency Treatment Protocols such as ATLS, ACLS, Sepsis or Stroke Resuscitation.\n\nHowever, You do have ${possessive} permission to consult Palliative Care or Hospice.\n\nIf there is a change in ${possessive}  condition or you require further direction, assurance or clarification of ${possessive} goals, then please see the instructions contained in ${possessive} living will and contact me at ${agentPhone}.`,

        patientPOLST: `Hello, I am ${name}  & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is No CPR/DNR and Allow My Natural Death.\n\nPlease Do Not Transfer me to an Emergency Room unless it is to control pain.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nIf you are reviewing this message, then the time has come to withhold or withdraw all treatment that attempts to prolong my life.\n\nMy goals of care at this time are to attain a dignified, comfortable and peaceful natural death.\n\nPlease Do Not Follow Your Emergency Treatment Protocols such as ATLS, ACLS, Sepsis or Stroke Resuscitation.\n\nHowever, You do have my permission to consult Palliative Care or Hospice.\n\nIf there is a change in my condition or you require further direction, assurance or clarification of my goals, then please see the instructions contained in my living will as well as my POLST and contact my HealthCare Agent ${agentName} at ${agentPhone}.`,

        hcaPOLST: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is No CPR ${pronoun} ${toBe} a DNR and Allow ${possessive}  Natural Death.\n\nPlease Do Not Transfer ${object} to an Emergency Room unless you have contacted me first and it is to control pain.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nIf you are reviewing this message, then the time has come to withhold or withdraw all treatment that attempts to prolong ${possessive} life.\n\n${Possessive} goals of care at this time are to attain a dignified, comfortable and peaceful natural death.\n\nPlease Do Not Follow Your Emergency Treatment Protocols such as ATLS, ACLS, Sepsis or Stroke Resuscitation.\n\nHowever, you do have ${possessive} permission to consult Palliative Care or Hospice.\n\nIf there is a change in ${possessive} condition or you require further direction, assurance or clarification of ${possessive} goals, then please see the instructions contained in ${possessive} living will as well as ${possessive} POLST and contact me at ${agentPhone}.`,
      },
    },
    noLW: {
      fullCode: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS if my Cardiac Arrest is witnessed!\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeBipapInt: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS if my Cardiac Arrest is witnessed!\n\nIf I have respiratory failure then I consent to treatment with BIPAP initially and if that fails then Intubation.\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP initially and if that fails then Intubation.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeBipap: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS if my Cardiac Arrest is witnessed!\n\nIf I have respiratory failure then I consent to treatment with BIPAP only and do not consent to intubation.\n\nEven if it results in my death.\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP only and ${refuses} intubation, even if it causes ${possessive} death.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeACLS15: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS  for 15 minutes if my Cardiac Arrest is witnessed!\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS for 15 minutes if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeACLS15BipapInt: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS for 15 minutes if my Cardiac Arrest is witnessed!\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP initially and if that fails then Intubation.\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS for 15 minutes if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP initially and if that fails then Intubation.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please contact me for further treatment direction at ${agentPhone}.`,
      },
      fullCodeACLS15Bipap: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is Full Code & the provision of CPR & ACLS for 15 minutes if my Cardiac Arrest is witnessed!\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP only and do not consent to intubation.\n\nEven if it results in my death.\n\nIf my Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} initial Emergency Treatment Choice is Full Code and the provision of CPR & ACLS for 15 minutes if ${possessive} Cardiac Arrest is witnessed.\n\nIf ${possessive} Cardiac Arrest is Un-witnessed, with a perceived prolonged downtime and signs of death, then there is to be No Trial of CPR.\n\nIf ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP only and ${refuses} intubation, even if it causes ${possessive} death.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please contact me for further treatment direction at ${agentPhone}.`,
      },
      aggressiveBipapInt: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of my Cardiac Arrest.\n\nAt the point of Cardiac Arrest, I am directing that all aggressive treatment be Stopped to facilitate and allow for my Natural Death.\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP initially and if that fails then Intubation.\n\nI would not want long-term mechanical ventilation.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease initially follow all Emergency Treatment Protocols until my cardiac arrest including ATLS, ACLS, Sepsis and Stroke Resuscitation.\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of ${possessive} Cardiac Arrest.\n\nAt the point of Cardiac Arrest, ${pronoun} ${toBe} directing that all aggressive treatment be Stopped to facilitate and Allow for ${possessive} Natural Death.\n\nAlso, if ${pronoun} were to have respiratory failure then ${pronoun}  ${consents} to treatment with BIPAP initially and if that fails then Intubation.\n\n${Pronoun} would not want long-term mechanical ventilation.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please contact me for further treatment direction at ${agentPhone}.`,
      },
      aggressiveBipap: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of my Cardiac Arrest.\n\nAt the point of Cardiac Arrest, I am directing that all aggressive treatment be Stopped to facilitate and allow for my Natural Death.\n\nAlso, if I have respiratory failure then I consent to treatment with BIPAP only and do not consent to intubation.\n\nEven if it results in my death.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nPlease initially follow all Emergency Treatment Protocols until my cardiac arrest including ATLS, ACLS, Sepsis and Stroke Resuscitation.\n\nIf I develop an End Stage Medical condition despite sound medical treatment, a persistent vegetative state or state of advanced dementia, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is for Full & Aggressive Treatment up until the point of ${possessive} Cardiac Arrest.\n\nAt the point of Cardiac Arrest, ${pronoun} ${toBe} directing that all aggressive treatment be Stopped to facilitate and Allow for ${possessive} Natural Death.\n\nAlso, if ${pronoun} were to have respiratory failure then ${pronoun} ${consents} to treatment with BIPAP only and does not consent to intubation.\n\nEven if it results in ${possessive} death.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nPlease initially follow all Emergency Treatment Protocols (ATLS, ACLS, Sepsis and Stroke Resuscitation).\n\nIf ${pronoun} were to develop an End Stage Medical condition “despite sound medical treatment”, a persistent vegetative state or state of advanced dementia then please contact me for further treatment direction at ${agentPhone}.`,
      },
      clarifications: {
        patient: `Hello, I am ${name} and this is My Informed Decision on Video.\n\nMy Emergency Treatment Choices are as follows: If I am Found in Cardiac Arrest, then there is to be No CPR/DNR & Allow My Natural Death!\n\nWhen I am not in Cardiac Arrest and there is a change in My Condition, then Please Contact my HealthCare Agent for further guidance and treatment.\n\nPlease Do Not Transfer me to an Emergency Room unless you have contacted my HealthCare Agent First.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nThis MIDEO safety statement is not limiting my treatment other than for cardiac arrest.\n\n At this time My Goals of Care are more aligned with preventing pain and facilitating comfort.\n\nAll other conditions and potential treatments will be evaluated on a case by case basis and will depend upon My Goals.\n\nHowever, you do have my permission to consult Palliative Care.\n\nIf there is a change in My condition or you require direction as how to treat My condition, then please contact my HealthCare Agent who is my ${agentToPatient}, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Treatment Choices are as follows: If ${pronoun} were found in Cardiac Arrest, then there is to be No CPR, ${pronoun} ${toBe} a DNR & Allow ${possessive} Natural Death!\n\nWhen ${pronoun} ${toBe} not in Cardiac Arrest and there is a change in ${possessive} Condition, then please Contact me for further guidance and treatment.\n\nPlease Do Not Transfer ${object} to an Emergency Room unless you have contacted me first.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nThis MIDEO safety statement is not limiting ${possessive} treatment other than for cardiac arrest.\n\n At this time ${possessive}  Goals of Care are more aligned with preventing pain and facilitating comfort.\n\nAll other conditions and potential treatments will be evaluated on a case by case basis and will depend upon ${possessive} Goals.\n\nHowever, you do have ${possessive} permission to consult Palliative Care.\n\nIf there is a change in ${possessive} condition or you require direction as how to treat ${possessive} condition, then please contact me at ${agentPhone}.`,
      },
      eol: {
        patient: `Hello, I am ${name} & this is My Informed Decision on Video.\n\nMy Emergency Treatment Choice is No CPR/DNR and Allow My Natural Death.\n\nPlease Do Not Transfer me to an Emergency Room unless it is to control pain.\n\nMy Date of Birth is ${DOB} & this message has been validated by MIDEO Health.\n\nMy mind is sound as I voluntarily record this MIDEO Safety Statement.\n\nIf you are reviewing this message, then the time has come to withhold or withdraw all treatment that attempts to prolong my life.\n\nMy goals of care at this time are to attain a dignified, comfortable and peaceful natural death.\n\nPlease Do Not Follow Your Emergency Treatment Protocols such as ATLS, ACLS, Sepsis or Stroke Resuscitation.\n\nHowever, You do have my permission to consult Palliative Care or Hospice.\n\nIf there is a change in my condition or you require further direction, assurance or clarification of my goals, then please contact my HealthCare Agent, ${agentName} at ${agentPhone}.`,
        hca: `Hello, this is a My Informed decision on Video for my ${patientToAgent} ${name}.\n\n${Possessive} Date of Birth is ${DOB}.\n\n${Possessive} Emergency Treatment Choice is No CPR, ${pronoun} ${toBe} a DNR and Allow ${possessive}  Natural Death.\n\nPlease Do Not Transfer ${object} to an Emergency Room unless you have contacted me first and it is to control pain.\n\nI am ${agentName} and I am ${possessive} ${agentToPatient} & legally appointed HealthCare Agent.\n\nThis MIDEO Safety Statement has been validated by MIDEO Health.\n\nIf you are reviewing this message, then the time has come to withhold or withdraw all treatment that attempts to prolong ${possessive} life.\n\n${Possessive} goals of care at this time are to attain a dignified, comfortable and peaceful natural death.\n\nPlease Do Not Follow Your Emergency Treatment Protocols such as ATLS, ACLS, Sepsis or Stroke Resuscitation.\n\nHowever, You do have ${possessive} permission to consult Palliative Care or Hospice.\n\nIf there is a change in ${possessive} condition or you require further direction, assurance or clarification of ${possessive} goals, then please contact me at ${agentPhone}.`,
      },
    },
  };

  const code = chart?.SELECTED_TREATMENT_OPTION?.code;
  const hasLivingWill =
    (typeof(chart?.LIVING_WILL?.livingWillURI) === "string" ? chart?.LIVING_WILL?.livingWillURI !== "" : chart?.LIVING_WILL?.livingWillURI !== null) || (typeof(chart?.LIVING_WILL?.livingWill) === "string" ? chart?.LIVING_WILL?.livingWill !== 'false' : chart?.LIVING_WILL?.livingWill !== false);

  //if (chart?.livingWillURI === null) {  //PATIENT DOES NOT HAVE A LIVING WILL

  if (hasLivingWill === false) {
    //if (chart && chart?.livingWillURI && chart?.livingWillURI != null) {
    if (
      chart &&
      chart?.PATIENT_INFO_1?.selfReporting !== undefined &&
      chart?.PATIENT_INFO_1?.selfReporting !== null &&
      chart?.PATIENT_INFO_1?.selfReporting === (false || 'false')
    ) {
      script = scripts['noLW'][code]['hca'];
    } else {
      script = scripts['noLW'][code]['patient'];
    }
  } else {
    if (
      code === 'clarifications' ||
      (code === 'eol' && chart?.LIVING_WILL?.POLSTURI !== null)
    ) {
      //PATIENT HAS A LIVING WILL AND A POLST
      if (
        chart &&
        chart?.PATIENT_INFO_1?.selfReporting !== undefined &&
        chart?.PATIENT_INFO_1?.selfReporting !== null &&
        chart?.PATIENT_INFO_1?.selfReporting === (false || 'false')
      ) {
        script = scripts['lw'][code]['hcaPOLST'];
      } else {
        script = scripts['lw'][code]['patientPOLST'];
      }
    } else {
      //PATIENT HAS A LIVING WILL BUT NO POLST
      if (
        chart &&
        chart?.PATIENT_INFO_1?.selfReporting !== undefined &&
        chart?.PATIENT_INFO_1?.selfReporting !== null &&
        chart?.PATIENT_INFO_1?.selfReporting === (false || 'false')
      ) {
        script = scripts['lw'][code]['hca'];
      } else {
        script = scripts['lw'][code]['patient'];
      }
    }
  }

  // console.log("RETURNING VALUE");
 
 return script;
};
