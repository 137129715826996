import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitPhotoIDInformation = async (values) => {
  const basePatientId = values.uid;

  const PHOTO_ID = {
    licenseNumber: values.licenseNumber ? values.licenseNumber : "",
    issuingState: values.issuingState ? values.issuingState : "",
    photoIdentification: values.hasID ? values.hasID : false,
    photoIdentificationFrontURI: values.photoIdentificationFrontURI
      ? values.photoIdentificationFrontURI
      : "",
    photoIdentificationBackURI: values.photoIdentificationBackURI
      ? values.photoIdentificationBackURI
      : "",
  };

  const photoIdChartRef = doc(db, "users", basePatientId, "Form", "PHOTO_ID");
  await setDoc(photoIdChartRef, PHOTO_ID);
};
