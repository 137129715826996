import { db } from "../../constants/firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";


export const setInitialNeedsReview = (payload) => {
  const chart = payload;
  const uid = chart.PATIENT_INFO_1.uid;
  
  const docRef = doc(db, "users", uid);
  setDoc(docRef,
    {
      needsReview: {
        flagged: false,
        flaggedByName: "",
        flaggedByUID: "",
        flaggedTime: "",
        uid: uid,
        unFlaggedByName: "",
        unflaggedByUID: "",
      },
    },
    { merge: true }
  );
}