export const chiefComplaint = ((chart) => {
    let pronoun = 'They'
    let possessive = 'Their'

    if (chart.PATIENT_INFO_1.gender === 'male') {
        pronoun = 'He'
        possessive = 'his'
    }

    if (chart.PATIENT_INFO_1.gender === 'female') {
        pronoun = 'She'
        possessive = 'her'
    }

    if (chart.PATIENT_INFO_1.gender === 'non-binary') {
        pronoun = 'They'
        possessive = 'Their'
    }

    const placeHolderText = `${chart.PATIENT_INFO_1.firstName} is a ___________ at ____________ and wants to add MIDEO® to ${possessive} Medical Records and Estate Planning profile.  ${pronoun} has been concerned about his care, course and safety. ${pronoun} wants to learn how to better document Emergency vs. EOL choices for concern to assure ${possessive} treatment.`

    return placeHolderText
})
