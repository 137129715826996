import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const ENV = process.env.REACT_APP_ENVIRONMENT
  ? process.env.REACT_APP_ENVIRONMENT
  : "production";

const firebaseConfig = {
  development: {
    apiKey: "AIzaSyAOMs14Rp4xEwIKpVXfdsueZceGwLKpUgM",
    authDomain: "dev-mideo.firebaseapp.com",
    databaseURL: "https://dev-mideo-default-rtdb.firebaseio.com",
    projectId: "dev-mideo",
    storageBucket: "dev-mideo.appspot.com",
    messagingSenderId: "1094026880335",
    appId: "1:1094026880335:web:6d5e611a34daf0805f913a",
    measurementId: "G-STZ6DZ24L0",
  },
  production: {
    apiKey: "AIzaSyDbM2tdVaGoLZV1DOyzHPkBbmqzJU6ouO4",
    authDomain: "web-console-mideoid.firebaseapp.com",
    databaseURL: "https://web-console-mideoid-default-rtdb.firebaseio.com",
    projectId: "web-console-mideoid",
    storageBucket: "web-console-mideoid.appspot.com",
    messagingSenderId: "687346068978",
    appId: "1:687346068978:web:181a94e7512ea911928f6b",
    measurementId: "G-STZ6DZ24L0",
  },
};

const storageConfig = {
  development: {
    private: "gs://dev-mideo.appspot.com/",
    public: "gs://dev-mideo-public",
  },
  production: {
    private: "gs://web-console-mideoid.appspot.com",
    public: "gs://web-console-mideoid-public",
  },
};

const videoConfig = {
  development: "gs://dev-mideoid-videos",
  production: "gs://web-console-mideoid-videos",
};

const app = initializeApp(firebaseConfig[ENV]);
const db = getFirestore();
const storagePrivate = getStorage(app, storageConfig[ENV].private);
const storagePublic = getStorage(app, storageConfig[ENV].public);
const storageVideo = getStorage(app, videoConfig[ENV]);

export { app, db, storagePrivate, storagePublic, storageVideo };
