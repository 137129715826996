import React, { useRef, useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextInput,
} from '../../../../Features/Forms';
import { newPatientId } from '../../../../../data/localState/newPatientId';

import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from '../../../../../data/localState/currentUser';
import { Link, useNavigate } from 'react-router-dom';

import { uploadToCloudStorage } from './uploadToCloudStorage';
import { IDCardFront } from './IDCardFront';
import { IDCardBack } from './IDCardBack';
import { unlockFile } from './unlockFile';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import {
  Typography,
  TextField,
  Stack,
  useMediaQuery,
  Button,
  Box,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { submitPatientInformation } from '../../../../../data/mutations/NewPatient/submitPatientInformation';
import { submitPhotoIDInformation } from '../../../../../data/mutations/NewPatient/submitPhotoIDInformation';
import { CardUploadView } from './CardUploadView';
import { UploadingIndicator } from './UploadingIndicator';
import Webcam from 'react-webcam';
import { uploadPhotoAsCard } from './uploadPhotoAsCard';

export const IDInfo = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [photoIdentificationFrontURI, setphotoIdentificationFrontURI] = useState(chart?.PHOTO_ID?.photoIdentificationFrontURI);
  const [photoIdentificationBackURI, setphotoIdentificationBackURI] = useState(chart?.PHOTO_ID?.photoIdentificationBackURI);
  const [token, setToken] = useState();
  const [toggleID, setToggleID] = useState(true);
  const [uploading, setUploading] = useState(false)



  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
      console.log(chart);
    }
  }, [patient]);
  

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId)
      setChart( oldChart.chart )
    })();
  }, [patientId]);


  const handleUpload = async (e, fileName, setUri) => {
    uploadPhotoAsCard(e, fileName, setUri, setUploading, patientId, token)
  }

  const handlephotoIdentificationFrontURI = async (e) => {
    handleUpload(e, 'photoIdentificationFront', setphotoIdentificationFrontURI)
  };

  useEffect(() => {
    const photoIdentificationFrontURI = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'photoIdentificationFront',
      });
    })();
    
    const photoIdentificationBackURI = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'photoIdentificationBack',
      });
    })();

    setphotoIdentificationFrontURI(chart?.PHOTO_ID?.photoIdentificationFrontURI)
    setphotoIdentificationBackURI(chart?.PHOTO_ID?.photoIdentificationBackURI)
  }, [chart]);

  const handlephotoIdentificationBackURI = async (e) => {
    handleUpload(e, 'photoIdentificationBack', setphotoIdentificationBackURI)
  };
  useEffect(() => {
    if (formRef?.current?.values) {
      formRef.current.values.photoIdentificationBackURI = photoIdentificationBackURI
    }    
  }, [photoIdentificationBackURI])

  useEffect(() => {
    if (formRef?.current?.values) {
      formRef.current.values.photoIdentificationFrontURI = photoIdentificationFrontURI
    }  
  }, [photoIdentificationFrontURI])

  return (
    <div className="flex flex-col items-center">
      <UploadingIndicator
        open={uploading}
        size={"10em"}
        thickness={5}
        fontSize="2em"
      />
      {chart ? <Formik
        innerRef={formRef}
        enableReinitialize={false}
        initialValues={{
          uid: chart?.PATIENT_INFO_1?.uid || '',
          firstName: chart?.PATIENT_INFO_1?.firstName || '',
          licenseNumber: chart?.PHOTO_ID?.licenseNumber || undefined,
          hasID: chart?.PHOTO_ID?.photoIdentification || true,
          issuingState: chart?.PHOTO_ID?.issuingState || undefined,
          photoIdentificationFrontURI: chart?.PHOTO_ID?.photoIdentificationFrontURI || '',
          photoIdentificationBackURI: chart?.PHOTO_ID?.photoIdentificationBackURI || '',
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={(values, { setSubmitting }) => {
          formRef.current.values.photoIdentificationFrontURI = photoIdentificationFrontURI;
          formRef.current.values.photoIdentificationBackURI = photoIdentificationBackURI;
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          submitPhotoIDInformation(values);
          navigate('/signup/hipaaSignatures');
        }}
      >
        {({ isSubmitting, errors, touched, handleChange, values, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
              <Typography
                variant="h4"
                sx={{
                  color: '#1B405A',
                  fontSize: '32px',
                  fontFamily: 'Helvetica Neue',
                  fontWeight: 700,
                  textAlign: 'center',
                  margin: '29px 0',
                  width: 'fit-content',
                }}
              >
                ID & Forms
              </Typography>
              <Webcam width={0} height={0} audio={false} video={false} />
              <Typography>
                In this section of the form, we will be requiring a picture for your card.
                If you have not allowed camera access, please follow the instructions below to change your settings to allow camera access before moving forward in the process.
              </Typography>
              <Stack 
                  direction="column"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Typography
                justifyContent={"center"}
                fontWeight={"bold"}
                marginY={"1em"}
              >
                <a
                  href='https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop'
                  target='_blank'
                >
                  Chrome
                </a>
              </Typography>
              <Typography
                justifyContent={"center"}
                fontWeight={"bold"}
                marginY={"1em"}
              >
                <a
                  href='https://support.apple.com/guide/mac-help/control-access-to-your-camera-mchlf6d108da/mac'
                  target='_blank'
                >
                  Safari
                </a>
              </Typography>
              <Typography
                justifyContent={"center"}
                fontWeight={"bold"}
                marginY={"1em"}
              >
                <a
                  href='https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions'
                  target="_blank"
                >
                  Firefox
                </a>
              </Typography>
              </Stack>
              
              <Typography
                variant="h4"
                sx={{
                  color: '#1B405A',
                  fontSize: '32px',
                  fontFamily: 'Helvetica Neue',
                  fontWeight: 700,
                  textAlign: 'center',
                  margin: '29px 0',
                  width: 'fit-content',
                }}
              >
                Photo ID
              </Typography>

            
            {toggleID && chart ? (
              <Stack
                direction="column"
                sx={{ justifyContent: 'center', alignItems: 'center' }}
              >
                
                <FormikTextInput
                  label="Identication Card Number: "
                  name="licenseNumber"
                  required={toggleID ? true : null}
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Issuing State: "
                  name="issuingState"
                  required={toggleID ? true : null}
                  type="text"
                  handleChange={handleChange}
                />
                <CardUploadView
                  uri={photoIdentificationFrontURI}
                  handleUpload={handlephotoIdentificationFrontURI}
                  altText={"ID card front"}
                  inputId={"IDFront"}
                  inputName={"photoIdentificationFrontURI"}
                  title={"ID Card Front:"}
                />
                <CardUploadView
                  uri={photoIdentificationBackURI}
                  handleUpload={handlephotoIdentificationBackURI}
                  altText={"ID card back"}
                  inputId={"IDBack"}
                  inputName={"photoIdentificationBackURI"}
                  title={"ID Card Back:"}
                />
              </Stack>
            ) : null}
            <div style={{ margin: '0 auto', width: 'fit-content' }}>
              <button
                type="submit"
                disabled={(photoIdentificationFrontURI === (null || "" || undefined)) || (photoIdentificationBackURI === (null || "" || undefined)) || (formRef?.current?.values?.licenseNumber === (null || "" || undefined)) || (formRef?.current?.values?.issuingState === (null || "" || undefined))}
                className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save & Continue <ChevronRightIcon sx={{ fontSize: '32px' }} />
              </button>
            </div>
          </Form>
        )}
      </Formik> : null}
    </div>
  );
};
