import React from "react";
import Stack from "@mui/material/Stack";
import { currentUser } from "../../data/localState/currentUser";
import { Link } from "react-router-dom";
import { LogOutBtn } from "../Auth/components/LogOutBtn";

export const HeaderMenu = () => {
  const image = require("../../assets/MIDEOLogo.png");
  const user = currentUser.use();
  const loggedIn = React.useMemo(() => {
    return !!user;
  }, [user]);

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        display: "flex",
        height: 84,
        backgroundColor: "black",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <img
        style={{ height: 80, marginLeft: "41px" }}
        src={image}
        alt="MIDEO Logo"
      />
      {process.env.REACT_APP_ENVIRONMENT === "development" && (
        <p style={{ fontSize: 24, color: "red" }}>Development</p>
      )}
      <Stack
        direction="row"
        style={{
          width: "fit-content",
          display: "flex",
          color: "white",
          justifyContent: "space-evenly",
          marginRight: "41px",
          minWidth: "390px",
        }}
      >
        {!loggedIn && (
          <Link
            to="login"
            style={{ margin: "0 16px", height: 80 }}
            alt="MIDEO Login"
          >
            Login
          </Link>
        )}
        {!loggedIn && (
          <Link
            to="signup"
            style={{ margin: "0 16px", height: 80 }}
            alt="MIDEO Signup"
          >
            Signup
          </Link>
        )}
        <div style={{ margin: "0 16px" }}>
          <a
            style={{ height: 80 }}
            href="https://mideocard.com"
            alt="MIDEO Card Home"
          >
            Home
          </a>
        </div>
        <div style={{ margin: "0 16px" }}>
          <a
            style={{ height: 80 }}
            href="https://mideohealth.com/for-clinicians/"
            alt="For Clinicians"
          >
            For Clinicians
          </a>
        </div>
        <div style={{ margin: "0 16px" }}>
          <a style={{ height: 80 }} href="https://mideocard.com/faq/" alt="FAQ">
            FAQ
          </a>
        </div>
        <div style={{ margin: "0 16px" }}>
          <a
            style={{ height: 80 }}
            href="https://mideohealth.com/get-started/"
            alt="Contact"
          >
            Contact
          </a>
        </div>
        {loggedIn && <LogOutBtn />}
      </Stack>
    </Stack>
  );
};
