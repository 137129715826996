import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { uploadToCloudStorage } from "./uploadToCloudStorage";
import Modal from "react-modal";

export const SignatureBox = (props) => {
  const {
    fileName,
    isOpen,
    setIsOpen,
    patientId,
    setSignatureExists,
    setSignatureUri,
    setFormHasChanges,
    contentLabel,
  } = props;
  const sigRef = useRef();
  const [token, setToken] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    position: "absolute",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      height: "350px",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const convertDataURIToBlob = async (datauri) => {
    const blob = await (await fetch(datauri)).blob();
    return blob;
  };

  const uploadSignature = async (blob) => {
    const file = new File([blob], fileName, { type: blob.type });
    const payload = {
      uid: patientId,
      folder: "signatures",
      file: file,
      token: token,
    };
    const tempURI = await uploadToCloudStorage(payload);
    setSignatureExists(true);
    setSignatureUri(tempURI);
  };

  const handleSave = async () => {
    const datauri = sigRef.current.getTrimmedCanvas().toDataURL("image/png");
    const blob = await convertDataURIToBlob(datauri);

    uploadSignature(blob);
    setFormHasChanges(true);
    closeModal();
  };

  const handleClear = () => {
    sigRef.current.clear();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        position: "absolute",
        overlay: {
          zIndex: 5,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          height: "350px",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel={contentLabel}
      ariaHideApp={false}
    >
      <div className="z-10">
        <div className="border-2 w-auto h-auto z-50">
          <SignatureCanvas
            ref={sigRef}
            canvasProps={{ width: 750, height: 250, className: "sigCanvas" }}
          />
        </div>
        <button
          onClick={handleSave}
          className="m-y-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save Signature
        </button>
        <button
          onClick={handleClear}
          className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Clear
        </button>
      </div>
    </Modal>
  );
};
