import React, { useState } from "react";
import { TakeNewPhoto } from "./TakeNewPhoto";
import { Button } from "@mui/material";

export const CardUploadView = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="flex flex-col mt-6">
      <div>
        <p className="text-center">{props.title}</p>
      </div>
      <div>
        {props.uri && props.uri !== "" ? (
          <img
            key={props.uri}
            className="h-auto w-48 float-left"
            src={props.uri}
            alt={props.altText}
          ></img>
        ) : null}
        <input
          className=""
          onChange={props.handleUpload}
          id={props.inputId}
          name={props.inputName}
          type="file"
        />
        <Button onClick={() => setVisible(true)}>Take Photo</Button>
        <TakeNewPhoto
          handleTakePicture={props.handlePhotoTaken || props.handleUpload}
          visible={visible}
          setVisible={setVisible}
          title={props.title}
        />
      </div>
    </div>
  );
};
