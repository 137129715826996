// AUTHENTICATION VIA EMAIL/PASSWORD MUST BE ENABLED IN THE FIREBASE CONSOLE BEFORE USING THIS MUTATION

import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../../constants/firebase";
import { setCurrentUser } from "../../localState/currentUser";
 
export const createUser = (payload) => {
  const auth = getAuth(app);
  createUserWithEmailAndPassword(auth,payload.email,payload.password)
    .then((userCredential) => {
      const user = userCredential.user;
      setCurrentUser(user);
      sendEmailVerification(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(
        `Error logging in user generated code: ${errorCode}. Message: ${errorMessage}`
      );
      alert("There was an error logging in. Please try again.");
    });
};
