export const getDiscountCode = (apiUri, uid) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
  
    const uri = encodeURI(`${apiUri}/discountCodes/${uid}`);
   
    return fetch(uri, requestOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          return response.status
        }
      })
      .catch(error => console.log('error', error));
  }