export const listProducts = (apiUri) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  const uri = encodeURI(`${apiUri}/products/`);
  console.log(apiUri)
  console.log(uri)
  return fetch(uri, requestOptions)
    .then(response => {
      if (response.status === 200) {        
        return response.json()
      } else {
        return response.status
      }
    })
    .then(result => {
      let localProductArr = Object.keys(result).map(key => ({ ...result[key], key: key }))
      localProductArr = localProductArr.sort((a, b) => a.sortOrder - b.sortOrder)
      return localProductArr;
    })
    .catch(error => console.log('error', error));
}