import { db, storagePublic } from "../../../../../data/constants/firebase";
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const saveLivingWillQRCode = (props) => {
    const dataUrl = props.dataUrl;
    const patientId = props.patientId;
    const storageRef = ref(storagePublic,`${patientId}/LivingWillQRCode.jpeg`)
    const message = dataUrl;

    // var uploadTask = storageRef.putString(message, 'data_url', { contentType: "image/jpg" });
    var uploadTask = uploadBytesResumable(storageRef, message, { contentType: "image/jpg" });


    // Listen for state changes, errors, and completion of the upload.
    return new Promise(function (resolve, reject) {
        uploadTask.on('state_changed', (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused': // or 'paused'
                        // console.log('Upload is paused');
                        break;
                    case 'running': // or 'running'
                        // console.log('Upload is running');
                        break;
                    default:
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        console.error("User doesn't have permission.");
                        break;
                    case "storage/canceled":
                        // User canceled the upload
                        console.error("User cancelled the upload.");
                        break;

                    case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        console.error("Unknown error.", error.code.status);
                        break;

                    default:
                }
            },
            async () => {
                // Upload completed successfully, now we can set the download URL in Firestore
                getDownloadURL(uploadTask.snapshot.ref).then( async(downloadURL) => {
                    // console.log('File available at ', downloadURL);

                    // unlockedURL = await unlockFile({ folder: folder, name: file.name, uid: uid, token: token })
                    resolve(downloadURL)
                })

                // uploadTask.snapshot.ref
                //     .getDownloadURL()
                //     .then((downloadURL) => {
                //         const docRef = db
                //             .collection("users")
                //             .doc(patientId)
                //             .collection("Chart")
                //             .doc("OTHER_DOCUMENTS");
                //         docRef.set({ livingWillQRCodeURI: downloadURL }, { merge: true });

                //         resolve(downloadURL);
                //     });
            }
        );
    });
};
