import React, { useRef, useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  FormikTextInput,
} from '../../../../Features/Forms';
import { getPatientChart } from '../../../../../data/mutations/NewPatient/getPatientChart';
import { currentUser } from '../../../../../data/localState/currentUser';
import { useNavigate } from 'react-router-dom';
import { submitInsuranceInformation } from '../../../../../data/mutations/NewPatient/submitInsuranceInformation';
import { uploadToCloudStorage } from './uploadToCloudStorage';
import { unlockFile } from './unlockFile';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import {
  Typography,
  TextField,
  Stack,
  Button,
  Box,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CardUploadView } from './CardUploadView';
import { SignatureOnFile } from './SignatureOnFile';
import { getProductApi } from '../../../../../data/mutations/NewPatient/api';
import { UploadingIndicator } from './UploadingIndicator';
import { submitABNInformation } from '../../../../../data/mutations/NewPatient/submitABNInformation';
import { submitMedicareInformation } from '../../../../../data/mutations/NewPatient/submitMedicareInformation';
import {lettersAndSpaces, phoneRegExp, zipRegExp} from '../../../../../constants';
import * as Yup from 'yup';
import { SignatureBox } from './SignatureBox';
import Webcam from 'react-webcam';
import { uploadPhotoAsCard } from './uploadPhotoAsCard';

export const InsuranceInfo = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const [chart, setChart] = useState();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [healthInsuranceCardFrontURI, setHealthInsuranceCardFrontURI] =
    useState(chart?.HEALTH_INSURANCE_INFO?.healthInsuranceCardFrontURI || '');
  const [healthInsuranceCardBackURI, setHealthInsuranceCardBackURI] = useState(
    chart?.HEALTH_INSURANCE_INFO?.healthInsuranceCardBackURI || ''
  );
  const [medicareCardFrontURI, setMedicareCardFrontURI] = useState(
    chart?.MEDICARE_INFO?.medicareCardFrontURI || ''
  );
  const [medicareCardBackURI, setMedicareCardBackURI] = useState(
    chart?.MEDICARE_INFO?.medicareCardBackURI || ''
  );
  const [
    medicareSupplementOtherCardFrontURI,
    setMedicareSupplementOtherCardFrontURI,
  ] = useState(chart?.MEDICARE_INFO?.medicareSupplementOtherCardFrontURI || '');
  const [
    medicareSupplementOtherCardBackURI,
    setMedicareSupplementOtherCardBackURI,
  ] = useState(chart?.MEDICARE_INFO?.medicareSupplementOtherCardBackURI || '');

  const [token, setToken] = useState();
  const [toggleInsured, setToggleInsured] = useState(false);
  const [toggleNotInsured, setToggleNotInsured] = useState(false);
  const [toggleMedicareDescriptors, setToggleMedicareDescriptors] = useState(
    []
  );
  const [toggleMedicare, setToggleMedicare] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [safeToSubmit, setSafeToSubmit] = useState(false);
  const [nonMedicareABNChoice, setToggleNonMedicareABNChoice] = useState(null);
  const [medicareABNChoice, setToggleMedicareABNChoice] = useState(null);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [medicareABNSigModalIsOpen, setMedicareABNSigModalIsOpen] =
    useState(false);
  const [medicareABNSignatureDataURI, setMedicareABNSignatureDataURI] =
    useState(chart?.MEDICARE_INFO?.signature1DataURI || '');
  const [medicareABNSignatureExists, setMedicareABNSignatureExists] =
    useState(false);

  const [nonMedicareABNSigModalIsOpen, setNonMedicareABNSigModalIsOpen] =
    useState(false);
  const [nonMedicareABNSignatureDataURI, setNonMedicareABNSignatureDataURI] =
    useState(chart?.MEDICARE_INFO?.signature1DataURI || '');
  const [nonMedicareABNSignatureExists, setNonMedicareABNSignatureExists] =
    useState(false);

  const [patientId, setPatientId] = useState(currentUser.use().uid);

  const [originalProductCost, setOriginalProductCost] = useState(500);

  const handleUpload = async (e, fileName, setUri) => {
    uploadPhotoAsCard(e, fileName, setUri, setUploading, patientId, token)
  }

  const handleMedicareCardFront = async (e) => {
    handleUpload(e, 'medicareCardFront', setMedicareCardFrontURI);
  };

  const handleMedicareCardBack = async (e) => {
    handleUpload(e, 'medicareCardBack', setMedicareCardBackURI);
  };

  const handleMedicareSupplementOtherCardFront = async (e) => {
    handleUpload(e, 'medicareSupplementOtherCardFront', setMedicareSupplementOtherCardFrontURI);
  };

  const handleMedicareSupplementOtherCardBack = async (e) => {
    handleUpload(e, 'medicareSupplementOtherCardBack', setMedicareSupplementOtherCardBackURI);
  };

  const handleHealthInsuranceCardFront = async (e) => {
    handleUpload(e, 'healthInsuranceCardFront', setHealthInsuranceCardFrontURI);
  };


  const handleHealthInsuranceCardBack = async (e) => {
    handleUpload(e, 'healthInsuranceCardBack', setHealthInsuranceCardBackURI);
  };

  const patient = currentUser.use();
  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  useEffect(() => {
    if (formRef?.current?.values) {
    }
  }, [formRef]);


  useEffect(() => {
    const healthInsuranceCardFront = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'healthInsuranceCardFront',
      });
    })();
    const healthInsuranceCardBack = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'healthInsuranceCardBack',
      });
    })();
    const medicareCardFront = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'medicareCardFront',
      });
    })();
    const medicareCardBack = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'medicareCardBack',
      });
    })();
    const medicareSupplementOtherCardFront = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'medicareSupplementOtherCardFront',
      });
    })();
    const medicareSupplementOtherCardBack = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: 'images',
        name: 'medicareSupplementOtherCardBack',
      });
    })();

    if (chart?.MEDICARE_INFO?.recipient) {
      setMedicareABNSignatureDataURI(chart?.ABN_INFO?.signature1DataURI);
      if (chart?.ABN_INFO?.signature1DataURI !== '') {
        setMedicareABNSignatureExists(true);
      }
    } else if (chart?.HEALTH_INSURANCE_INFO?.insured) {
      setNonMedicareABNSignatureDataURI(chart?.ABN_INFO?.signature1DataURI);
      if (chart?.ABN_INFO?.signature1DataURI !== '') {
        setNonMedicareABNSignatureExists(true);
      }
    }
    setHealthInsuranceCardFrontURI(
      chart?.HEALTH_INSURANCE_INFO?.healthInsuranceCardFrontURI
    );
    setHealthInsuranceCardBackURI(
      chart?.HEALTH_INSURANCE_INFO?.healthInsuranceCardBackURI
    );
    setMedicareCardFrontURI(chart?.MEDICARE_INFO?.medicareCardFrontURI);
    setMedicareCardBackURI(chart?.MEDICARE_INFO?.medicareCardBackURI);
    setMedicareSupplementOtherCardFrontURI(
      chart?.MEDICARE_INFO?.medicareCardFrontURI2
    );
    setMedicareSupplementOtherCardBackURI(
      chart?.MEDICARE_INFO?.medicareCardBackURI2
    );
  }, [chart]);



  useEffect(() => {
    if (chart?.MEDICARE_INFO) {
      if (chart?.MEDICARE_INFO?.medicareDescriptors !== []) {
        setToggleMedicareDescriptors(chart?.MEDICARE_INFO?.medicareDescriptors);
      }
    }

    const init = async () => {
      const productRes = await getProductApi(
        chart?.PAYMENT_INFO?.selectedProduct
      );
      setOriginalProductCost(productRes && productRes.oneTimePrice);
    };

    init();

    if (chart?.MEDICARE_INFO) {
      if (chart?.MEDICARE_INFO?.recipient === true) {
        setToggleMedicare(true);
      } else if (chart?.MEDICARE_INFO?.recipient === false) {
        setToggleMedicare(false);
      } else {
        setToggleMedicare(false);
      }
    }

    if (chart?.HEALTH_INSURANCE_INFO) {
      if (chart?.HEALTH_INSURANCE_INFO?.insured === (true || 'true')) {
        setToggleInsured(true);
      } else if (chart?.HEALTH_INSURANCE_INFO?.insured === false) {
        setToggleInsured(false);
      } else {
        setToggleInsured(false);
      }
    }

    if (chart?.HEALTH_INSURANCE_INFO) {
      if (chart?.HEALTH_INSURANCE_INFO?.notInsured === (true || 'true')) {
        setToggleNotInsured(true);
      } else if (chart?.HEALTH_INSURANCE_INFO?.notInsured === false) {
        setToggleNotInsured(false);
      } else {
        setToggleNotInsured(false);
      }
    }

    if (formRef?.current?.values) {
      if (
        formRef?.current?.values.nonMedicareABNChoice ===
        'OPTION 1. I will be asked to pay now, but I also want my insurance billed for an official decision on payment. I understand if my insurance doesn’t pay, I am responsible for payment, but I can appeal to my insurance carrier. If my insurance does pay, you will refund any payments I made to you, less co-pays or deductibles.'
      ) {
        setToggleNonMedicareABNChoice('OptionOne');
      } else if (
        formRef?.current?.values.nonMedicareABNChoice ===
        'OPTION 2. I want the MIDEO listed above, but do not bill my insurance. I understand that payment is required in advance of my MIDEO appointment. I also understand that I cannot appeal or make an insurance reimbursement claim if my insurance is not billed.'
      ) {
        setToggleNonMedicareABNChoice('OptionTwo');
      }
    }

    if (formRef?.current?.values) {
      if (
        formRef?.current?.values.medicareABNChoice ===
        'OPTION 1. Pay now, but also bill insurance. Patient understands if insurance doesn’t pay, patient is responsible for payment, but can appeal to insurance carrier. If insurance does pay, MIDEO will refund any payments made by patient, less co-pays or deductibles.'
      ) {
        setToggleMedicareABNChoice('OptionOne');
      } else if (
        formRef?.current?.values.medicareABNChoice ===
        'OPTION 2. Do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed.'
      ) {
        setToggleMedicareABNChoice('OptionTwo');
      } else if (
        formRef?.current?.values.medicareABNChoice ===
        'OPTION 3. Patient is not a medicare recipient.'
      ) {
        setToggleMedicareABNChoice('OptionThree');
      }
    }
  }, [chart]);

  //sets chart variable
  useEffect(() => {
    if (toggleMedicare === true) {
      formRef.current.values.medicare = true;
      setToggleInsured(false);
      setToggleNotInsured(false);
    } else if (toggleMedicare === false) {
      if (formRef?.current?.values?.medicare) {
      formRef.current.values.medicare = false;
      }
    }
  }, [toggleMedicare]);

  useEffect(() => {
    if (toggleInsured === true) {
      formRef.current.values.insured = true;
      setToggleMedicare(false);
      setToggleNotInsured(false);
    } else if (toggleInsured === false) {
      if (formRef?.current?.values?.insured) {
      formRef.current.values.insured = false;
      }
    }
  }, [toggleInsured]);

   useEffect(() => {
    if (toggleNotInsured === true) {
      formRef.current.values.notInsured = true;
      setToggleMedicare(false);
      setToggleInsured(false);
    } else if (toggleNotInsured === false) {
      if (formRef?.current?.values?.notInsured) {
      formRef.current.values.notInsured = false;
      }
    }
  }, [toggleNotInsured]);

  useEffect(() => {
    
    if (toggleInsured) {
      if ((nonMedicareABNSignatureDataURI === undefined) || (nonMedicareABNSignatureDataURI === "")) {
        setSafeToSubmit(false)
        return
      }
      if ((healthInsuranceCardFrontURI === undefined) || (healthInsuranceCardFrontURI === "")) {
        setSafeToSubmit(false)
        return
      }
      if ((healthInsuranceCardBackURI === undefined) || (healthInsuranceCardBackURI === "")) {
        setSafeToSubmit(false)
        return
      }
    }

    if (toggleMedicare) {
      if ((medicareABNSignatureDataURI === undefined) || (medicareABNSignatureDataURI === "")) {
        setSafeToSubmit(false)
        return
      }
      if ((medicareCardFrontURI === undefined) || (medicareCardFrontURI === "")) {
        setSafeToSubmit(false)
        return
      }
      if ((medicareCardBackURI === undefined) || (medicareCardBackURI === "")) {
        setSafeToSubmit(false)
        return
      }
    }

    if (toggleMedicareDescriptors && (toggleMedicareDescriptors[1] === 'Medicare Supplement' ||
      toggleMedicareDescriptors[1] === 'Other')) {
      
      if ((medicareSupplementOtherCardFrontURI === undefined) || (medicareSupplementOtherCardFrontURI === "")) {
        setSafeToSubmit(false)
        return
      }
      if ((medicareSupplementOtherCardBackURI === undefined) || (medicareSupplementOtherCardBackURI === "")) {
        setSafeToSubmit(false)
        return
      }
    }

    setSafeToSubmit(true)
 }, [
   toggleInsured,
   toggleMedicare,
   toggleNotInsured,
   toggleMedicareDescriptors,
   healthInsuranceCardFrontURI,
   healthInsuranceCardBackURI,
   medicareCardFrontURI,
   medicareCardBackURI,
   medicareSupplementOtherCardFrontURI,
   medicareSupplementOtherCardBackURI,
   nonMedicareABNSignatureDataURI,
   medicareABNSignatureDataURI
 ])

 useEffect((() => {
  if ((medicareCardFrontURI !== ('' || undefined) && medicareCardBackURI !== ('' || undefined))) {
    if (medicareABNSignatureExists) {
      if (medicareABNChoice !== null) {
      setSafeToSubmit(true)
      }
    }
  }
 }), [medicareCardFrontURI, medicareABNChoice, nonMedicareABNChoice, medicareCardBackURI, healthInsuranceCardBackURI, medicareABNSignatureExists, healthInsuranceCardFrontURI, nonMedicareABNSignatureExists])


  useEffect(() => {
    setToggleMedicareDescriptors(formRef?.current?.values?.medicareDescriptors);
  }, [toggleState]);

  const handleDescriptorChangeTraditionalMedicare = () => {
    if (
      formRef.current.values.medicareDescriptors[0] === 'Traditional Medicare'
    ) {
      formRef.current.values.medicareDescriptors[0] = null;
    } else {
      formRef.current.values.medicareDescriptors[0] = 'Traditional Medicare';
    }
    setToggleMedicareDescriptors(formRef.current.values.medicareDescriptors);
    setToggleState((prev) => !prev);
  };

  const handleDescriptorChangeMedicareAdvantage = () => {
    if (
      formRef.current.values.medicareDescriptors[0] === 'Medicare Advantage'
    ) {
      formRef.current.values.medicareDescriptors[0] = null;
    } else {
      formRef.current.values.medicareDescriptors[0] = 'Medicare Advantage';
    }
    setToggleMedicareDescriptors(formRef.current.values.medicareDescriptors);
    setToggleState((prev) => !prev);
  };

  const handleDescriptorChangeMedicareSupplement = () => {
    if (
      formRef.current.values.medicareDescriptors[1] === 'Medicare Supplement'
    ) {
      formRef.current.values.medicareDescriptors[1] = null;
    } else {
      formRef.current.values.medicareDescriptors[1] = 'Medicare Supplement';
    }
    setToggleMedicareDescriptors(formRef.current.values.medicareDescriptors);
    setToggleState((prev) => !prev);
  };

  const handleDescriptorChangeOther = () => {
    if (formRef.current.values.medicareDescriptors[1] === 'Other') {
      formRef.current.values.medicareDescriptors[1] = null;
    } else {
      formRef.current.values.medicareDescriptors[1] = 'Other';
    }
    setToggleMedicareDescriptors(formRef.current.values.medicareDescriptors);
    setToggleState((prev) => !prev);
  };

  return (
    <div className="flex flex-col items-center">
      <UploadingIndicator
        open={uploading}
        size={'10em'}
        thickness={5}
        fontSize="2em"
      />
      {chart ? (
        <Formik
          innerRef={formRef}
          enableReinitialize={false}
          initialValues={{
            uid: patientId,
            firstName: chart?.PATIENT_INFO_1?.firstName || '',
            medicareABNChoice: chart?.ABN_INFO?.medicareABNChoice || '',
            nonMedicareABNChoice: chart?.ABN_INFO?.nonMedicareABNChoice || '',
            city: chart?.HEALTH_INSURANCE_INFO?.city || '',
            additionalInfo: chart?.ABN_INFO?.additionalInfo || '',
            country: chart?.HEALTH_INSURANCE_INFO?.country || '',
            insuranceCo: chart?.HEALTH_INSURANCE_INFO?.insuranceCo || '',
            insured: chart?.HEALTH_INSURANCE_INFO?.insured || false,
            notInsured: chart?.HEALTH_INSURANCE_INFO?.notInsured || false,
            secondaryInsuranceCo:
              chart?.HEALTH_INSURANCE_INFO?.secondaryInsuranceCo || '',
            spouseDOB: chart?.HEALTH_INSURANCE_INFO?.spouseDOB || '',
            spouseName: chart?.HEALTH_INSURANCE_INFO?.spouseName || '',
            street: chart?.HEALTH_INSURANCE_INFO?.street || '',
            state: chart?.HEALTH_INSURANCE_INFO?.state || '',
            zip: chart?.HEALTH_INSURANCE_INFO?.zip || '',
            medicareABNSignatureDataURI:
              chart?.ABN_INFO?.signature1DataURI || '',
            nonMedicareABNSignatureDataURI:
              chart?.ABN_INFO?.signature1DataURI || '',
            medicare: chart?.MEDICARE_INFO?.recipient || false,
            planName: chart?.MEDICARE_INFO?.planName || '',
            medicareDescriptors:
              chart?.MEDICARE_INFO?.medicareDescriptors || [],
            healthInsuranceCardFrontURI:
              chart?.HEALTH_INSURANCE_INFO?.healthInsuranceCardFrontURI || '',
            healthInsuranceCardBackURI:
              chart?.HEALTH_INSURANCE_INFO?.healthInsuranceCardBackURI || '',
            medicareCardFrontURI:
              chart?.MEDICARE_INFO?.medicareCardFrontURI || '',
            medicareCardBackURI:
              chart?.MEDICARE_INFO?.medicareCardBackURI || '',
            medicareSupplementOtherCardFrontURI:
              chart?.MEDICARE_INFO?.medicareCardFrontURI2 || '',
            medicareSupplementOtherCardBackURI:
              chart?.MEDICARE_INFO?.medicareCardBackURI2 || '',
          }}
          // validationSchema={PatientInfoSchema}
          onSubmit={(values, { setSubmitting }) => {
            formRef.current.values.medicareABNSignatureDataURI =
              medicareABNSignatureDataURI;
            formRef.current.values.nonMedicareABNSignatureDataURI =
              nonMedicareABNSignatureDataURI;
            formRef.current.values.healthInsuranceCardFrontURI =
              healthInsuranceCardFrontURI;
            formRef.current.values.healthInsuranceCardBackURI =
              healthInsuranceCardBackURI;
            formRef.current.values.medicareCardFrontURI = medicareCardFrontURI;
            formRef.current.values.medicareCardBackURI = medicareCardBackURI;
            formRef.current.values.medicareSupplementOtherCardFrontURI =
              medicareSupplementOtherCardFrontURI;
            formRef.current.values.medicareSupplementOtherCardBackURI =
              medicareSupplementOtherCardBackURI;
            const newChartData = {
              ...previousChartData,
              ...values,
            };
            setNewPatientChartData(newChartData);
            submitABNInformation(values);
            submitMedicareInformation(values);
            submitInsuranceInformation(values);
            navigate('/signup/IDInfo');
          }}
          validationSchema={Yup.object().shape({
              insured: Yup.mixed().oneOf(
                [true, false],
                'You must make a selection',
              ),
              insuranceCo: Yup.string().when('insured', {
                is: true,
                then: Yup.string()
                  .required('Please enter your insurance company name')
                  .min(
                    2,
                    ({min}) =>
                      `Insurance company name should be at least ${min} characters`,
                  )
                  .max(
                    50,
                    ({max}) =>
                      `Insurance company name should be shorter than ${max} characters`,
                  ),
                otherwise: Yup.string(),
              }),
              secondaryInsuranceCo: Yup.string().when('insured', {
                is: true,
                then: Yup.string()
                  .min(
                    2,
                    ({min}) =>
                      `Secondary Insurance company name should be at least ${min} characters`,
                  )
                  .max(
                    50,
                    ({max}) =>
                      `Secondary Insurance company name should be shorter than ${max} characters`,
                  ),
              }),
              spouseName: Yup.string().when('insured', {
                is: true,
                then: Yup.string()
                  .min(
                    2,
                    ({min}) =>
                      `Spouse's name should be at least ${min} characters`,
                  )
                  .max(
                    50,
                    ({max}) =>
                      `Spouse's name should be shorter than ${max} characters`,
                  ),
              }),
              // spouseDOB: Yup.date(),
              street: Yup.string().when('insured', {
                is: true,
                then: Yup.string()
                  .min(
                    7,
                    ({min}) =>
                      `Street Address should be at least ${min} characters`,
                  )
                  .max(
                    100,
                    ({max}) =>
                      `Street Address should be shorter than ${max} characters`,
                  ),
                city: Yup.string()
                  .min(
                    2,
                    ({min}) => `City name should be at least ${min} characters`,
                  )
                  .max(
                    50,
                    ({max}) =>
                      `City name should be shorter than ${max} characters`,
                  ),
              }),
              state: Yup.string().when('insured', {
                is: true,
                then: Yup.string()
                  .min(
                    2,
                    ({min}) =>
                      `Please use your state's two letter postal designation`,
                  )
                  .max(
                    2,
                    ({max}) =>
                      `Please use your state's two letter postal designation`,
                  ),
              }),
              zip: Yup.string().when('insured', {
                is: true,
                then: Yup.string().matches(zipRegExp, 'Zip code is not valid'),
              }),
              country: Yup.string(),
            })}
        >
          {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
            <Form style={{ width: '100%' }}>
              <Typography
                variant="h4"
                sx={{
                  color: '#1B405A',
                  fontSize: '32px',
                  fontFamily: 'Helvetica Neue',
                  fontWeight: 700,
                  textAlign: 'center',
                  margin: '29px 0',
                  width: 'fit-content',
                }}
              >
                Healthcare & Insurance
              </Typography>

              <Webcam width={0} height={0} audio={false} video={false} />
              <Typography>
                In this section of the form, we will be requiring a pictures of your insurance cards.
                If you have not allowed camera access, please follow the instructions below to change your settings to allow camera access before moving forward in the process.
              </Typography>
              <Stack 
                  direction="column"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Typography
                justifyContent={"center"}
                fontWeight={"bold"}
                marginY={"1em"}
              >
                <a
                  href='https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop'
                  target='_blank'
                >
                  Chrome
                </a>
              </Typography>
              <Typography
                justifyContent={"center"}
                fontWeight={"bold"}
                marginY={"1em"}
              >
                <a
                  href='https://support.apple.com/guide/mac-help/control-access-to-your-camera-mchlf6d108da/mac'
                  target='_blank'
                >
                  Safari
                </a>
              </Typography>
              <Typography
                justifyContent={"center"}
                fontWeight={"bold"}
                marginY={"1em"}
              >
                <a
                  href='https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions'
                  target="_blank"
                >
                  Firefox
                </a>
              </Typography>
              </Stack>

              <Typography
                variant="h4"
                sx={{
                  color: '#1B405A',
                  fontSize: '28px',
                  textAlign: 'center',
                  fontFamily: 'Helvetica Neue',
                  fontWeight: 500,
                  margin: '29px auto 31px auto',
                  width: 'fit-content',
                }}
              >
                Do you have Insurance or Medicare?
              </Typography>
              <Stack
                direction="row"
                sx={{
                  width: 'fit-content',
                  height: 'fit-content',
                  margin: '7px auto 38px auto',
                }}
              >
                <Button
                  name="insured"
                  onClick={() => {
                    setToggleInsured(prev => !prev);
                  }}
                  variant="contained"
                  sx={{
                    width: '18vw',
                    height: '100px',
                    padding: 0,
                    borderRadius: '4px 0px 0px 4px',
                    textTransform: 'none',
                    color: toggleInsured === true ? '#FFFFFF' : '#00000099',
                    fontSize: '16px',
                    backgroundColor:
                      toggleInsured === true ? '#4B8596' : '#FFFFFF',
                  }}
                >
                  Insurance
                </Button>
                <Button
                  name="medicare"
                  onClick={() => {
                    setToggleMedicare((prev) => !prev);
                  }}
                  variant="contained"
                  sx={{
                    width: '18vw',
                    height: '100px',
                    padding: 0,
                    borderRadius: '0px 4px 4px 0px',
                    textTransform: 'none',
                    color: toggleMedicare === true ? '#FFFFFF' : '#00000099',
                    fontSize: '16px',
                    backgroundColor:
                      toggleMedicare === true ? '#4B8596' : '#FFFFFF',
                  }}
                >
                  Medicare
                </Button>
                <Button
                  name="notInsured"
                  onClick={() => {
                    setToggleNotInsured((prev) => !prev);
                  }}
                  variant="contained"
                  sx={{
                    width: '18vw',
                    height: '100px',
                    padding: 0,
                    borderRadius: '4px 0px 0px 4px',
                    textTransform: 'none',
                    color: toggleNotInsured === true ? '#FFFFFF' : '#00000099',
                    fontSize: '16px',
                    backgroundColor:
                      toggleNotInsured === true ? '#4B8596' : '#FFFFFF',
                  }}
                >
                  No Insurance
                </Button>
              </Stack>
              {/* <p className="text-2xl text-black my-2">
                  Insurance Information
                </p>
                <div>
                  <FormikCheckbox
                    label="Insured? "
                    name="insured"
                    type="checkbox"
                    handleChange={handleChange}
                    className="float-left"
                  />
                  <p>I have insurance.</p>
                </div> */}
              {toggleInsured ? (
                <Stack
                  direction="column"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <FormikTextInput
                    label="Insurance Company: "
                    name="insuranceCo"
                    type="text"
                    handleChange={handleChange}
                  />
                  <FormikTextInput
                    label="Secondary Insurance Company: "
                    name="secondaryInsuranceCo"
                    type="text"
                    handleChange={handleChange}
                  />
                  <FormikTextInput
                    label="Spouse Full Name: "
                    name="spouseName"
                    type="text"
                    handleChange={handleChange}
                  />
                  <TextField
                    id="spouseDOB"
                    name="spouseDOB"
                    type="date"
                    title="Spouse Date of Birth"
                    onChange={handleChange}
                    className={'DOBInputField'}
                    sx={{
                      width: '270px',
                      backgroundColor: 'white',
                      color: '#00000099',
                      borderRadius: '4px',
                      margin: '7px 0',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormikTextInput
                    label="Spouse Street Address: "
                    name="street"
                    type="text"
                    handleChange={handleChange}
                  />
                  <FormikTextInput
                    label="City: "
                    name="city"
                    type="text"
                    handleChange={handleChange}
                  />
                  <FormikTextInput
                    label="State: "
                    name="state"
                    type="text"
                    handleChange={handleChange}
                  />
                  <FormikTextInput
                    label="ZIP Code: "
                    name="zip"
                    type="text"
                    handleChange={handleChange}
                  />
                  <FormikTextInput
                    label="Country "
                    name="country"
                    type="text"
                    handleChange={handleChange}
                  />
                  <p className="pt-4 text-xl font-bold text-center text-mideoformblue">
                    Advanced Beneficiary Notice of Non-Coverage (ABN) -
                    Non-Medicare Patients Only
                  </p>
                  <p className="text-xl text-mideoMaroon text-center">
                    NOTE: If my insurance does not pay for MIDEO, I may have to
                    pay.
                  </p>
                  <p>
                    Your Health Insurance does not pay for everything, even some
                    care that you or your health care provider has good reason
                    to believe you need. We expect your insurance may not pay
                    for MIDEO. The reason your insurance may not pay for MIDEO
                    is that it may be considered experimental. The cost of your
                    visit and evaluation is ${originalProductCost?.toFixed(2)}.
                  </p>
                  <Typography
                    sx={{
                      fontSize: '1.25rem',
                      lineHeight: '1.75rem',
                      color: '#1B405A',
                      marginTop: '16px',
                    }}
                  >
                    Non-Medicare ABN Selection:
                  </Typography>
                  <div className="flex flex-col">
                    <Stack
                      direction="row"
                      sx={{
                        width: 'fit-content',
                        height: 'auto',
                        margin: '7px auto',
                      }}
                    >
                      <Box
                        sx={{
                          width: 'fit-content',
                          height: 'auto',
                        }}
                      >
                        <Button
                          name="nonMedicareABNChoice"
                          value="OPTION 1. I will be asked to pay now, but I also want my insurance billed for an official decision on payment. I understand if my insurance doesn’t pay, I am responsible for payment, but I can appeal to my insurance carrier. If my insurance does pay, you will refund any payments I made to you, less co-pays or deductibles."
                          onClick={(e) => {
                            handleChange(e);
                            setToggleNonMedicareABNChoice('OptionOne');
                          }}
                          variant="contained"
                          sx={{
                            width: '22vw',
                            height: '100%',
                            padding: 2,
                            borderRadius: '4px 0px 0px 4px',
                            textTransform: 'none',
                            color:
                              nonMedicareABNChoice === 'OptionOne'
                                ? '#FFFFFF'
                                : '#00000099',
                            fontSize: '16px',
                            backgroundColor:
                              nonMedicareABNChoice === 'OptionOne'
                                ? '#4B8596'
                                : '#FFFFFF',
                          }}
                        >
                          I will be asked to pay now, but I also want my
                          insurance billed for an official decision on payment.
                          I understand if my insurance doesn’t pay, I am
                          responsible for payment, but I can appeal to my
                          insurance carrier. If my insurance does pay, you will
                          refund any payments I made to you, less co-pays or
                          deductibles.
                        </Button>
                        <Button
                          name="nonMedicareABNChoice"
                          value="OPTION 2. I want the MIDEO listed above, but do not bill my insurance. I understand that payment is required in advance of my MIDEO appointment. I also understand that I cannot appeal or make an insurance reimbursement claim if my insurance is not billed."
                          onClick={(e) => {
                            handleChange(e);
                            setToggleNonMedicareABNChoice('OptionTwo');
                          }}
                          variant="contained"
                          sx={{
                            width: '22vw',
                            height: '100%',
                            padding: 2,
                            borderRadius: '0px 4px 4px 0px',
                            textTransform: 'none',
                            color:
                              nonMedicareABNChoice === 'OptionTwo'
                                ? '#FFFFFF'
                                : '#00000099',
                            fontSize: '16px',
                            backgroundColor:
                              nonMedicareABNChoice === 'OptionTwo'
                                ? '#4B8596'
                                : '#FFFFFF',
                          }}
                        >
                          I want the MIDEO listed above, but do not bill my
                          insurance. I understand that payment is required in
                          advance of my MIDEO appointment. I also understand
                          that I cannot appeal or make an insurance
                          reimbursement claim if my insurance is not billed.
                        </Button>
                      </Box>
                    </Stack>

                    {/* <FormikSelect
                    label=""
                    name="nonMedicareABNChoice"
                    handleChange={handleChange}
                  >
                    <option value="OPTION 1. I will be asked to pay now, but I also want my insurance billed for an official decision on payment. I understand if my insurance doesn’t pay, I am responsible for payment, but I can appeal to my insurance carrier. If my insurance does pay, you will refund any payments I made to you, less co-pays or deductibles.">
                      OPTION 1. I will be asked to pay now, but I also want my
                      insurance billed for an official decision on payment. I
                      understand if my insurance doesn’t pay, I am responsible
                      for payment, but I can appeal to my insurance carrier. If
                      my insurance does pay, you will refund any payments I made
                      to you, less co-pays or deductibles.
                    </option>
                    <option value="OPTION 2. I want the MIDEO listed above, but do not bill my insurance. I understand that payment is required in advance of my MIDEO appointment. I also understand that I cannot appeal or make an insurance reimbursement claim if my insurance is not billed.">
                      OPTION 2. I want the MIDEO listed above, but do not bill
                      my insurance. I understand that payment is required in
                      advance of my MIDEO appointment. I also understand that I
                      cannot appeal or make an insurance reimbursement claim if
                      my insurance is not billed.
                    </option>
                  </FormikSelect> */}
                  </div>
                  <button
                    type="button"
                    onClick={(e) => setNonMedicareABNSigModalIsOpen(true)}
                    style={{ marginBottom: '16px' }}
                    className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Signature of Consent for Non-Medicare ABN Selection
                  </button>
                  {nonMedicareABNSignatureExists && (
                    <SignatureOnFile uri={nonMedicareABNSignatureDataURI} />
                  )}
                  <FormikTextInput
                    label="Additional ABN Info: "
                    name="additionalInfo"
                    type="text"
                    handleChange={handleChange}
                  />
                  <CardUploadView
                    uri={healthInsuranceCardFrontURI}
                    handleUpload={handleHealthInsuranceCardFront}
                    altText={'insurance card front'}
                    inputId={'insuranceFront'}
                    inputName={'healthInsuranceCardFront'}
                    title={'Insurance Card Front:'}
                  />
                  <CardUploadView
                    uri={healthInsuranceCardBackURI}
                    handleUpload={handleHealthInsuranceCardBack}
                    altText={'insurance card back'}
                    inputId={'insuranceBack'}
                    inputName={'healthInsuranceCardBack'}
                    title={'Insurance Card Back:'}
                  />
                  <SignatureBox
                    fileName={"ABN_INFO_ABNSignature"}
                    isOpen={nonMedicareABNSigModalIsOpen}
                    setIsOpen={setNonMedicareABNSigModalIsOpen}
                    patientId={patientId}
                    setSignatureExists={setNonMedicareABNSignatureExists}
                    setSignatureUri={setNonMedicareABNSignatureDataURI}
                    setFormHasChanges={setFormHasChanges}
                    contentLabel={"NonMedicareABN Signature Modal"}
                  />

              <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <button
                  type="submit"
                      disabled={!safeToSubmit}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: '32px' }} />
                </button>
              </div>
                </Stack>
              ) : null}
              {toggleNotInsured ? (

              <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <button
                  type="submit"
                  disabled={false}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: '32px' }} />
                </button>
              </div>
              ) : null}
              {/* <p className="mt-12 text-2xl text-black">
                  Medicare Information
                </p>
                <div>
                  <FormikCheckbox
                    label="Medicare? "
                    name="medicare"
                    type="checkbox"
                    handleChange={handleChange}
                    className="float-left"
                  />
                  <p className="ml-2">I have medicare.</p>
                </div> */}
              {toggleMedicare ? (
                <Stack
                  spacing={2}
                  direction="column"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <FormikTextInput
                    label="Plan Name: "
                    name="planName"
                    required={toggleMedicare ? true : null}
                    type="text"
                    handleChange={handleChange}
                  />
                  <Typography
                    sx={{
                      fontSize: '1.25rem',
                      lineHeight: '1.75rem',
                      color: '#1B405A',
                      marginTop: '16px',
                    }}
                  >
                    Choose one (optional):
                  </Typography>
                  <Stack
                    direction="row"
                    sx={{
                      width: 'fit-content',
                      height: 'fit-content',
                      margin: '7px auto',
                    }}
                  >
                    <Button
                      name="medicareDescriptors"
                      onClick={handleDescriptorChangeMedicareSupplement}
                      variant="contained"
                      sx={{
                        width: '18vw',
                        minWidth: 'fit-content',
                        height: '50px',
                        padding: 1,
                        borderRadius: '4px 0px 0px 4px',
                        textTransform: 'none',
                        color:
                          toggleMedicareDescriptors &&
                          toggleMedicareDescriptors[1] === 'Medicare Supplement'
                            ? '#FFFFFF'
                            : '#00000099',
                        fontSize: '16px',
                        backgroundColor:
                          toggleMedicareDescriptors &&
                          toggleMedicareDescriptors[1] === 'Medicare Supplement'
                            ? '#4B8596'
                            : '#FFFFFF',
                      }}
                    >
                      Medicare Supplement
                    </Button>
                    <Button
                      name="medicareDescriptors"
                      onClick={handleDescriptorChangeOther}
                      variant="contained"
                      sx={{
                        width: '18vw',
                        minWidth: 'fit-content',
                        height: '50px',
                        padding: 0,
                        borderRadius: '0px 4px 4px 0px',
                        textTransform: 'none',
                        color:
                          toggleMedicareDescriptors &&
                          toggleMedicareDescriptors[1] === 'Other'
                            ? '#FFFFFF'
                            : '#00000099',
                        fontSize: '16px',
                        backgroundColor:
                          toggleMedicareDescriptors &&
                          toggleMedicareDescriptors[1] === 'Other'
                            ? '#4B8596'
                            : '#FFFFFF',
                      }}
                    >
                      Other
                    </Button>
                  </Stack>
                  {/* <div role="group" aria-labelledby="checkbox-group">
                      <div className="flex flex-col mt-4">
                        <div className="flex flex-row">
                          <label>
                            <Field
                              className="mr-1"
                              type="checkbox"
                              name="medicareDescriptors"
                              value="Traditional Medicare"
                            />
                            Traditional Medicare
                          </label>
                        </div>
                        <div className="flex flex-row">
                          <label>
                            <Field
                              className="mr-1"
                              type="checkbox"
                              name="medicareDescriptors"
                              value="Medicare Advantage"
                            />
                            Medicare Advantage
                          </label>
                        </div>
                        <div className="flex flex-row">
                          <label>
                            <Field
                              className="mr-1"
                              type="checkbox"
                              name="medicareDescriptors"
                              value="I have a Medicare Supplement"
                            />
                            Medicare Supplement
                          </label>
                        </div>
                        <div className="flex flex-row">
                          <label>
                            <Field
                              className="mr-1"
                              type="checkbox"
                              name="medicareDescriptors"
                              value="Other"
                            />
                            Other
                          </label>
                        </div>
                      </div>
                    </div> */}
                  <p className="pt-4 text-xl font-bold text-center text-mideoformblue">
                    Advanced Beneficiary Notice of Non-Coverage (ABN) - Medicare
                    Patients Only
                  </p>
                  <p>
                    Medicare may not pay for everything. To submit your payment,
                    please enter your information and click on the "Submit
                    Payment” button below. The total cost for the process and
                    the physical MIDEO ID Card is $
                    {originalProductCost?.toFixed(2)}.
                  </p>
                  <p>
                    If you choose Option 1 or 2, we may help you to use any
                    other insurance that you might have, but Medicare cannot
                    require us to do so.
                  </p>
                  <Typography
                    sx={{
                      fontSize: '1.25rem',
                      lineHeight: '1.75rem',
                      color: '#1B405A',
                      marginTop: '16px',
                    }}
                  >
                    Medicare ABN Selection:
                  </Typography>
                  <Stack
                    direction="column"
                    sx={{
                      width: 'fit-content',
                      height: 'auto',
                      margin: '7px auto',
                    }}
                  >
                    <Button
                      name="medicareABNChoice"
                      value="OPTION 1. Pay now, but also bill insurance. Patient understands if insurance doesn’t pay, patient is responsible for payment, but can appeal to insurance carrier. If insurance does pay, MIDEO will refund any payments made by patient, less co-pays or deductibles."
                      onClick={(e) => {
                        handleChange(e);
                        setToggleMedicareABNChoice('OptionOne');
                      }}
                      variant="contained"
                      sx={{
                        width: '36vw',
                        height: 'fit-content',
                        padding: 2,
                        borderRadius: '4px 0px 0px 4px',
                        textTransform: 'none',
                        color:
                          medicareABNChoice === 'OptionOne'
                            ? '#FFFFFF'
                            : '#00000099',
                        fontSize: '16px',
                        backgroundColor:
                          medicareABNChoice === 'OptionOne'
                            ? '#4B8596'
                            : '#FFFFFF',
                      }}
                    >
                      Option 1: Pay now, but also bill insurance. Patient
                      understands if insurance doesn’t pay, patient is
                      responsible for payment, but can appeal to insurance
                      carrier. If insurance does pay, MIDEO will refund any
                      payments made by patient, less co-pays or deductibles.
                    </Button>
                    <Button
                      name="medicareABNChoice"
                      value="OPTION 2. Do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed."
                      onClick={(e) => {
                        handleChange(e);
                        setToggleMedicareABNChoice('OptionTwo');
                      }}
                      variant="contained"
                      sx={{
                        width: '36vw',
                        height: 'fit-content',
                        padding: 2,
                        borderRadius: '0px 4px 4px 0px',
                        textTransform: 'none',
                        color:
                          medicareABNChoice === 'OptionTwo'
                            ? '#FFFFFF'
                            : '#00000099',
                        fontSize: '16px',
                        backgroundColor:
                          medicareABNChoice === 'OptionTwo'
                            ? '#4B8596'
                            : '#FFFFFF',
                      }}
                    >
                      Option 2: Do not bill Medicare. Patient is responsible for
                      payment. Patient cannot appeal if Medicare is not billed.
                    </Button>
                    <Button
                      name="medicareABNChoice"
                      value="OPTION 3. Patient is not a medicare recipient."
                      onClick={(e) => {
                        handleChange(e);
                        setToggleMedicareABNChoice('OptionThree');
                      }}
                      variant="contained"
                      sx={{
                        width: '36vw',
                        height: 'fit-content',
                        padding: 2,
                        marginBottom: 3,
                        borderRadius: '0px 4px 4px 0px',
                        textTransform: 'none',
                        color:
                          medicareABNChoice === 'OptionThree'
                            ? '#FFFFFF'
                            : '#00000099',
                        fontSize: '16px',
                        backgroundColor:
                          medicareABNChoice === 'OptionThree'
                            ? '#4B8596'
                            : '#FFFFFF',
                      }}
                    >
                      Option 3: Patient is not a medicare recipient.
                    </Button>
                  </Stack>
                  <button
                    type="button"
                    onClick={(e) => setMedicareABNSigModalIsOpen(true)}
                    style={{ marginBottom: '16px' }}
                    className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Signature of Consent for Medicare ABN Selection
                  </button>
                  {medicareABNSignatureExists && (
                    <SignatureOnFile uri={medicareABNSignatureDataURI} />
                  )}
                  <FormikTextInput
                    label="Additional ABN Info: "
                    name="additionalInfo"
                    type="text"
                    handleChange={handleChange}
                  />
                  <CardUploadView
                    uri={medicareCardFrontURI}
                    handleUpload={handleMedicareCardFront}
                    altText={'medicare card front'}
                    inputId={'medicareFront'}
                    inputName={'medicareCardFront'}
                    title={'Medicare Card Front:'}
                  />
                  <CardUploadView
                    uri={medicareCardBackURI}
                    handleUpload={handleMedicareCardBack}
                    altText={'medicare card back'}
                    inputId={'medicareBack'}
                    inputName={'medicareCardBack'}
                    title={'Medicare Card Back:'}
                  />
                  {toggleMedicareDescriptors && (toggleMedicareDescriptors[1] === 'Medicare Supplement' ||
                  toggleMedicareDescriptors[1] === 'Other') ? (
                    <>
                      <CardUploadView
                        uri={medicareSupplementOtherCardFrontURI}
                        handleUpload={handleMedicareSupplementOtherCardFront}
                        altText={'medicare supplement or other card front'}
                        inputId={'medicareSupplementOtherFront'}
                        inputName={'medicareAdditionalCardFront'}
                        title={'Medicare Supplement or Other Card Front:'}
                      />
                      <CardUploadView
                        uri={medicareSupplementOtherCardBackURI}
                        handleUpload={handleMedicareSupplementOtherCardBack}
                        altText={'medicare supplement other card back'}
                        inputId={'medicareSupplementOtherBack'}
                        inputName={'medicareAdditionalCardBack'}
                        title={'Medicare Supplement or Other Card Back:'}
                      />
                    </>
                  ) : null}

                  <SignatureBox
                    fileName={"ABN_INFO_ABNSignature"}
                    isOpen={medicareABNSigModalIsOpen}
                    setIsOpen={setMedicareABNSigModalIsOpen}
                    patientId={patientId}
                    setSignatureExists={setMedicareABNSignatureExists}
                    setSignatureUri={setMedicareABNSignatureDataURI}
                    setFormHasChanges={setFormHasChanges}
                    contentLabel={"MedicareABN Signature Modal"}
                  />

              <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <button
                  type="submit"
                  disabled={!safeToSubmit}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: '32px' }} />
                </button>
              </div>
                </Stack>
              ) : null}
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
