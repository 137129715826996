import { uploadToCloudStorage } from "./uploadToCloudStorage";

export const uploadPhotoAsCard = async (e, fileName, setUri, setUploading, patientId, token) => {
  setUploading(true);
  const b64toBlob = (base64) => fetch(base64).then((res) => res.blob());
  var blob
  if (e.target) {
    blob = e.target.files[0]
  } else {
    blob = await b64toBlob(e);
  }
  const file = new File([blob], fileName, {
    type: blob.type,
  });
  const payload = {
    uid: patientId,
    folder: 'images',
    file: file,
    token: token,
  };
  const tempURI = await uploadToCloudStorage(payload);
  setUri(tempURI);
  setUploading(false);
  return tempURI
}