import React, { useRef, useState, useEffect } from 'react';
import { newPatientId } from '../../../../../data/localState/newPatientId';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from '../../../../../data/localState/currentUser';
import * as Yup from 'yup';
import {lettersAndSpaces, phoneRegExp, zipRegExp} from '../../../../../constants';
import { FormikTextInput } from '../../../../Features/Forms';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { submitContactInformation } from '../../../../../data/mutations/NewPatient/submitContactInformation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Typography,
  Backdrop,
  Stack,
  useMediaQuery,
  Button,
  Box,
  Fade,
  Modal,
} from '@mui/material';

const patientVideoUri =
  'https://storage.googleapis.com/mideoid-console-public/videos/instructional/MIDEO%20ID%20Card%20-%20Overview%20Video%20Patient.mp4';
const agentVideoUri =
  'https://storage.googleapis.com/mideoid-console-public/videos/instructional/MIDEO%20ID%20Card%20-%20Overview%20Video%20Agent.mp4';

export const HealthcarePartners = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate('/signup/payment');
  };
  const isSlim = useMediaQuery('(max-width:1040px)');
  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);
  
  const [uri, setUri] = useState(
    chart && chart.selfReporting ? patientVideoUri : agentVideoUri
  );

  useEffect(() => {
    chart && setUri(chart.selfReporting ? patientVideoUri : agentVideoUri);
  }, [chart]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId)
      setChart( oldChart.chart )
    })();
  }, [patientId]);


  return (
    <div className="flex flex-col items-center">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 840,
              bgcolor: '#CADCE1',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              id="video-modal-title"
              variant="h6"
              component="h2"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 700,
              }}
            >
              Learn More About MIDEO
            </Typography>
            <Typography
              id="video-modal-description"
              sx={{
                mt: 2,
                color: '#1B405A',
                fontSize: '18px',
                textAlign: 'center',
                fontFamily: 'Helvetica Neue',
                fontWeight: 500,
              }}
            >
              Watch this 90 second video that will tell you more about MIDEO and
              what to expect next.
            </Typography>
            <Box style={{ height: '320px', width: '500px', marginTop: '32px' }}>
              <video controls>
                <source
                  key={uri}
                  src={uri}
                  type="video/mp4"
                  alt="Learn More About MIDEO Video"
                />
                we have a problem
              </video>
              <Button
                onClick={handleClose}
                sx={{ display: 'flex', margin: '16px auto' }}
              >
                Continue <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: patientId,
          doctor: chart?.DOCTOR_INFO?.doctor || '',
          drPhone: chart?.DOCTOR_INFO?.drPhone || '',
          drStreetAddress: chart?.DOCTOR_INFO?.drStreetAddress || '',
          drCity: chart?.DOCTOR_INFO?.drCity || '',
          drState: chart?.DOCTOR_INFO?.drState || '',
          drZIP: chart?.DOCTOR_INFO?.drZIP || '',
          drCountry: chart?.DOCTOR_INFO?.drCountry || '',
          hospital: chart?.DOCTOR_INFO?.hospital || '',
          hospitalStreetAddress: chart?.DOCTOR_INFO?.hospitalStreetAddress || '',
          hospitalCity: chart?.DOCTOR_INFO?.hospitalCity || '',
          hospitalState: chart?.DOCTOR_INFO?.hospitalState || '',
          hospitalZIP: chart?.DOCTOR_INFO?.hospitalZIP || '',
          hospitalCountry: chart?.DOCTOR_INFO?.hospitalCountry || '',
          attorney: chart?.DOCTOR_INFO?.attorney || '',
          attorneyPhone: chart?.DOCTOR_INFO?.attorneyPhone || '',
          attorneyStreetAddress: chart?.DOCTOR_INFO?.attorneyStreetAddress || '',
          attorneyCity: chart?.DOCTOR_INFO?.attorneyCity || '',
          attorneyState: chart?.DOCTOR_INFO?.attorneyState || '',
          attorneyZIP: chart?.DOCTOR_INFO?.attorneyZIP || '',
          attorneyCountry: chart?.DOCTOR_INFO?.attorneyCountry || '',
          returning: chart?.DOCTOR_INFO?.returning || false,
        }}
        // validationSchema={OtherContactsSchema}
        onSubmit={(values, { setSubmitting }) => {
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          submitContactInformation(newChartData);
          setOpen(true);
        }}
        validationSchema={Yup.object().shape({
          doctor: Yup.string()
            .trim()
            .min(
              5,
              ({min}) => `Doctor Name should be at least ${min} characters`,
            )
            .max(
              50,
              ({max}) => `Doctor Name should be shorter than ${max} characters`,
            ),
          drPhone: Yup.string()
            .trim()
            .matches(phoneRegExp, 'Phone number invalid'),
          drStreetAddress: Yup.string()
            .trim()
            .min(
              2,
              ({min}) => ` Street Address should be at least ${min} characters`,
            )
            .max(
              50,
              ({max}) =>
                ` Street Address should be shorter than ${max} characters`,
            ),
          drCity: Yup.string()
            .trim()
            .min(2, ({min}) => `City name should be at least ${min} characters`)
            .max(
              50,
              ({max}) => `City name should be shorter than ${max} characters`,
            )
            .matches(lettersAndSpaces, 'Field cannot contain numbers'),
          drState: Yup.string()
            .trim()
            .min(
              2,
              ({min}) =>
                `Please use your state's two letter postal designation`,
            )
            .max(
              2,
              ({max}) =>
                `Please use your state's two letter postal designation`,
            )
            .matches(lettersAndSpaces, 'State cannot contain numbers'),
          drZIP: Yup.string()
            .trim()
            .matches(zipRegExp, 'Zip code is not valid'),
          hospital: Yup.string()
            .trim()
            .min(
              1,
              ({min}) => `Hospital Name should be at least ${min} characters`,
            )
            .max(
              60,
              ({max}) =>
                `Hospital Name should be shorter than ${max} characters`,
            ),
          hospitalStreetAddress: Yup.string()
            .trim()
            .min(
              2,
              ({min}) => ` Street Address should be at least ${min} characters`,
            )
            .max(
              50,
              ({max}) =>
                ` Street Address should be shorter than ${max} characters`,
            ),
          hospitalCity: Yup.string()
            .trim()
            .min(2, ({min}) => `City name should be at least ${min} characters`)
            .max(
              50,
              ({max}) => `City name should be shorter than ${max} characters`,
            )
            .matches(lettersAndSpaces, 'Field cannot contain numbers'),
          hospitalState: Yup.string()
            .trim()
            .min(
              2,
              ({min}) =>
                `Please use your state's two letter postal designation`,
            )
            .max(
              2,
              ({max}) =>
                `Please use your state's two letter postal designation`,
            )
            .matches(lettersAndSpaces, 'Field cannot contain numbers'),
          hospitalZIP: Yup.string()
            .trim()
            .matches(zipRegExp, 'Zip code is not valid'),
          attorney: Yup.string()
            .trim()
            .min(
              5,
              ({min}) => `Attorney Name should be at least ${min} characters`,
            )
            .max(
              60,
              ({max}) =>
                `Attorney Name should be shorter than ${max} characters`,
            ),
          attorneyPhone: Yup.string()
            .trim()
            .matches(phoneRegExp, 'Phone number is not valid'),
          attorneyStreetAddress: Yup.string()
            .trim()
            .min(
              2,
              ({min}) => ` Street Address should be at least ${min} characters`,
            )
            .max(
              50,
              ({max}) =>
                ` Street Address should be shorter than ${max} characters`,
            ),
          attorneyCity: Yup.string()
            .trim()
            .min(2, ({min}) => `City name should be at least ${min} characters`)
            .max(
              50,
              ({max}) => `City name should be shorter than ${max} characters`,
            )
            .matches(lettersAndSpaces, 'Field cannot contain numbers'),
          attorneyState: Yup.string()
            .trim()
            .trim()
            .min(
              2,
              ({min}) =>
                `Please use your state's two letter postal designation`,
            )
            .max(
              2,
              ({max}) =>
                `Please use your state's two letter postal designation`,
            )
            .matches(lettersAndSpaces, 'Field cannot contain numbers'),
          attorneyZIP: Yup.string()
            .trim()
            .matches(zipRegExp, 'Zip code is not valid'),
        })}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
            <Typography
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 700,
                margin: '29px 0 29px 0',
                width: 'fit-content',
              }}
            >
              Doctor, Hospital, & Attorney
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 500,
                margin: '29px 0 31px 0',
                width: 'fit-content',
              }}
            >
              Please enter the contact information below for your doctor,
              hospital, and attorney.
            </Typography>
            <Stack
              direction={isSlim ? 'column' : 'row'}
              style={{ display: 'flex', justifyContent: 'space-evenly' }}
            >
              <Stack direction="column" className="">
                <FormikTextInput
                  label="Doctor’s Full Name"
                  name="doctor"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Doctor’s Phone"
                  name="drPhone"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Doctor’s Office Address"
                  name="drStreetAddress"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="City"
                  name="drCity"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="State"
                  name="drState"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="ZIP Code"
                  name="drZIP"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Country"
                  name="drCountry"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Preferred Hospital Name"
                  name="hospital"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Preferred Hospital Address"
                  name="hospitalStreetAddress"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="City"
                  name="hospitalCity"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="State"
                  name="hospitalState"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="ZIP Code"
                  name="hospitalZIP"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Country"
                  name="hospitalCountry"
                  type="text"
                  handleChange={handleChange}
                />
              </Stack>
              <Stack direction="column" sx={{}}>
                <FormikTextInput
                  label="Attorney's Name"
                  name="attorney"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Attorney's Phone"
                  name="attorneyPhone"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Attorney's Street Address"
                  name="attorneyStreetAddress"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="City"
                  name="attorneyCity"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="State"
                  name="attorneyState"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="ZIP Code"
                  name="attorneyZIP"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Country"
                  name="attorneyCountry"
                  type="text"
                  handleChange={handleChange}
                />
                <div>
                  <button
                    type="submit"
                    className="ml-12 mb-12 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue{' '}
                    <ChevronRightIcon sx={{ fontSize: '32px' }} />
                  </button>
                </div>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};
