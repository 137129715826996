import React, { useRef, useState, useEffect } from "react";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { submitSignaturesInformation } from "../../../../../data/mutations/NewPatient/submitSignaturesInformation";
import { uploadToCloudStorage } from "./uploadToCloudStorage";
import { Typography, Stack, useMediaQuery } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SignatureBox } from "./SignatureBox";

export const AOBSignatures = () => {
  const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const chart = newPatientChartData.use();

  const [formHasChanges, setFormHasChanges] = useState(false);

  const [AOBSigModalIsOpen, setAOBSigModalIsOpen] = useState(false);
  const [AOBSignatureDataURI, setAOBSignatureDataURI] = useState(false);
  const [AOBSignatureExists, setAOBSignatureExists] = useState(false);

  const [AOBOtherSigModalIsOpen, setAOBOtherSigModalIsOpen] = useState(false);
  const [AOBOtherSignatureDataURI, setAOBOtherSignatureDataURI] =
    useState(false);
  const [AOBOtherSignatureExists, setAOBOtherSignatureExists] = useState(false);

  const [token, setToken] = useState(false);
  const [upToDate, setUpToDate] = useState(true);
  // const paramsObj = useParams()
  // const queryClient = useQueryClient()

  const handleAOBSignature = async (e) => {
    const blob = e.target.files[0];
    const file = new File([blob], "AOB_Patient", {
      type: blob.type,
    });
    const payload = {
      uid: patientId,
      folder: "signatures",
      file: file,
      token: token,
    };
    const tempURI = await uploadToCloudStorage(payload);
    setAOBSignatureExists(true);
    setAOBSignatureDataURI(
      `https://storage.googleapis.com/web-console-mideoid.appspot.com/user/${patientId}/signatures/AOB_FOR_CARE_Patient`
    );
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: patientId,
          DOB: chart?.DOB,
          exceptions: chart?.exceptions || "",
          firstName: chart?.firstName || "",
          lastName: chart?.lastName || "",
          middleInitial: chart?.middleInitial || "",
          returning: chart?.returning || true,
          AOBSignatureDataURI: chart?.AOBSignatureDataURI || "",
          AOBOtherSignatureDataURI: chart?.AOBOtherSignatureDataURI || "",
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          formRef.current.values.AOBSignatureDataURI = AOBSignatureDataURI;
          formRef.current.values.AOBOtherSignatureDataURI =
            AOBOtherSignatureDataURI;
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          submitSignaturesInformation(values);
          navigate("/signup/consentCareSignatures");
          // setUpToDate(false)
          // editChart(values)
        }}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form className="flex flex-col mb-6">
            <Typography
              variant="h4"
              sx={{
                color: "#1B405A",
                fontSize: "32px",
                fontFamily: "Helvetica Neue",
                fontWeight: 700,
                margin: "29px 0 0 0",
                width: "fit-content",
              }}
            >
              Assignment Of Benefits (AOB)
            </Typography>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#1B405A",
                  fontSize: "28px",
                  textAlign: "center",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                  margin: "29px auto 0 auto",
                  width: "fit-content",
                }}
              >
                Statement to Permit Payment of Insurance/Medicare Benefits to
                Provider, Physicians and Patient
              </Typography>
              <p>
                I request payment of authorized Insurance/Medicare benefits to
                me or on my behalf, for any service(s) furnished to me by The
                MIDEO Health, including physician services. I authorize any
                holder of medical and other information about me to be released
                to my insurance company/Medicare and its agents for any
                information needed to determine these benefits or benefits for
                related services. I understand I am responsible and will pay The
                MIDEO Health for any health insurance deductibles,
                co-insurance(s)/co-pays, or services not covered by my
                insurance/Medicare program as defined by the Social Security
                Act.
              </p>
              <Typography
                variant="h4"
                sx={{
                  color: "#1B405A",
                  fontSize: "28px",
                  textAlign: "center",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                  margin: "29px auto 31px auto",
                  width: "fit-content",
                }}
              >
                Assignment of Insurance/Medicare Benefits
              </Typography>
              <p>
                I hereby assign to, and authorize that payment be made directly
                to MIDEO Health for all benefits otherwise payable to me under
                the terms of my insurance policies (including Major Medical
                policies), by reason of the service(s) described in statements
                rendered by MIDEO Health. MIDEO Health shall refund any payments
                in excess of its full regular charge(s) to the person(s)
                entitled to receive the same benefits. I understand that I am
                financially responsible for all charges not covered, or unpaid
                by my insurance within a reasonable time.
              </p>
              <button
                type="button"
                onClick={(e) => setAOBSigModalIsOpen(true)}
                className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Capture Signature of Beneficiary
              </button>
              <button
                type="button"
                onClick={(e) => setAOBOtherSigModalIsOpen(true)}
                className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Capture Signature of Other
              </button>
              {/* Upload Signature
                      <input
                          type="file"
                          onChange={async (e) => {
                              const file = e.target.files[0];
                              // const dataURI = await toBase64(file);
                              // handleSignatureUploadHIPAA(dataURI);
                          }}
                      /> */}
              <div style={{ margin: "0 auto", width: "fit-content" }}>
                <button
                  type="submit"
                  disabled={
                    !AOBOtherSignatureExists || !AOBOtherSignatureExists
                  }
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: "32px" }} />
                </button>
              </div>
            </Stack>
          </Form>
        )}
      </Formik>

      <SignatureBox
        fileName={"ASSIGNMENT_OF_BENEFITS_BeneficiarySignature"}
        isOpen={AOBSigModalIsOpen}
        setIsOpen={setAOBSigModalIsOpen}
        patientId={patientId}
        setSignatureExists={setAOBSignatureExists}
        setSignatureUri={setAOBSignatureDataURI}
        setFormHasChanges={setFormHasChanges}
        contentLabel={"AOB Signature Modal"}
      />

      <SignatureBox
        fileName={"ASSIGNMENT_OF_BENEFITS_OtherSignature"}
        isOpen={AOBOtherSigModalIsOpen}
        setIsOpen={setAOBOtherSigModalIsOpen}
        patientId={patientId}
        setSignatureExists={setAOBOtherSignatureExists}
        setSignatureUri={setAOBOtherSignatureDataURI}
        setFormHasChanges={setFormHasChanges}
        contentLabel={"AOB Other Signature Modal"}
      />
    </div>
  );
};
