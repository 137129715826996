import React, { useState } from "react";
import { loginUser } from "../../../data/mutations/Auth/loginUser";
import { createUser } from "../../../data/mutations/Auth/createUser";
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderMenu } from "../../Main/HeaderMenu";
import { Footer } from "../../Main/Footer";
import { currentUser } from "../../../data/localState/currentUser";
import {
  Box,
  Button,
  Container,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import { useEffect } from "react";
import { getUser } from "../../../data/mutations/Auth/getUser";
import { updateUser } from "../../../data/mutations/Auth/updateUser";
import { resetPassword } from "../../../data/mutations/Auth/resetPassword";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`login-tabpanel-${index}`}
      aria-labelledby={`login-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, w: "100%" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `login-tab-${index}`,
    "aria-controls": `login-tabpanel-${index}`,
  };
}

const LoginSignUp = (props) => {
  const { type, email, setEmail, password, setPassword, handleSubmit } = props;

  const [confirmPassword, setConfirmPassword] = useState();

  return (
    <Paper sx={{ p: 5 }}>
      <Typography>{type}</Typography>
      <div>
        <label style={{ display: "block" }} htmlFor="email">
          Email:{" "}
        </label>
        <input
          type="text"
          placeholder="Email"
          aria-label="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {type !== "Reset Password" && (
        <div>
          <label
            className="mt-4"
            style={{ display: "block" }}
            htmlFor="password"
          >
            Password:{" "}
          </label>
          <input
            type="password"
            placeholder="Password"
            aria-label="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      )}
      {type === "Sign Up" && (
        <div>
          <label
            className="mt-4"
            style={{ display: "block" }}
            htmlFor="password"
          >
            Confirm Password:{" "}
          </label>
          <input
            type="password"
            placeholder="Confirm Password"
            aria-label="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      )}
      <button
        type="submit"
        onClick={handleSubmit}
        disabled={
          type === "Sign Up"
            ? password !== confirmPassword
            : type === "Reset Password"
            ? email === ""
            : password === ""
        }
        className="mt-2 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {type}
      </button>
    </Paper>
  );
};

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const user = currentUser.use();
  const [signedIn, setSignedIn] = React.useState(false);
  const [userData, setUserData] = useState();

  const [tabValue, setTabValue] = useState(0);

  const handleLogin = async (e) => {
    const payload = {};
    payload.email = email;
    payload.password = password;
    await loginUser(payload);
    //setSignedIn(true);
    //navigate("signup/basicInfo");
  };

  const handleCreate = async (e) => {
    const payload = {};
    payload.email = email;
    payload.password = password;
    await createUser(payload);
    //setSignedIn(true)
    //navigate("signup/basicInfo");
  };

  const handleResetPassword = async () => {
    const payload = {};
    payload.email = email;
    await resetPassword(payload);
    alert(
      "An email has been sent to your email address with a link to reset your password."
    );
  };

  const isLogin = React.useMemo(() => {
    return location.pathname.includes("login");
  }, [location]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user?.emailVerified) {
      navigate("signup/basicInfo");
    } else {
      navigate("signup/basicInfo");
    }
  }, [user]);

  const handleResend = async () => {
    const localUser = await getUser(user.uid);
    if (localUser) {
      let difftime = 10000000;
      if (localUser.lastVerificationEmailSent) {
        difftime =
          Date.now() / 1000 - localUser.lastVerificationEmailSent.seconds;
      }
      if (difftime < 60) {
        console.log("Email too recent. Not resending");
      } else {
        sendEmailVerification(user);
        await updateUser({
          uid: user.uid,
          lastVerificationEmailSent: new Date(),
        });
      }
    } else {
      sendEmailVerification(user);
      await updateUser({
        uid: user.uid,
        lastVerificationEmailSent: new Date(),
      });
    }
  };

  const handleRefresh = async () => {
    const payload = {};
    payload.email = email;
    payload.password = password;
    await loginUser(payload);
    //navigate("signup/basicInfo")
  };

  return (
    <div>
      <HeaderMenu />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        {user === null && (
          <Box sx={{ width: "50%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TabPanel value={tabValue} index={0}>
                <LoginSignUp
                  type={"Login"}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  handleSubmit={handleLogin}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <LoginSignUp
                  type={"Sign Up"}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  handleSubmit={handleCreate}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <LoginSignUp
                  type={"Reset Password"}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  handleSubmit={handleResetPassword}
                />
              </TabPanel>
            </Box>
            <Box sx={{ borderTop: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="login tabs"
                centered
              >
                <Tab label="Login" {...a11yProps(0)} />
                <Tab label="Sign Up" {...a11yProps(1)} />
                <Tab label="Reset Password" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Box>
        )}
        {user?.emailVerified && (
          <Typography>
            Something odd happened. Logout and log back in
          </Typography>
        )}
        {user
          ? !user.emailVerified && (
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Verify Your Email</Typography>
                <Typography variant="body2">
                  A verification email has been sent to your email address.
                  Please verify your email before proceeding.
                </Typography>
                <Typography variant="body2" sx={{ mt: 5, mb: 3 }}>
                  Did not get a verification email? Check your spam or request
                  another.
                </Typography>
                <Button
                  onClick={() => handleResend()}
                  className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Resend Verification Email
                </Button>
                <Typography variant="body2" sx={{ mt: 5, mb: 3 }}>
                  Already verified your email? Click below to proceed to the
                  form.
                </Typography>
                <Button
                  onClick={() => handleRefresh()}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Go to Form
                </Button>
              </Box>
            )
          : null}
      </Container>
      <Footer />
    </div>
  );
};
