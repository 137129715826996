export const castAsBoolean = (value) => {
  if (value === true) {
    return true;
  }
  if (value === false) {
    return false;
  }
  if (value) {
    if (value.toLowerCase() === "true") {
      return true;
    }
    if (value.toLowerCase() === "false") {
      return false;
    }
  }
  return false;
};
