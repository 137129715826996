import React from 'react'

export const PowerOfAttorney = (props) => {
    return (
        <div className='flex flex-col mt-6' >
            <div>
                <p className='text-center'>Power Of Attorney:</p>
            </div>
            <div>
                {props.powerOfAttorneyURI !== '' ?
                    <a key={props.powerOfAttorneyURI} className="h-auto w-48 float-left underline text-link" href={props.powerOfAttorneyURI} alt="insurance card front" target="_blank" rel="noopener noreferrer" >Power of Attorney</a>
                    :
                    null
                }
                <input className='' onChange={props.handlePowerOfAttorney} id="powerOfAttorney" name="powerOfAttorney" type="file" />
            </div>
        </div >
    )
}
