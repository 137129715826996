import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const setConsultRequested = async (payload) => {
  const chart = payload;
  const uid = chart.PATIENT_INFO_1.uid;

  const CONSULTATION_REQUESTED = {
    requestReceived: true,
  };

  const crDocRef = doc(db, "users", uid, "Form", "CONSULTATION_REQUESTED");
  await setDoc(crDocRef, CONSULTATION_REQUESTED);
};
