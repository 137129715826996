import React, { useRef, useState, useEffect } from 'react';
import { newPatientId } from '../../../../../data/localState/newPatientId';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { submitSignaturesInformation } from '../../../../../data/mutations/NewPatient/submitSignaturesInformation';
import { Typography, Stack, useMediaQuery } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { currentUser } from '../../../../../data/localState/currentUser';
import { getPatientChart } from '../../../../../data/mutations/NewPatient/getPatientChart';
import { SignatureOnFile } from './SignatureOnFile';
import { SignatureBox } from './SignatureBox';
export const ConsentForCareSignatures = () => {
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [formHasChanges, setFormHasChanges] = useState(false);

  const [consentSigModalIsOpen, setConsentSigModalIsOpen] = useState(false);
  const [consentSignatureDataURI, setConsentSignatureDataURI] = useState(false);
  const [consentSignatureExists, setConsentSignatureExists] = useState(false);


  const [token, setToken] = useState(false);
  const [upToDate, setUpToDate] = useState(true);
  
  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);
  

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId)
      setChart( oldChart.chart )
    })();
  }, [patientId]);

  useEffect(() => { 
    if (chart?.CONSENT_FOR_CARE?.signature1DataURI) {
      setConsentSignatureDataURI(chart.CONSENT_FOR_CARE.signature1DataURI)
      setConsentSignatureExists(true)
    }
  }, [chart])

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: chart?.PATIENT_INFO_1?.uid,
          returning: chart?.CONSENT_FOR_CARE?.returning || true,
          consentSignatureDataURI: chart?.CONSENT_FOR_CARE?.consentSignatureDataURI || '',
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          formRef.current.values.consentSignatureDataURI = consentSignatureDataURI;
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          submitSignaturesInformation(newChartData);
          navigate('/signup/photo');
          // setUpToDate(false)
          // editChart(values)
        }}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form className="flex flex-col mb-6">
            <Typography
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 700,
                margin: '29px 0 32px 0',
                width: 'fit-content',
              }}
            >
              Consent For Care Signature:
            </Typography>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>
                You have the right, as a patient, to be informed about your
                condition and the recommended surgical, medical or diagnostic
                procedure to be used so that you may make the decision whether
                or not to undergo any suggested treatment or procedure after
                knowing the risks and hazards involved. At this point in your
                care, no specific treatment plan has been recommended. This
                consent form is simply an effort to obtain your permission to
                perform the evaluation necessary to identify the appropriate
                advice, treatment and/or procedure for any identified
                condition(s). This consent provides us with your permission to
                perform reasonable and necessary medical examinations, testing
                and treatment. By signing below, you are indicating that (1) you
                intend that this consent is continuing in nature even after a
                specific diagnosis has been made and treatment recommended; and
                (2) you consent to treatment at this office or any other
                satellite office under common ownership. The consent will remain
                fully effective until it is revoked in writing. You have the
                right at any time to discontinue services. You have the right to
                discuss the treatment plan with your physician about the
                purpose, potential risks, and benefits of any test ordered for
                you. If you have any concerns regarding any test or treatment
                recommended by your health care provider, we encourage you to
                ask questions. I voluntarily request a physician, and/or
                mid-level provider (Nurse Practitioner, Physician Assistant, or
                Clinical Nurse Specialist), and other health care providers or
                the designees as deemed necessary, to perform reasonable and
                necessary medical examination, or testing and treatment for the
                reason or condition which has brought me to seek care at this
                practice. I understand that if additional testing, invasive or
                interventional procedures are recommended, I will be asked to
                read and sign additional consent forms prior to the test(s) or
                procedure(s).
              </p>
              <button
                type="button"
                onClick={(e) => setConsentSigModalIsOpen(true)}
                className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Capture Consent Signature
              </button>
              {consentSignatureExists &&
                <SignatureOnFile uri={consentSignatureDataURI} />
              }
              <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <button
                  type="submit"
                  disabled={!consentSignatureExists}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: '32px' }} />
                </button>
              </div>
            </Stack>
          </Form>
        )}
      </Formik>

      <SignatureBox
        fileName={"CONSENT_FOR_CARE_Patient"}
        isOpen={consentSigModalIsOpen}
        setIsOpen={setConsentSigModalIsOpen}
        patientId={patientId}
        setSignatureExists={setConsentSignatureExists}
        setSignatureUri={setConsentSignatureDataURI}
        setFormHasChanges={setFormHasChanges}
        contentLabel={"Consent for Care Signature Modal"}
      />
    </div>
  );
};
