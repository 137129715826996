import { createDiscountCodeApi, getDiscountCodeApi, getDomainValidApi, useDiscountCodeApi } from "./api"

//const createDiscountCode

export const getDiscountCodeAPI = async (uid, emailAddress, product, resend) => {
  const emailSplit = emailAddress.toLowerCase().split("@")

  // console.log("Email Domain is", emailSplit[1])

  let resp;
  let domain;
  if (emailSplit.length === 2) {
    const domain = emailSplit[1];
    const validResponse = await getDomainValidApi(domain)
    if (validResponse.active) {
      // console.log("Valid domain")
      const discountResponse = await getDiscountCodeApi(uid);
      // console.log(discountResponse)
      if (discountResponse.discountCode && !discountResponse.used && discountResponse.product===product) {
        // console.log("Code exists and is not used")
        return discountResponse;
      } else if (discountResponse === 404) {
        // console.log("Code does not exist")
        const newCodeResponse = await createDiscountCodeApi(uid, domain, product)
        if (newCodeResponse.discountCode) {
          return newCodeResponse.discountCode
        }
      } else if (discountResponse.discountCode && !discountResponse.used && discountResponse.product!==product) { 
        // console.log("Code exists for another product")
        const newCodeResponse = await createDiscountCodeApi(uid, domain, product)
        if (newCodeResponse.discountCode) {
          return newCodeResponse.discountCode
        }
      } else if (resend) { 
        // console.log("Code regeneration requested")
        const newCodeResponse = await createDiscountCodeApi(uid, domain, product)
        if (newCodeResponse.discountCode) {
          return newCodeResponse.discountCode
        }
      } else {
        console.error("Failed to retrieve code")
        return false
      }
    }
  } else {
    console.error("Failed to retrieve code")
    return false
  }
  return false
}

export const useCodeAPI = async (uid) => {
  return await useDiscountCodeApi(uid);
}