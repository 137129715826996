import React, { useRef, useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextInput,
} from '../../../../Features/Forms';
import { newPatientId } from '../../../../../data/localState/newPatientId';
import { useNavigate } from 'react-router-dom';
import { submitSelectedTreatmentInformation } from '../../../../../data/mutations/NewPatient/submitSelectedTreatmentInformation';
import { unlockFile } from './unlockFile';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from '../../../../../data/localState/currentUser';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Typography,
  Backdrop,
  Stack,
  useMediaQuery,
  Button,
  Box,
  Fade,
  Modal,
} from '@mui/material';
import { submitAttestationInformation } from '../../../../../data/mutations/NewPatient/submitAttestationInformation';
import { updateFilterOptions } from '../../../../../data/mutations/NewPatient/updateFilter';

const generateVideoUri = (videoName) => {
  const baseUri = `https://storage.googleapis.com/mideoid-console-public/videos/treatmentOptions/`;
  const uri = baseUri + encodeURIComponent(videoName);
  return uri;
};

export const TreatmentOptions = () => {
  const patientIdUsed = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [toggleFacilitator, setToggleFacilitator] = useState(null);

  const [productCost, setProductCost] = useState(0);
  const [safeToSubmit, setSafeToSubmit] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [toggleSelection, setToggleSelection] = useState(null);
  const [educationalVideoUri, setEducationalVideoUri] = useState(
    'https://storage.googleapis.com/mideoid-console-public/videos/instructional/MIDEO-App%20Patient%20Education.mp4'
  );
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleInfoVideoOpen = (code) => {
    setUri(treatmentOptions.filter((index) => index.code === code)[0].videoUri);
    setDescription(
      treatmentOptions.filter((index) => index.code === code)[0].title
    );
    setCode(treatmentOptions.filter((index) => index.code === code)[0].code);
    setOpen(true);
  };
  const isSlim = useMediaQuery('(max-width:1040px)');

  const [uri, setUri] = useState();
  const [code, setCode] = useState();
  const [description, setDescription] = useState();
  const treatmentOptions = [
    {
      sortOrder: 1,
      videoUri: generateVideoUri(
        '1 - The Full Code. All Aggressive Life Saving Treatments.mp4'
      ),
      title: 'Full Code - All Treatment & No Limitations in Care',
      code: 'fullCode',
      facilitatorRequired: false,
    },
    {
      sortOrder: 2,
      videoUri: generateVideoUri(
        '2 - The Full Code with Trial of BIPAP, then Intubation.mp4'
      ),
      title: 'Full Code with trial of BiPAP then intubation',
      code: 'fullCodeBipapInt',
      facilitatorRequired: true,
    },
    {
      sortOrder: 3,
      videoUri: generateVideoUri(
        '3 - The Full Code with Trial of BIPAP Only, No Intubation.mp4'
      ),
      title: 'Full Code with Trial of BiPAP and no intubation',
      code: 'fullCodeBipap',
      facilitatorRequired: true,
    },
    {
      sortOrder: 4,
      videoUri: generateVideoUri(
        '4 - The Full Code with ACLS for 15 Mins. All Aggressive Life Saving Treatments.mp4'
      ),
      title: 'Full Code and Trial of ACLS for 15 minutes',
      code: 'fullCodeACLS15',
    },
    {
      sortOrder: 5,
      videoUri: generateVideoUri(
        '5 - Full Code with Trial of ACLS x 15 mins with BIPAP then Intubation.mp4'
      ),
      title:
        'Full Code and Trial of ACLS for 15 minutes with BiPAP then intubation',
      code: 'fullCodeACLS15BipapInt',
      facilitatorRequired: true,
    },
    {
      sortOrder: 6,
      videoUri: generateVideoUri(
        '6 - Full Code with Trial of ACLS x 15 mins with BIPAP only. No intubation.mp4'
      ),
      title:
        'Full Code and Trial of ACLS for 15 minutes with BiPAP and no intubation',
      code: 'fullCodeACLS15Bipap',
      facilitatorRequired: true,
    },
    {
      sortOrder: 7,
      videoUri: generateVideoUri(
        '7 - Full and Aggressive Treatment up until Cardiac Arrest with BIPAP and then Intubation.mp4'
      ),
      title:
        'Full and Aggressive treatment until cardiac arrest with BiPAP then intubation',
      code: 'aggressiveBipapInt',
      facilitatorRequired: true,
    },
    {
      sortOrder: 8,
      videoUri: generateVideoUri(
        '8 - Full and Aggressive Treatment up until Cardiac Arrest with BIPAP Only. No Intubation.mp4'
      ),
      title:
        'Full and Aggressive treatment until cardiac arrest with BiPAP and no intubation',
      code: 'aggressiveBipap',
      facilitatorRequired: true,
    },
    {
      sortOrder: 9,
      videoUri: generateVideoUri('9 - Clarifications in Treatment. DNR.mp4'),
      title: 'Clarification in Treatment DNR',
      code: 'clarifications',
      facilitatorRequired: true,
    },
    {
      sortOrder: 10,
      videoUri: generateVideoUri(
        '10 - End-Of-Life Do Not Resuscitate & Allow Natural Death Order.mp4'
      ),
      title: 'When At End of Life (EOL)',
      code: 'eol',
      facilitatorRequired: true,
    },
  ];

  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);
  

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId)
      setChart( oldChart.chart )
    })();
  }, [patientId]);


  useEffect(() => {
      if (chart?.FACILITATOR_INFO?.facilitator === (true || 'true')) {
        setToggleFacilitator(true);
      } else if (chart?.FACILITATOR_INFO?.facilitator === 'false') {
        setToggleFacilitator(false);
      } else {
        setToggleFacilitator(null);
      }

    if (chart?.SELECTED_TREATMENT_OPTION?.code) {
      setToggleSelection(chart?.SELECTED_TREATMENT_OPTION?.code);
      setCode(chart?.SELECTED_TREATMENT_OPTION?.code);
      setDescription(
        treatmentOptions.filter((index) => index.code === chart?.SELECTED_TREATMENT_OPTION?.code)[0].title
      );
    }
  }, [chart]);

  return (
    <div className="flex flex-col items-center">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 840,
              bgcolor: '#CADCE1',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              id="video-modal-title"
              variant="h6"
              component="h2"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                textAlign: 'center',
                fontFamily: 'Helvetica Neue',
                fontWeight: 500,
              }}
            >
              {description}
            </Typography>
            <Box style={{ height: '320px', width: '500px', marginTop: '32px' }}>
              <video controls>
                <source
                  key={uri}
                  src={uri}
                  type="video/mp4"
                  alt="Learn More About MIDEO Video"
                />
                we have a problem
              </video>
              <Button
                onClick={handleClose}
                sx={{ display: 'flex', margin: '16px auto' }}
              >
                Continue <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {chart ? <Formik
        innerRef={formRef}
        enableReinitialize={false}
        initialValues={{
          uid: chart?.PATIENT_INFO_1?.uid || '',
          firstName: chart?.PATIENT_INFO_1?.firstName || '',
          code: chart?.SELECTED_TREATMENT_OPTION?.code || '',
          description: chart?.SELECTED_TREATMENT_OPTION?.description || '',
          attested: chart?.ATTESTATION?.attested || '',
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.description = description
          values.attested = true;
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          updateFilterOptions({ code: formRef.current.values.code })
          //investigate this and emulate for EducationalVideo if need be
          submitSelectedTreatmentInformation(values);
          submitAttestationInformation(values);
          navigate('/signup/recordingInstructions');
        }}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
            <Typography
              id="video-educational-title"
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 700,
                margin: '29px 0 29px 0',
                width: 'fit-content',
              }}
            >
              Treatment Options
            </Typography>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '16px',
                }}
              >
                <Typography
                  style={{
                    margin: '8px 8px',
                    color: '#1B405A',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  Click a treament option below to learn more. When you have
                  selected your preferred treatment choice, attest to your
                  selection to continue:
                </Typography>
                {toggleFacilitator ? (
                  treatmentOptions.map((index) => (
                    <Button
                      name="code"
                      key={index.sortOrder}
                      value={index.code}
                      // disabled={!safeToSubmit}
                      onClick={(e) => {
                        handleChange(e);
                        setToggleSelection(index.code);
                        handleInfoVideoOpen(index.code);
                      }}
                      variant="contained"
                      sx={{
                        width: '34vw',
                        height: '100px',
                        padding: 0,
                        margin: '0 auto 0 auto',
                        borderRadius: '4px 4px 4px 4px',
                        textTransform: 'none',
                        color:
                          toggleSelection === index.code
                            ? '#FFFFFF'
                            : '#00000099',
                        fontSize: '16px',
                        backgroundColor:
                          toggleSelection === index.code
                            ? '#4B8596'
                            : '#FFFFFF',
                      }}
                    >
                      {index.title}
                    </Button>
                  ))
                ) : (
                  <Button
                    name="code"
                    value={treatmentOptions[0].code}
                    // disabled={!safeToSubmit}
                    onClick={(e) => {
                      handleChange(e);
                      setToggleSelection(treatmentOptions[0].code);
                      handleInfoVideoOpen(treatmentOptions[0].code);
                    }}
                    variant="contained"
                    sx={{
                      width: '34vw',
                      height: '100px',
                      padding: 2,
                      margin: '0 auto 0 auto',
                      borderRadius: '4px 4px 4px 4px',
                      textTransform: 'none',
                      color:
                        toggleSelection === treatmentOptions[0].code
                          ? '#FFFFFF'
                          : '#00000099',
                      fontSize: '16px',
                      backgroundColor:
                        toggleSelection === treatmentOptions[0].code
                          ? '#4B8596'
                          : '#FFFFFF',
                    }}
                  >
                    {treatmentOptions[0].title}
                  </Button>
                )}
              </Stack>
              <Typography
                variant="h4"
                sx={{
                  color: '#720D0F',
                  fontSize: '24px',
                  TypographyAlign: 'center',
                  fontFamily: 'Helvetica Neue',
                  fontWeight: 500,
                  margin: '32px auto 0px auto',
                  width: 'fit-content',
                }}
              >
                Attestation
              </Typography>
              <Typography
                sx={{
                  color: '#1B405A',
                  fontSize: '18px',
                  margin: '8px 0px',
                }}
              >
                I attest to the treatment option:
              </Typography>
              <Typography
                sx={{
                  color: '#1B405A',
                  fontSize: '18px',
                  margin: '8px 0px',
                  fontWeight: 700,
                  textAlign: 'center',
                }}
              >
                {description}
              </Typography>
              <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <button
                  type="submit"
                  disabled={!description}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Yes, I Do Attest{' '}
                  <ChevronRightIcon sx={{ fontSize: '32px' }} />
                </button>
              </div>
            </Stack>
          </Form>
        )}
      </Formik> : null}
    </div>
  );
};
