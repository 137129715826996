export const createDiscountCode = (apiUri, uid, domain, product) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };
  
    const uri = encodeURI(`${apiUri}/discountCodes?uid=${uid}&domain=${domain}&product=${product}`);
    
    return fetch(uri, requestOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          return response.status
        }
      })
      .catch(error => console.log('error', error));
  }