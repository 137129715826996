/**
 *
 * @param {string} patientUid - Patient ID
 * @param {string} signatureType - Type of signature (witness or witness2)
 * @param {string} ccName - Patient name (carbon copy recipient name)
 * @param {string} ccEmail - Patien email (carbon copy recipient email)
 * @param {string} signerName - Witness name
 * @param {string} signerEmail - Witness email
 * @param {string} telePrompterScript - Teleprompter script from the patient
 */
export const sendEnvelope = (
  patientUid,
  signatureType,
  ccName,
  ccEmail,
  signerName,
  signerEmail,
  telePrompterScript
) => {
  const baseURL = {
    production: "https://us-central1-web-console-mideoid.cloudfunctions.net",
    development: "https://us-central1-dev-mideo.cloudfunctions.net",
  };
  const apiUri = `${
    process.env.REACT_APP_ENVIRONMENT
      ? baseURL[process.env.REACT_APP_ENVIRONMENT]
      : baseURL.production
  }/docusignApi`;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    patientUid: patientUid,
    signatureType: signatureType,
    ccName: ccName,
    ccEmail: ccEmail,
    signerName: signerName,
    signerEmail: signerEmail,
    script: telePrompterScript,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const uri = encodeURI(`${apiUri}/envelope`);
  fetch(uri, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};
