import { useField } from "formik";
import { useEffect } from "react";

export const FormikSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select
        {...field}
        style={{ borderRadius: '4px', margin: '7px 0', width: '270px', height: '56px', border: "1px solid #0000003B", }}
        children={props.children}
        name={props.name}
        onChange={(e) => {
          props.handleChange(e);
        }}
        disabled={props.disabled}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
