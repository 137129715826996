import { db, storagePublic } from "../../../../../data/constants/firebase";
import { unlockFile } from './unlockFile';
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import QRCode from "qrcode";
import { saveLivingWillQRCode } from "./saveLivingWillQRcode";
import { savePOLSTQRCode } from "./savePOLSTQRCode";

export const uploadToPublicCloudStorage = async (payload) => {
  const { folder, file, uid, token } = payload;
  const metadata = {
    contentType: file.type,
    "Cache-Control": "private",
  };

  const storageRef = ref(storagePublic,`${uid}/${folder}/${file.name}`)
  
  // const OTHER_DOCS_REF = db
  //   .collection("users")
  //   .doc(uid)
  //   .collection("Chart")
  //   .doc("OTHER_DOCUMENTS");

  // const uploadTask = storageRef.put(file, metadata);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise(function (resolve, reject) {
    uploadTask.on('state_changed', (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.warn("Upload is paused");
            break;
          case 'running':
            console.warn("Upload is running");
            break;
          default:
            return;
        }
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            console.error("INSUFFICIENT PERMISSION");
            alert("Current User does not have permission to upload files.");
            reject();
            break;
          case "storage/canceled":
            console.error("User canceled the upload");
            reject();
            break;
          case "storage/unknown":
            console.error(
              "An error occurred. Received the following response from the server:",
              error.serverResponse
            );
            reject();
            break;
          default:
            reject();
        }
      },
      async () => {
        // Handle successful uploads on complete
        if (file.name === "livingWill") {
          // generate a QRCode image and save the image to Firestore
          getDownloadURL(uploadTask.snapshot.ref)
          .then( async(downloadURL) => {
            return QRCode.toDataURL(downloadURL);
          })
          .then((res) => {
            let payload = {};
            payload.dataUrl = res;
            payload.patientId = uid;
            saveLivingWillQRCode(payload);
          })
          .catch((err) => console.error(err));

          // OTHER_DOCS_REF.set({ livingWillExists: true }, { merge: true }).catch(
          //   (err) => console.error(err)
          // );
        }

        if (file.name === "POLST") {
          getDownloadURL(uploadTask.snapshot.ref)
          .then( async(downloadURL) => {
            return QRCode.toDataURL(downloadURL);
          })
          .then((res) => {
            let payload = {};
            payload.dataUrl = res;
            payload.patientId = uid;
            savePOLSTQRCode(payload);
          })
          .catch((err) => console.error(err));

          // OTHER_DOCS_REF.set({ POLSTExists: true }, { merge: true }).catch(
          //   (err) => console.error(err)
          // );
        }
        getDownloadURL(uploadTask.snapshot.ref).then( async(downloadURL) => {
          resolve(downloadURL)
        });

      }
    );
  });
};
