export const consultReason = ((chart) => {
    let pronoun = 'They'
    let possessive = 'Their'
    let hasPlans = 'in addition to'
    let donor = ''
    const DOB = Date.parse(chart.PATIENT_INFO_1.DOB);
    const ageDifMs = Date.now() - DOB;
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970)


    if (chart.PATIENT_INFO_1.gender === 'male') {
        pronoun = 'He'
        possessive = 'his'
    }

    if (chart.PATIENT_INFO_1.gender === 'female') {
        pronoun = 'She'
        possessive = 'her'
    }

    if (chart.PATIENT_INFO_1.gender === 'non-binary') {
        pronoun = 'They'
        possessive = 'their'
    }

    if (chart.LIVING_WILL.POLSTURI === null && chart.LIVING_WILL.livingWillURI === null) {
        hasPlans = ('rather than')
    }

    if (chart.LIVING_WILL.organDonor === false && chart.PATIENT_INFO_1.gender === 'male') {
        donor = `as well as preserve his rights not to be an organ donor at this time`
    }

    if (chart.LIVING_WILL.organDonor === false && chart.PATIENT_INFO_1.gender === 'female') {
        donor = `as well as preserve her rights not to be an organ donor at this time`
    }

    if (chart.LIVING_WILL.organDonor === false && chart.PATIENT_INFO_1.gender === 'non-binary') {
        donor = `as well as preserve their rights not to be an organ donor at this time`
    }



    const placeHolderText =
        `   Medical evaluation, risk stratification, Goals of Care Conversation with ACP education & with possible creation of MIDEO® Video Advance Directive.
    
    Brief history:  ${age} y/o ${chart.PATIENT_INFO_1.gender} adult with no significant PMH & is well and healthy.
    
    The patient has no complaints of any fever chills cough sputum production shortness of breath travel history exposure to a coronavirus potential patient. There's no complaints of any chest pain palpitations seizure syncope headache difficulty speech swallowing stroke like symptoms abdominal pain dysuria hematuria melena or hematochezia Arthralgia rash lymph adenopathy or night sweats.  The Remaining ROS is OTW listed below.
    
    ${pronoun} is physically and mentally fit in good spirits presents here today for evaluation, medical risk stratification, education and the possibility of creating a MIDEO® ${hasPlans} creating a LW or POLST. As such ${possessive} prognosis is very good.  As such he wants to better document ${possessive} choices to assure realistic care (for reversible & treatable conditions)${donor}. 
    `

    return placeHolderText
})
