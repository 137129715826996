import { useField } from "formik";

export const FormikTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <>
      {/* <label htmlFor={props.id || props.name}>{label}</label> */}
      <input
        {...field}
        required={(props.required !== null && props.required) || false}
        placeholder={label}
        name={props.name}
        type={props.type}
        style={{
          borderRadius: '4px', margin: '7px 0', width: '270px', height: '56px', border: "1px solid #0000003B",
        }}
        onChange={(e) => {
          props.handleChange(e);
          props.handleChangeSideEffects && props.handleChangeSideEffects();
        }}
        disabled={props.disabled}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
