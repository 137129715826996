import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const createCardInfoField = (chart) => {
  const uid = chart.PATIENT_INFO_1.uid;
  const DOB = chart.PATIENT_INFO_1.DOB;
  const patientName = `${chart.PATIENT_INFO_1.firstName} ${chart?.PATIENT_INFO_1?.middleInitial} ${chart.PATIENT_INFO_1.lastName}`;
  const code =
    chart.SELECTED_TREATMENT_OPTION &&
    chart.SELECTED_TREATMENT_OPTION.description
      ? chart.SELECTED_TREATMENT_OPTION.description
      : "";
  const livingWill =
    chart.LIVING_WILL.livingWillURI === (null || "") ? "No" : "Yes";
  const agent = chart.HEALTHCARE_AGENT_INFO.agent;
  const agentPhone = chart.HEALTHCARE_AGENT_INFO.cellPhone;
  const polst = chart.LIVING_WILL.polstURI === (null || "") ? "No" : "Yes";

  const docRef = doc(db, "users", uid);
  setDoc(
    docRef,
    {
      displayName: patientName,
      cardInfo: {
        DOB: DOB || null,
        patientName: patientName || null,
        code: code || null,
        livingWill: livingWill || null,
        agent: agent || null,
        agentPhone: agentPhone || null,
        polst: polst || null,
      },
    },
    { merge: true }
  );
};
