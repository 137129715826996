import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitSignaturesInformation = async (values) => {
  const basePatientId = values.uid;

  const HIPAA_AUTH = {
    DOB: values.DOB,
    exceptions: values.exceptions,
    firstName: values.firstName,
    lastName: values.lastName,
    middleName: values.middleInitial,
    returning: values.returning,
    signature1DataURI: values.HIPAASignatureDataURI,
  };

  const CONSENT_FOR_CARE = {
    signature1DataURI: values.consentSignatureDataURI,
  };

  console.log(values);

  const WITNESS_SIGNATURE = {
    signature1DataURI: values.witnessSignatureDataURI || "",
    signature2DataURI: values.witness2SignatureDataURI || "",
    witnessName: values.witnessName || "",
    witness2Name: values.witness2Name || "",
    witnessEmail: values.witnessEmail || "",
    witness2Email: values.witness2Email || "",
  };

  console.log(WITNESS_SIGNATURE);

  const PATIENT_SIGNATURE = {
    signature1DataURI: values.patientSignatureDataURI,
  };

  if (Object.values(HIPAA_AUTH).every((el) => el !== undefined)) {
    const hipaaDocRef = doc(db, "users", basePatientId, "Form", "HIPAA_AUTH");
    await setDoc(hipaaDocRef, HIPAA_AUTH);
  } else {
    console.error("HIPAA Document not saved");
  }

  if (Object.values(WITNESS_SIGNATURE).every((el) => el !== undefined)) {
    const witnessDocRef = doc(
      db,
      "users",
      basePatientId,
      "Form",
      "WITNESS_SIGNATURE"
    );
    await setDoc(witnessDocRef, WITNESS_SIGNATURE, { merge: true });
  } else {
    console.error("Witness Signature not saved");
  }

  if (Object.values(PATIENT_SIGNATURE).every((el) => el !== undefined)) {
    const patientDocRef = doc(
      db,
      "users",
      basePatientId,
      "Form",
      "PATIENT_SIGNATURE"
    );
    await setDoc(patientDocRef, PATIENT_SIGNATURE);
  } else {
    console.error("Patient Signature not saved");
  }
  if (Object.values(CONSENT_FOR_CARE).every((el) => el !== undefined)) {
    const consentDocRef = doc(
      db,
      "users",
      basePatientId,
      "Form",
      "CONSENT_FOR_CARE"
    );
    await setDoc(consentDocRef, CONSENT_FOR_CARE);
  } else {
    console.error("Consent for Care not saved");
  }
};
