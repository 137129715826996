import React from "react";

export const Footer = () => {
    const image = require("../../assets/MIDEOLogo.png");

    return(
        <div className="flex flex-col items-center" style={{ width:"100%", height:220, backgroundColor: "#333" }}>
          <div className="justify-around ml-40 mr-40" style={{ width:"100%", display:"flex", color:"white", borderBottom: "1px solid white"}}>
            <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
              <div style={{display:"flex", color:"white", lineHeight:"80px", marginLeft:20, marginRight:20}}>
                <a style={{height:80}} href="https://mideocard.com" alt="MIDEO Card Home">Home</a>
              </div>
              <div style={{display:"flex", color:"white", lineHeight:"80px", marginLeft:20, marginRight:20}}>
                <a style={{height:80}} href="https://mideocard.com/health-plans-and-medical-providers/" alt="For Health Plans">For Health Plans</a>
              </div>
              <div style={{display:"flex", color:"white", lineHeight:"80px", marginLeft:20, marginRight:20}}>
                <a style={{height:80}} href="https://mideocard.com/faq/" alt="FAQ">FAQ</a>
              </div>
              <div style={{display:"flex", color:"white", lineHeight:"80px", marginLeft:20, marginRight:20}}>
                <a style={{height:80}} href="https://mideocard.com/contact/" alt="Contact">Contact</a>
              </div>
            </div>
          </div>
          <div className="flex flex-col italic text-center text-white border-white mt-4">
            <p>Copyright © 2020 | MIDEOcard.com. | All Rights Reserved</p>
            <p>Information listed on this website pertaining to MIDEO® is protected by United States Patent # 11,164 129 B2 Approval Date: November 2, 2021.</p>
          </div>
        </div>
    )
}