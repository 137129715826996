import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";
import { sections } from "../../constants/sections";

export const getNavigation = async (uid) => {
  const navDocRef = doc(db, "users", uid, "Form", "NAVIGATION");
  const navDoc = await getDoc(navDocRef);
  if (navDoc.exists) {
    if (navDoc.data() && navDoc.data().sections) {
      return navDoc.data();
    } else {
      return { ...navDoc.data(), sections: sections };
    }
  } else {
    return { sections: sections };
  }
};
