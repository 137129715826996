import React, { useRef, useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextInput,
} from '../../../../Features/Forms';
import { newPatientId } from '../../../../../data/localState/newPatientId';
import { useNavigate } from 'react-router-dom';
import { submitInsuranceInformation } from '../../../../../data/mutations/NewPatient/submitInsuranceInformation';
import { uploadToCloudStorage } from './uploadToCloudStorage';
import { unlockFile } from './unlockFile';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import {
  Typography,
  TypographyField,
  Stack,
  useMediaQuery,
  Button,
  Box,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const RecordingInstructions = () => {
  const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const chart = newPatientChartData.use();
  const [toggleFacilitator, setToggleFacilitator] = useState(null);

  const [productCost, setProductCost] = useState(0);
  const [safeToSubmit, setSafeToSubmit] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [educationalVideoUri, setEducationalVideoUri] = useState(
    'https://storage.googleapis.com/mideoid-console-public/videos/instructional/MIDEO-App%20Patient%20Education.mp4'
  );

  function handleScheduling() {
    window.open(
      'https://consumer.scheduling.athena.io/?locationId=26368-1',
      '_blank'
    );
  }

  function handleChangeSideEffects() {
    if (
      chart?.siteID === formRef?.current?.values?.siteIDVerify &&
      chart?.facilitatorID === formRef?.current?.values?.facilitatorIDVerify
    ) {
      setSafeToSubmit(true);
    } else {
      setSafeToSubmit(false);
    }
    setToggleState((prev) => !prev);
  }
  useEffect(() => {
    if (
      chart?.siteID === formRef?.current?.values?.siteIDVerify &&
      chart?.facilitatorID === formRef?.current?.values?.facilitatorIDVerify
    ) {
      setSafeToSubmit(true);
    } else {
      setSafeToSubmit(false);
    }
  }, [toggleState]);

  useEffect(() => {
    if (formRef?.current?.values) {
      if (formRef?.current?.values?.facilitator === 'true') {
        setToggleFacilitator(true);
      } else if (formRef?.current?.values?.facilitator === 'false') {
        setToggleFacilitator(false);
      } else {
        setToggleFacilitator(null);
      }
    }
  }, []);

  return (
    <div className="flex flex-col items-center">
      <Formik
        innerRef={formRef}
        enableReinitialize={false}
        initialValues={{
          uid: patientId,
          firstName: chart?.firstName || '',
          facilitator: chart?.facilitator || '',
          facilitatorID: chart?.facilitatorID || '',
          siteID: chart?.siteID || '',
          facilitatorIDVerify: chart?.facilitatorIDVerify || '',
          siteIDVerify: chart?.siteIDVerify || '',
          submitDate: chart?.submitDate || '',
          cardName: chart?.cardName || '',
          cardEmail: chart?.cardEmail || '',
          cardPhone: chart?.cardPhone || '',
          cardDetails: chart?.cardDetails || '',
          couponCode: chart?.couponCode || '',
          alreadyPaid: chart?.alreadyPaid || '',
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={(values, { setSubmitting }) => {
          navigate('/signup/reviewScript');
        }}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
            <Typography
              id="video-educational-title"
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 700,
                margin: '29px 0 29px 0',
                width: 'fit-content',
              }}
            >
              Recording Instructions
            </Typography>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  margin: '16px 8px 0px 8px',
                  color: '#1B405A',
                  textAlign: 'center',
                }}
              >
                The video below will walk you through the steps of recording
                your MIDEO:
              </Typography>
              <Box
                style={{
                  height: 'auto',
                  width: '70%',
                  marginTop: '32px',
                  marginBottom: '32px',
                }}
              >
                <video controls>
                  <source
                    key={educationalVideoUri}
                    src={
                      'https://storage.googleapis.com/mideoid-console-public/videos/treatmentOptions/Time%20to%20Record%20Your%20MIDEO.mp4'
                    }
                    type="video/mp4"
                    alt="Learn More About MIDEO Video"
                  />
                  we have a problem
                </video>
              </Box>
              <Typography
                variant="h4"
                sx={{
                  color: '#1B405A',
                  fontSize: '32px',
                  padding: '0px 16px',
                  fontFamily: 'Helvetica Neue',
                  fontWeight: 700,
                  margin: '29px 0 29px 0',
                  width: 'fit-content',
                }}
              >
                Example Video
              </Typography>
              <Typography
                sx={{
                  margin: '16px 0px',
                  padding: '0px 16px',
                  color: '#1B405A',
                  textAlign: 'center',
                }}
              >
                The video below is an example of a video recording to give you a
                feel for what the video should look:
              </Typography>
              <Box
                style={{ height: 'auto', width: '400px', marginTop: '32px' }}
              >
                <video controls>
                  <source
                    key={educationalVideoUri}
                    src={
                      'https://storage.googleapis.com/mideoid-console-public/videos/instructional/MIDEO%20Example%20Video.mp4'
                    }
                    type="video/mp4"
                    alt="Learn More About MIDEO Video"
                  />
                  we have a problem
                </video>
              </Box>
              <div
                style={{ margin: '16px auto 32px auto', width: 'fit-content' }}
              >
                <button
                  type="submit"
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: '32px' }} />
                </button>
              </div>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};
