import React from "react";
import { Routes, Route } from "react-router-dom";
import { Login } from "./Layouts/Login";

export const AuthManager = () => {
  return (
    <>
      {/* <div>Hello from auth manager</div> */}
      <Routes>
        <Route path="*" element={<Login />}>
          {/* <Route index element={<Home />} />
          <Route path="teams" element={<Teams />}>
            <Route path=":teamId" element={<Team />} />
            <Route path="new" element={<NewTeamForm />} />
            <Route index element={<LeagueStandings />} />
          </Route> */}
        </Route>
      </Routes>
    </>
  );
};
