import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const updateNavigation = async (values) => {
  const basePatientId = values.uid;

  const NAVIGATION = {
    sections: values.sections,
  };

  const navDocRef = doc(db, "users", basePatientId, "Form", "NAVIGATION");
  await setDoc(navDocRef, NAVIGATION, { merge: true });
};
