import { db } from "../../constants/firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";


export const setInitialPatientRole = (payload) => {
  const chart = payload;
  const uid = chart.PATIENT_INFO_1.uid;
  
  const docRef = doc(db, "users", uid);
  setDoc(docRef,
    {
      roles: { isPatient: true } ,
    },
    { merge: true }
  ).catch(function (error) {
    console.error(error);
  });
}