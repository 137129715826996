import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitInsuranceInformation = async (values) => {
  const HEALTH_INSURANCE_INFO = {
    insured: values.insured,
    notInsured: values.notInsured,
    insuranceCo: values.insuranceCo,
    secondaryInsuranceCo: values.secondaryInsuranceCo || "",
    spouseName: values.spouseName,
    spouseDOB: values.spouseDOB,
    street: values.street,
    city: values.city,
    state: values.state,
    zip: values.zip,
    country: values.country,
    healthInsuranceCardFrontURI: values.healthInsuranceCardFrontURI || "",
    healthInsuranceCardBackURI: values.healthInsuranceCardBackURI || "",
    returning: true,
  };

  const healthInsuranceDocRef = doc(
    db,
    "users",
    values.uid,
    "Form",
    "HEALTH_INSURANCE_INFO"
  );
  await setDoc(healthInsuranceDocRef, HEALTH_INSURANCE_INFO);
};
