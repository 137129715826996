import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitOtherDocumentsInformation = async (values) => {
  const basePatientId = values.uid;

  const OTHER_DOCUMENTS = {
    POLSTURI: values.POLSTURI,
    livingWillURI: values.livingWillURI,
    powerOfAttorney: values.powerOfAttorneyURI,
  };

  const otherDocRef = doc(
    db,
    "users",
    basePatientId,
    "Form",
    "OTHER_DOCUMENTS"
  );
  await setDoc(otherDocRef, OTHER_DOCUMENTS);
};
