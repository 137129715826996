import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";
import { submitChartDefaults } from "./submitChartDefaults";
import { hca, livingWill, polst, religiousBeliefs } from "./PlaceHolders";

export const setInitialChartInfo = async (form) => {
  const uid = form.PATIENT_INFO_1.uid;

  for (const [key, value] of Object.entries(form)) {
    console.log("key", key);
    let chartObj = {};
    let docElements = form[key];
    for (const [field, value] of Object.entries(docElements)) {
      chartObj[field] = value;
    }
    const docRef = doc(db, "users", uid, "Chart", key);
    setDoc(docRef, chartObj, { merge: true });
  }
  const userDocRef = doc(db, "users", uid);
  updateDoc(userDocRef, {
    "filterOptions.ACPE": form.FACILITATOR_INFO.facilitatorID,
    "filterOptions.siteID": form.FACILITATOR_INFO.siteID,
    "filterOptions.createdOn": Date.now(),
    isArchived: false,
  });

  const chartsRef = doc(db, "users", uid, "datesOfService", "charts");
  setDoc(chartsRef, { chartsArray: [] }, { merge: true });

  await submitChartDefaults(form);

  const GOALS_DEFAULTS = {
    hca: hca(form),
    livingWill: livingWill(form),
    polst: polst(form),
    beliefs: religiousBeliefs(form),
  };

  const goalsRef = doc(db, "users", uid, "Chart", "GOALS");
  setDoc(goalsRef, GOALS_DEFAULTS, { merge: true });
  return;
};
