import React, { useRef, useEffect, useState } from 'react';
import { newPatientId } from '../../../../../data/localState/newPatientId';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from '../../../../../data/localState/currentUser';
import { FormikTextInput } from '../../../../Features/Forms';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { submitContactInformation } from '../../../../../data/mutations/NewPatient/submitContactInformation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Typography,
  TextField,
  Stack,
  useMediaQuery,
  Button,
} from '@mui/material';
import {lettersAndSpaces, phoneRegExp, zipRegExp} from '../../../../../constants';
import * as Yup from 'yup';

export const OtherContacts = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const isSlim = useMediaQuery('(max-width:1040px)');
  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);
  

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId)
      setChart( oldChart.chart )
    })();
  }, [patientId]);


   return (
    <div className="flex flex-col items-center">
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: patientId,
          agent: chart?.HEALTHCARE_AGENT_INFO?.agent || '',
          agentAddress: chart?.HEALTHCARE_AGENT_INFO?.street || '',
          agentCity: chart?.HEALTHCARE_AGENT_INFO?.city || '',
          agentState: chart?.HEALTHCARE_AGENT_INFO?.state || '',
          agentZIP: chart?.HEALTHCARE_AGENT_INFO?.ZIP || '',
          agentCountry: chart?.HEALTHCARE_AGENT_INFO?.country || '',
          agentHomePhone: chart?.HEALTHCARE_AGENT_INFO?.homePhone || '',
          agentCellPhone: chart?.HEALTHCARE_AGENT_INFO?.cellPhone || '',
          agentEmail: chart?.HEALTHCARE_AGENT_INFO?.email || '',
          agentToPatient: chart?.HEALTHCARE_AGENT_INFO?.agentToPatient || '',
          patientToAgent: chart?.HEALTHCARE_AGENT_INFO?.patientToAgent || '',
          returning: chart?.HEALTHCARE_AGENT_INFO?.returning || false,
        }}
        // validationSchema={OtherContactsSchema}
        onSubmit={(values, { setSubmitting }) => {
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          submitContactInformation(values);
          navigate('/signup/doctorInfo');
        }}
        validationSchema={Yup.object().shape({
          agent: Yup.string().required('You must name an agent'),
          agentAddress: Yup.string()
            .required(' Street Address is required')
            .min(
              2,
              ({min}) => ` Street Address should be at least ${min} characters`,
            )
            .max(
              50,
              ({max}) =>
                ` Street Address should be shorter than ${max} characters`,
            ),
            agentCity: Yup.string()
            .required('City name is required')
            .min(2, ({min}) => `City name should be at least ${min} characters`)
            .max(
              50,
              ({max}) => `City name should be shorter than ${max} characters`,
            )
            .matches(lettersAndSpaces, 'Field cannot contain numbers'),
          agentState: Yup.string()
            .required('State is required')
            .min(
              2,
              ({min}) =>
                `Please use your state's two letter postal designation`,
            )
            .max(
              2,
              ({max}) =>
                `Please use your state's two letter postal designation`,
            )
            .matches(lettersAndSpaces, 'State cannot contain numbers'),
            agentZIP: Yup.string()
            .required('Postal Code is required')
            .matches(zipRegExp, 'Zip code is not valid'),
            agentCountry: Yup.string()
            .required('Country selection is required')
            .matches(lettersAndSpaces, 'Field cannot contain numbers'),
            agentHomePhone: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid',
          ),
          agentCellPhone: Yup.string()
            .required('Cell phone number required for emergency contact')
            .matches(phoneRegExp, 'Phone number is not valid'),
            agentEmail: Yup.string().email('Email Invalid'),
          patientToAgent: Yup.string().required('This field is required'),
          agentToPatient: Yup.string().required('This field is required'),
        })}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
            <Typography
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 700,
                margin: '29px 0 29px 0',
                width: 'fit-content',
              }}
            >
              Healthcare Agent Information
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 500,
                margin: '29px 0 31px 0',
                width: 'fit-content',
              }}
            >
              This is the person you are appointing to make decisions when you
              are not able to do so.
            </Typography>
            <Stack
              direction={isSlim ? 'column' : 'row'}
              style={{ display: 'flex', justifyContent: 'space-evenly' }}
            >
              <Stack direction="column" className="">
                <FormikTextInput
                  label="Agent Name"
                  required={true}
                  name="agent"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Street Address"
                  name="agentAddress"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="City"
                  name="agentCity"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="State"
                  name="agentState"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="ZIP Code"
                  required={true}
                  name="agentZIP"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Country"
                  required={true}
                  name="agentCountry"
                  type="text"
                  handleChange={handleChange}
                />
              </Stack>
              <Stack direction="column" sx={{}}>
                <FormikTextInput
                  label="Home Phone (optional)"
                  name="agentHomePhone"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Cell Phone"
                  name="agentCellPhone"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Contact Email Address"
                  name="agentEmail"
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Agent's Relationship to Patient"
                  name="agentToPatient"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <FormikTextInput
                  label="Patient's Relationship to Agent"
                  name="patientToAgent"
                  required={true}
                  type="text"
                  handleChange={handleChange}
                />
                <div>
                  <button
                    type="submit"
                    className="ml-12 mb-12 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue{' '}
                    <ChevronRightIcon sx={{ fontSize: '32px' }} />
                  </button>
                </div>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};
