import { db } from "../../constants/firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";


export const setInitialCardStatus = (payload) => {
  const chart = payload;
  const uid = chart.PATIENT_INFO_1.uid;
  
  const docRef = doc(db, "users", uid);
  setDoc(docRef,
    {
      cardStatus: {
        cardPrinted: {
          description: "Confirmed audio matches script.",
          isComplete: false,
          sortOrder: 5,
        },
        codeCorrect: {
          description: "Confirmed QR code links to correct video.",
          isComplete: false,
          sortOrder: 6,
        },
        imageCorrect: {
          description: "Patient photo verified.",
          isComplete: false,
          sortOrder: 2,
        },
        releaseApproved: {
          description: "Card released.",
          isComplete: false,
          sortOrder: 7,
        },
        textVerified: {
          description: "Card text verified.",
          isComplete: false,
          sortOrder: 1,
        },
        videoProcessed: {
          description: "Video processed.",
          isComplete: false,
          sortOrder: 4,
        },
        videoVerified: {
          description: "Patient video verified.",
          isComplete: false,
          sortOrder: 3,
        },
      },
    },
    { merge: true }
  ).catch(function (error) {
    console.error(error);
  });
}