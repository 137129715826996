import React from "react";
import { Routes, Route } from "react-router-dom";
import { app, db } from "../data/constants/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { currentUser, setCurrentUser } from "../data/localState/currentUser";
import { AuthManager } from "./Auth/AuthManager";
import { Home } from "./Main/Home";
import { getPatientChart } from "../data/mutations/NewPatient/getPatientChart";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { updateUser } from "../data/mutations/Auth/updateUser";

const stripePromise = loadStripe(
  "pk_test_51HgY8BA1hg8BPrh2z1aov1JOhVjPMgmmO7RaoXoHaVrD5n5VdxtN3Sla7Wpvz7AzUVeTCU5RgatGqsAasMHxu31700Sj2lYQv7"
);

export const App = () => {
  const auth = getAuth(app);
  const user = currentUser.use();

  const initUser = async (user) => {
    const userData = await getPatientChart(user.uid);
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setCurrentUser(user);
      initUser(user);
      updateUser({
        uid: user.uid,
        email: user.email,
        lastLoginTime: new Date(),
      });
    } else {
      setCurrentUser(null);
    }
  });
  return (
    <Elements stripe={stripePromise}>
      {user === null || !user.emailVerified ? (
        <Routes>
          <Route path="*" element={<AuthManager />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Home />} />
        </Routes>
      )}
    </Elements>
  );
};

export default App;
