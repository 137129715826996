import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitScriptAttestmentInformation = async (values) => {
  const basePatientId = values.uid;

  const SCRIPT_REVIEW = {
    scriptAttested: values.scriptAttested,
    telePrompterScript: values.telePrompterScript,
    attestedCode: values.attestedCode,
    attestedSelfReporting: values.attestedSelfReporting,
    attestedHasLivingWill: values.attestedHasLivingWill,
    editedScript: values.editedScript || false,
  };

  const srDocRef = doc(db, "users", basePatientId, "Form", "SCRIPT_REVIEW");
  await setDoc(srDocRef, SCRIPT_REVIEW);
};
