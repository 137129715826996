import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitAttestationInformation = async (values) => {
  const basePatientId = values.uid;

  const ATTESTATION = {
    attested: values.attested,
  };

  const attDocRef = doc(db, "users", basePatientId, "Form", "ATTESTATION");
  await setDoc(attDocRef, ATTESTATION);
};
