import React, { useRef, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  FormikTextInput,
} from '../../../../Features/Forms';
import { useNavigate } from 'react-router-dom';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../../data/localState/newPatientChartData';
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from '../../../../../data/localState/currentUser';
import {
  IconButton,
  Typography,
  Stack,
  Button,
  Box,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { submitSampleCardInformation } from '../../../../../data/mutations/NewPatient/submitSampleCardInformation';
import { submitFacilitatedTreatmentOptions } from '../../../../../data/mutations/NewPatient/submitFacilitatedTreatmentOptionsInformation';

import { updateFilterOptions } from '../../../../../data/mutations/NewPatient/updateFilter';
import { createCardInfoField } from '../../../../../data/mutations/NewPatient/createCardInfoField';
import { setInitialNeedsReview } from '../../../../../data/mutations/NewPatient/setIntialNeedsReview';
import { sendCardCreatedNotification } from '../../../../../data/mutations/NewPatient/sendCardCreatedNotification';
import { setInitialPatientRole } from '../../../../../data/mutations/NewPatient/setIntialPatientRole';
import { setInitialCardStatus } from '../../../../../data/mutations/NewPatient/setInitialCardStatus';
import { setConsultRequested } from '../../../../../data/mutations/NewPatient/setConsultRequested';
import { submitGoalsInformation } from '../../../../../data/mutations/NewPatient/submitGoalsInformation';
import { submitChartDefaults } from '../../../../../data/mutations/NewPatient/submitChartDefaults';


export const EducationalVideo = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [toggleFacilitator, setToggleFacilitator] = useState(null);

  const [viewSiteId, setViewSiteId] = useState(true)
  const [viewFacilitatorId, setViewFacilitatorId] = useState(true)

  
  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [safeToSubmit, setSafeToSubmit] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [educationalVideoUri, setEducationalVideoUri] = useState(
    'https://storage.googleapis.com/mideoid-console-public/videos/instructional/MIDEO-App%20Patient%20Education.mp4'
  );

  function handleScheduling() {
    updateFilterOptions({
      applicationStatus: "closed",
      isArchived: false,
      ACPE: chart?.FACILITATOR_INFO?.facilitatorID,
      siteID: chart?.FACILITATOR_INFO?.siteID
    })
    setConsultRequested(chart);
    createCardInfoField(chart);
    setInitialNeedsReview(chart);
    setInitialPatientRole(chart);
    setInitialCardStatus(chart);    
    submitGoalsInformation(chart);
    submitFacilitatedTreatmentOptions(formRef.current.values);
    submitSampleCardInformation(formRef.current.values);
    submitChartDefaults(chart)
    sendCardCreatedNotification(chart);
    window.open(
      'https://consumer.scheduling.athena.io/?locationId=26368-1',
      '_blank'
    );
    navigate('/');
  }


  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);
  

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId)
      setChart( oldChart.chart )
    })();
  }, [patientId]);



  useEffect(() => {
    if (chart?.FACILITATOR_INFO) {
      if (chart?.FACILITATOR_INFO?.facilitator === (true || 'true')) {
        setToggleFacilitator(true);
      } else if (chart?.FACILITATOR_INFO?.facilitator === 'false') {
        setToggleFacilitator(false);
      } else {
        setToggleFacilitator(null);
      }
    }
    if (
      chart?.FACILITATOR_INFO?.siteID === formRef?.current?.values?.siteIDVerify &&
      chart?.FACILITATOR_INFO?.facilitatorID === formRef?.current?.values?.facilitatorIDVerify
    ) {
      setSafeToSubmit(true);
    } else {
      setSafeToSubmit(false);
    }
    setToggleState(!toggleState);
  }, [chart]);


  function handleChangeSideEffects() {
    if (
      chart?.FACILITATOR_INFO?.siteID === formRef?.current?.values?.siteIDVerify &&
      chart?.FACILITATOR_INFO?.facilitatorID === formRef?.current?.values?.facilitatorIDVerify
    ) {
      setSafeToSubmit(true);
    } else {
      setSafeToSubmit(false);
    }
    setToggleState(!toggleState);
  }

  useEffect(() => {
    if (
      chart?.FACILITATOR_INFO?.siteID === formRef?.current?.values?.siteIDVerify &&
      chart?.FACILITATOR_INFO?.facilitatorID === formRef?.current?.values?.facilitatorIDVerify
    ) {
      setSafeToSubmit(true);
    } else {
      setSafeToSubmit(false);
    }
  }, [toggleState]);



  return (
    <div className="flex flex-col items-center">
      {chart ? <Formik
        innerRef={formRef}
        enableReinitialize={false}
        initialValues={{
          uid: chart?.PATIENT_INFO_1?.uid || '',
          firstName: chart?.PATIENT_INFO_1?.firstName || '',
          facilitator: chart?.FACILITATOR_INFO?.facilitator || '',
          facilitatorID: chart?.FACILITATOR_INFO?.facilitatorID || '',
          siteID: chart?.FACILITATOR_INFO?.siteID || '',
          facilitatorIDVerify: null,
          siteIDVerify: null,
          submitDate: chart?.submitDate || '',
          returning: false,
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={(values, { setSubmitting }) => {
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          submitFacilitatedTreatmentOptions(values);
          submitSampleCardInformation(values);
          navigate('/signup/treatmentOptions');
        }}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
            <Typography
              id="video-educational-title"
              variant="h4"
              sx={{
                color: '#1B405A',
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 700,
                margin: '29px 0 29px 0',
                width: 'fit-content',
              }}
            >
              Treatment Options
            </Typography>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {toggleFacilitator ? (
                <Stack direction="column" spacing={2}>
                  <Typography
                    style={{
                      margin: '8px 8px',
                      color: '#1B405A',
                      textAlign: 'center',
                      fontWeight: 700,
                    }}
                  >
                    Your facilitator will now discuss your treatment options:
                  </Typography>
                  <Typography
                    sx={{
                      color: '#1B405A',
                      fontSize: '18px',
                      textAlign: 'center',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: 500,
                    }}
                  >
                    All aggressive treatments to save your life.
                  </Typography>
                  <Typography
                    sx={{
                      color: '#1B405A',
                      fontSize: '18px',
                      textAlign: 'center',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: 500,
                    }}
                  >
                    Discuss treatments that may not be beneficial.
                  </Typography>
                  <Typography
                    sx={{
                      color: '#1B405A',
                      fontSize: '18px',
                      textAlign: 'center',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: 500,
                    }}
                  >
                    Witnessed cardiac arrest treatment.
                  </Typography>
                  <Typography
                    sx={{
                      color: '#1B405A',
                      fontSize: '18px',
                      textAlign: 'center',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: 500,
                    }}
                  >
                    Unwitnessed cardiac arrest treatment.
                  </Typography>
                </Stack>
              ) : null}
              <Box style={{ height: 'auto',marginTop: '32px'}}>
                <video controls>
                  <source
                    key={educationalVideoUri}
                    src={educationalVideoUri}
                    type="video/mp4"
                    alt="Learn More About MIDEO Video"
                  />
                  we have a problem
                </video>
              </Box>
              <Typography
                variant="h4"
                sx={{
                  color: '#1B405A',
                  fontSize: '32px',
                  fontFamily: 'Helvetica Neue',
                  fontWeight: 700,
                  margin: '29px 0 29px 0',
                  width: 'fit-content',
                }}
              >
                Patient Educational Video
              </Typography>
              <Typography
                sx={{
                  margin: '16px 8px',
                  color: '#1B405A',
                  textAlign: 'center',
                }}
              >
                This is now an opportunity for patient education on how to
                navigate the healthcare environment and also the risks and
                benefits associated with a living will or POLST.
              </Typography>
              <Typography
                style={{
                  margin: '16px 8px 0px 8px',
                  color: '#1B405A',
                  textAlign: 'center',
                }}
              >
                This education is 24 minutes long.
              </Typography>
              {chart?.facilitator === 'true' ? null : (
                <Typography
                  style={{
                    margin: '16px 8px',
                    color: '#1B405A',
                    textAlign: 'center',
                  }}
                >
                  After viewing the video; if you desire more information on any
                  of the options presented, click the button below to submit
                  your application in its current state and schedule an
                  appointment with a certified ACPE. If you are comfortable
                  moving forward with the Full Code option, click the button to
                  Save and Continue.
                </Typography>
              )}
              <Button
                name="submitDate"
                type="button"
                value={Date().toLocaleString()}
                // disabled={!safeToSubmit}
                onClick={(e) => {
                  handleChange(e);
                  handleScheduling();
                }}
                variant="contained"
                sx={{
                  width: '28vw',
                  height: '100px',
                  padding: 0,
                  margin: '0 auto 0 auto',
                  borderRadius: '4px 4px 4px 4px',
                  TypographyTransform: 'none',
                  color: toggleFacilitator === true ? '#FFFFFF' : '#00000099',
                  fontSize: '16px',
                  backgroundColor:
                    toggleFacilitator === true ? '#4B8596' : '#FFFFFF',
                }}
              >
                Submit for Review & Schedule Consultation
              </Button>
              {toggleFacilitator ? (
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      margin: '16px 8px',
                      color: '#1B405A',
                      textAlign: 'center',
                    }}
                  >
                    If patient is approved to move forward with the process,
                    enter your ACPE number below and click "Continue".
                  </Typography>
                  <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  >
                  <FormikTextInput
                    label="Verify Facilitator ID"
                    name="facilitatorIDVerify"
                    type={viewFacilitatorId ? "password" : "text"}
                    handleChange={handleChange}
                    handleChangeSideEffects={handleChangeSideEffects}
                  />
                  <IconButton onClick={() => setViewFacilitatorId(!viewFacilitatorId)}>
                    {viewFacilitatorId ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  >
                  <FormikTextInput
                    label="Verify Site ID"
                    name="siteIDVerify"
                    type={viewSiteId ? "password" : "text"}
                    handleChange={handleChange}
                    handleChangeSideEffects={handleChangeSideEffects}
                  />
                  <IconButton onClick={() => setViewSiteId(!viewSiteId)}>
                    {viewSiteId ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </Stack>
                </Stack>
              ) : null}
              <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <button
                  type="submit"
                  disabled={toggleFacilitator ? !safeToSubmit : false}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: '32px' }} />
                </button>
              </div>
            </Stack>
          </Form>
        )}
      </Formik> : null}
    </div>
  );
};
