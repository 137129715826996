import React from 'react'

export const SignatureOnFile = (props) => {
  return (
    <div className='flex flex-col mt-6' >
      <div>
          <p className='text-center'>Signature On File:</p>
      </div>
      <div>
          <img key={props.uri} className="h-auto w-48 float-left" src={props.uri} alt="signature on file"></img>
      </div>
    </div >
  )
}