import React, { useRef, useState, useEffect } from "react";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikSelect, FormikTextInput } from "../../../../Features/Forms";
import { submitSignaturesInformation } from "../../../../../data/mutations/NewPatient/submitSignaturesInformation";
import { uploadToCloudStorage } from "./uploadToCloudStorage";
import { Typography, Stack, useMediaQuery } from "@mui/material";

import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SignatureOnFile } from "./SignatureOnFile";
import { SignatureBox } from "./SignatureBox";
export const HIPAASignatures = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  //const chart = newPatientChartData.use();

  const [formHasChanges, setFormHasChanges] = useState(false);

  const [HIPAASigModalIsOpen, setHIPAASigModalIsOpen] = useState(false);
  const [HIPAASignatureDataURI, setHIPAASignatureDataURI] = useState(false);
  const [HIPAASignatureExists, setHIPAASignatureExists] = useState(false);

  const [token, setToken] = useState(false);
  const [upToDate, setUpToDate] = useState(true);
  const isSlim = useMediaQuery("(max-width:1040px)");
  // const paramsObj = useParams()
  // const queryClient = useQueryClient()

  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const handleHIPAASignature = async (e) => {
    const blob = e.target.files[0];
    const file = new File([blob], "HIPAA_FOR_CARE_Patient", {
      type: blob.type,
    });
    const payload = {
      uid: patientId,
      folder: "signatures",
      file: file,
      token: token,
    };
    const tempURI = await uploadToCloudStorage(payload);
    setHIPAASignatureExists(true);
    setHIPAASignatureDataURI(
      `https://storage.googleapis.com/web-console-mideoid.appspot.com/user/${patientId}/signatures/HIPAA_FOR_CARE_Patient`
    );
  };
  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  useEffect(() => {
    if (chart?.HIPAA_AUTH?.signature1DataURI) {
      setHIPAASignatureDataURI(chart.HIPAA_AUTH.signature1DataURI);
      setHIPAASignatureExists(true);
    }
  }, [chart]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: patientId,
          DOB: chart?.PATIENT_INFO_1?.DOB,
          exceptions: chart?.HIPAA_AUTH?.exceptions || "",
          firstName: chart?.PATIENT_INFO_1?.firstName || "",
          lastName: chart?.PATIENT_INFO_1?.lastName || "",
          middleInitial: chart?.PATIENT_INFO_1?.middleInitial || "",
          returning: chart?.PATIENT_INFO_1?.returning || false,
          HIPAASignatureDataURI: chart?.HIPAA_AUTH?.signature1DataURI || "",
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          formRef.current.values.HIPAASignatureDataURI = HIPAASignatureDataURI;
          const newChartData = { ...previousChartData, ...values };
          setNewPatientChartData(newChartData);
          submitSignaturesInformation(values);
          navigate("/signup/directives");
          // setUpToDate(false)
          // editChart(values)
        }}
      >
        {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
          <Form className="flex flex-col ">
            <Typography
              variant="h4"
              sx={{
                color: "#1B405A",
                fontSize: "32px",
                fontFamily: "Helvetica Neue",
                fontWeight: 700,
                margin: "29px 0 29px 0",
                width: "fit-content",
              }}
            >
              HIPAA Authorization
            </Typography>
            <div className="flex flex-col items-center">
              <Stack
                direction={isSlim ? "column" : "row"}
                spacing={isSlim ? null : 1}
              >
                <Stack direction="column" spacing={2}>
                  <FormikTextInput
                    label="First: "
                    name="firstName"
                    required={true}
                    type="text"
                    // handleuichange={handleuichange}
                    handleChange={handleChange}
                    className="float-left"
                  />
                  <FormikTextInput
                    label="Middle Initial: "
                    name="middleInitial"
                    type="text"
                    // handleuichange={handleuichange}
                    handleChange={handleChange}
                    className="float-left"
                  />
                </Stack>
                <Stack direction="column" spacing={2}>
                  <FormikTextInput
                    label="Last: "
                    name="lastName"
                    required={true}
                    type="text"
                    // handleuichange={handleuichange}
                    handleChange={handleChange}
                    className="float-left"
                  />
                  <FormikTextInput
                    label="DOB: "
                    name="DOB"
                    required={true}
                    type="text"
                    // handleuichange={handleuichange}
                    handleChange={handleChange}
                    className="float-left"
                  />
                </Stack>
              </Stack>
              <p>
                1. Authorization I authorize the organization MIDEO Health and
                it’s affiliated companies, including members of its professional
                team, supporting staff, and the healthcare professionals
                enrolled in its training program for Advance Care Planning
                counseling, to use and to disclose the protected health
                information (PHI) described below to any individual healthcare
                provider or institution or others, if their intent is for others
                to learn the wishes that I expressed in my Living Will and/or my
                Advance Directive or directly related treatment or any other for
                other purposes as I may agree to either below or add in a
                separate consent form.
              </p>
              <p>
                2. Effective Period This authorization for release of
                information begins immediately upon my signing this form.
              </p>
              <p>
                3.Extent of Authorization I authorize the release of my complete
                health record (including records relating to mental healthcare,
                communicable diseases, HIV or AIDS, and treatment of alcohol or
                drug abuse) with the exceptions noted below. If you have no
                exceptions to the list, the word "None” has been pre-filled for
                you in the box for you. If you have exceptions, please write
                them in the box below:
              </p>
              <div>
                <FormikTextInput
                  label="Exceptions? "
                  name="exceptions"
                  type="text"
                  // handleuichange={handleuichange}
                  handleChange={handleChange}
                  className="float-left"
                />
              </div>
              <p>
                4. This medical information may be used by the person
                (recipient) I authorize to receive this information for medical
                treatment or consultation, billing or claims payment, or other
                purposes as I may direct.
              </p>
              <p>
                5. This authorization shall be in force and effect until I
                revoke it. This HIPAA is durable if I lose capacity and did not
                previously revoke it.
              </p>
              <p>
                6.  I understand that I have the right to revoke this
                authorization, in writing, at any time. I understand that a
                revocation is not effective to the extent that any person or
                entity has already acted in reliance on my authorization or if
                my authorization was obtained as a condition of obtaining
                insurance coverage and the insurer has a legal right to contest
                a claim.
              </p>
              <p>
                7.  I understand that my treatment, payment, enrollment or
                eligibility for benefits will not be conditioned on whether or
                not I sign this authorization.
              </p>
              <p>
                8.  I understand that information used or disclosed pursuant to
                this authorization may be disclosed by the recipient and may no
                longer be protected by federal or state law.
              </p>
              <button
                type="button"
                onClick={(e) => setHIPAASigModalIsOpen(true)}
                className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Capture HIPAA Signature
              </button>
              {HIPAASignatureExists && (
                <SignatureOnFile uri={HIPAASignatureDataURI} />
              )}
              <div style={{ margin: "0 auto", width: "fit-content" }}>
                <button
                  type="submit"
                  disabled={!HIPAASignatureExists}
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: "32px" }} />
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <SignatureBox
        fileName={"HIPPA_AUTH_primary"}
        isOpen={HIPAASigModalIsOpen}
        setIsOpen={setHIPAASigModalIsOpen}
        patientId={patientId}
        setSignatureExists={setHIPAASignatureExists}
        setSignatureUri={setHIPAASignatureDataURI}
        setFormHasChanges={setFormHasChanges}
        contentLabel={"HIPAA Signature Modal"}
      />
    </div>
  );
};
