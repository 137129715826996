
const sections = [
  {
    name: "Contact Info",
    restricted: false,
    paths: [
      "/signup/basicinfo",
      "/signup/contacts",
      "/signup/doctorInfo"
    ],
    requiresFacilitator: false
  },
  {
    name: "Learn More & Payment Info",
    restricted: false,
    paths: [
      "/signup/payment"
    ],
    requiresFacilitator: false
  },
  {
    name: "Insurance Info",
    restricted: false,
    paths: [
      "/signup/insurance"
    ],
    requiresFacilitator: true
  },
  {
    name: "ID & Forms",
    restricted: false,
    paths: [
      "/signup/IDInfo",
      "/signup/directives",
      "/signup/consentCareSignatures",
      "/signup/hipaaSignatures",
      "/signup/aobSignatures",
      "/signup/photo"
    ],
    requiresFacilitator: false
  },
  {
    name: "Your Treatment Choice",
    restricted: false,
    paths: [
      "/signup/educationalVideo",
      "/signup/treatmentOptions"
    ],
    requiresFacilitator: false
  },
  {
    name: "Recording Your Video",
    restricted: true,
    paths: [
      "/signup/recordingInstructions",
      "/signup/reviewScript",
      "/signup/video"
    ],
    requiresFacilitator: false
  },
  {
    name: "Signatures",
    restricted: true,
    paths: [
      "/signup/signatures"
    ],
    requiresFacilitator: false
  },
  {
    name: "Your Sample Card",
    restricted: true,
    paths: [
      "/signup/sampleCard"
    ],
    requiresFacilitator: false
  }
]


export { sections };