// AUTHENTICATION VIA EMAIL/PASSWORD MUST BE ENABLED IN THE FIREBASE CONSOLE BEFORE USING THIS MUTATION

import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
} from "firebase/auth";
import { app } from "../../constants/firebase";

export const resetPassword = (payload) => {
  const auth = getAuth(app);
  setPersistence(auth, browserSessionPersistence)
    .then(() => {
      return sendPasswordResetEmail(auth, payload.email).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(
          `Error sending password reset email. Generated code: ${errorCode}. Message: ${errorMessage}`
        );
        alert(
          "There was an error sending your password reset email. Please try again."
        );
      });
    })
    .catch((error) => {
      console.error(error);
    });
};
