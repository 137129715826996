import { db, storagePrivate, storageVideo } from "../../../../../data/constants/firebase";
import { unlockFile } from './unlockFile';
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const uploadToCloudStorage = (async (payload, setProgress, videoStorage) => {
    let unlockedURL = ''
    const { folder, file, uid, token } = payload
    const metadata = {
        contentType: file.type,
        'Cache-Control': 'private'
    }

    let storageRef = ref(storagePrivate,`user/${uid}/${folder}/${file.name}`)
    if (videoStorage) {
        storageRef = ref(storageVideo, `${uid}/${file.name}`)
    }
    // const storageRef = storage.ref().child('user').child(uid).child(folder).child(file.name)
    // const OTHER_DOCS_REF = db.collection('users').doc(uid).collection('Chart').doc('OTHER_DOCUMENTS')

    // Upload the file and metadata
    const uploadTask = uploadBytesResumable(storageRef, file);
    
    return new Promise(function (resolve, reject) {
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (setProgress) {
                setProgress(progress)
            }
           // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
               // console.log('Upload is paused');
                break;
            case 'running':
               // console.log('Upload is running');
                break;
            default:
                return;
            }
        }, 
        (error) => {
            switch (error.code) {
                case "storage/unauthorized":
                    console.error("INSUFFICIENT PERMISSION");
                    alert("Current User does not have permission to upload files.");
                    reject();
                    break;
                case "storage/canceled":
                    console.error("User canceled the upload");
                    reject();
                    break;
                case "storage/unknown":
                    console.error(
                    "An error occurred. Received the following response from the server:",
                    error.serverResponse
                    );
                    reject();
                    break;
                default:
                    reject();
            }
        }, 
        async () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then( async(downloadURL) => {

                unlockedURL = await unlockFile({ folder: folder, name: file.name, uid: uid, token: token })
                resolve(downloadURL)
            });
        });
    })
})