import React from 'react'

export const LivingWill = (props) => {
    return (
        <div className='flex flex-col mt-6' >
            <div>
                <p className='text-center'>Living Will:</p>
            </div>
            <div>
                {props.livingWillURI !== '' ?
                    <a key={props.livingWillURI} className="h-auto w-48 float-left underline text-link" href={props.livingWillURI} alt="living will" target="_blank" rel="noopener noreferrer" >Living Will</a>
                    :
                    null
                }
                <input className='' onChange={e => props.handleLivingWill(e)} id="livingWill" name="livingWill" type="file" />
            </div>
        </div >
    )
}
