import React from 'react'

export const POLST = (props) => {
    return (
        <div className='flex flex-col mt-6' >
            <div>
                <p className='text-center'>POLST:</p>
            </div>
            <div>
                {props.POLSTURI !== '' ?
                    <a key={props.POLSTURI} className="h-auto w-48 float-left underline text-link" href={props.POLSTURI} alt="POLST" target="_blank" rel="noopener noreferrer" >POLST</a>
                    :
                    null
                }
                <input className='' onChange={e => props.handlePOLST(e)} id="POLST" name="POLST" type="file" />
            </div>
        </div >
    )
}
