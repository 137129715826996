import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitABNInformation = async (values) => {
  let abnSig;
  if (values.insured === true) {
    abnSig = values.nonMedicareABNSignatureDataURI;
  } else if (values.medicare === true) {
    abnSig = values.medicareABNSignatureDataURI;
  }

  const ABN_INFO = {
    medicareABNChoice: values.medicareABNChoice || "",
    nonMedicareABNChoice: values.nonMedicareABNChoice || "",
    additionalInfo: values.additionalInfo || "",
    signature1DataURI: abnSig || "",
  };

  const abnDocRef = doc(db, "users", values.uid, "Form", "ABN_INFO");
  await setDoc(abnDocRef, ABN_INFO);
};
