import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";
import { generateFirestoreBasePatient } from "./generateFirestoreBasePatient";
import { getPatientChart } from "./getPatientChart";

export const submitPatientInformation = async (values) => {
  let basePatientId;

  if (values.uid === undefined) {
    basePatientId = await generateFirestoreBasePatient();
    // console.log("generated a uid for a new patient:", basePatientId);
  } else {
    // console.log("Patient already exists, patient uid = ", values.uid);
    // Check if user has been initialized.
    const oldChart = await getPatientChart(values.uid);
    if (!oldChart.user?.source) {
      await generateFirestoreBasePatient(values.uid);
    }
    basePatientId = values.uid;
  }

  const PATIENT_INFO_1 = {
    firstName: values.firstName,
    middleInitial: values.middleInitial,
    lastName: values.lastName,
    DOB: values.DOB,
    gender: values.gender,
    selfReporting: values.selfReporting,
    witnesses: values.witnesses,
    returning: true,
    uid: values.uid,
  };

  if (values.speaker) {
    PATIENT_INFO_1.speaker = values.speaker;
  }
  if (values.livingWill) {
    PATIENT_INFO_1.livingWill = values.livingWill;
  }
  if (values.POLST) {
    PATIENT_INFO_1.POLST = values.POLST;
  }

  const PATIENT_INFO_2 = {
    streetAddress: values.streetAddress,
    city: values.city,
    state: values.state,
    ZIP: values.zip || values.ZIP,
    country: values.country,
    homePhone: values.homePhone,
    cellPhone: values.cellPhone,
    email: values.email,
    returning: true,
  };

  /* const LIVING_WILL = {
    organDonor: values.organDonor,
    livingWill: values.livingWill,
    polst: values.POLST,
  };

  if (values.religion) {
    LIVING_WILL.religion = values.religion
  }

  if (values.religion) {
    LIVING_WILL.religiousInfo = values.religiousInfo
  }
 */

  // const GOALS = {
  // }

  // if (values.religion) {
  //   GOALS.religion = values.religion
  // }

  // if (values.religion) {
  //   GOALS.religiousInfo = values.religiousInfo
  // }

  //==================================================================
  const patientInfoChartRef = doc(
    db,
    "users",
    basePatientId,
    "Form",
    "PATIENT_INFO_1"
  );
  await setDoc(patientInfoChartRef, PATIENT_INFO_1);

  const patientInfoTwoChartRef = doc(
    db,
    "users",
    basePatientId,
    "Form",
    "PATIENT_INFO_2"
  );
  await setDoc(patientInfoTwoChartRef, PATIENT_INFO_2);

  /*   const LivingWillChartRef = doc(
    db,
    "users",
    basePatientId,
    "Chart",
    "LIVING_WILL"
  );
  await setDoc(LivingWillChartRef, LIVING_WILL); */

  const PatientBaseDocRef = doc(db, "users", basePatientId);

  if (PATIENT_INFO_2.email) {
    await updateDoc(PatientBaseDocRef, {
      displayName: `${values.firstName} ${values.middleInitial}. ${values.lastName}`,
      contactEmail: PATIENT_INFO_2.email,
    });
  } else {
    await updateDoc(PatientBaseDocRef, {
      displayName: `${values.firstName} ${values.middleInitial}. ${values.lastName}`,
    });
  }

  return basePatientId;
};
