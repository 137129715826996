import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitFacilitatedTreatmentOptions = async (values) => {
  const basePatientId = values.uid;

  const FACILITATED_TREATMENT_OPTIONS = {
    reviewed: true,
  };

  const ftoDocRef = doc(
    db,
    "users",
    values.uid,
    "Form",
    "FACILITATED_TREATMENT_OPTIONS"
  );
  await setDoc(ftoDocRef, FACILITATED_TREATMENT_OPTIONS);
};
