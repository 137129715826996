import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  AddressElement
} from "@stripe/react-stripe-js";
import { Alert, Button } from "@mui/material";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <label style={{width: "75%"}}>
      Card details
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
};

function BillingSection(setAddress, setName) {
  return (
    <label style={{width: "75%"}}>
      Billing Details
      <AddressElement options={{ mode: "billing" }}
        onChange={(event) => {
          if (event.complete) {
            // Extract potentially complete address
            const address = event.value.address;
          }
        }}
        />
    </label>
  );
};

export const PaymentForm = (props) => {
  const { clientSecret, setPaymentInProcess, paymentInProcess, handlePaymentSuccess } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [alert, setAlert] = useState()
  const [billingAddress, setBillingAddress] = useState()
  const [billingName, setBillingName] = useState()


  const handleClickPay = async() => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaymentInProcess(true)

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingName,
          address: billingAddress
        },
      }
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
      setAlert({ type: "error", message: result.error.message })
      setPaymentInProcess(false)
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        setAlert({ type: "success", message: "Payment accepted!" })
        handlePaymentSuccess()
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }

  }
  return (
    <>
      <BillingSection setAddress={setBillingAddress} setName={setBillingName} />
      <CardSection />
      
      <Button
        onClick={() => {
          handleClickPay();
        }}
        title="Pay"
        disabled={paymentInProcess}
      >
        Pay
      </Button>
      {alert && (
        <Alert severity={alert.type} onClose={() => setAlert()}>
          {alert.message}
        </Alert>)
      }
    </>
  );
}