import {
  collection,
  doc,
  getFirestore,
  getDoc,
  getDocs,
} from "firebase/firestore";

const db = getFirestore();

export const getPatientChart = async (patientId) => {
  const baseInfoChartRef = doc(db, "users", patientId);
  const userBase = await getDoc(baseInfoChartRef);
  const chartRef = collection(db, "users", patientId, "Form");
  const userChart = await getDocs(chartRef);
  let userCharts = {};
  userChart.forEach((doc) => {
    userCharts[doc.id] = doc.data();
  });
  return { user: userBase.data(), chart: userCharts };
};
