import { Avatar, Card, CardActionArea, CardContent, CardHeader, Container, Divider, List, ListItem, ListItemText, Typography } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const ProductCard = ({ item, setSelectedProduct, selectedProduct, selectDisabled }) => {
  return (
    <Card
      variant="outlined"
    >
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: "white" }}>{item === selectedProduct ? <CheckCircleIcon color="success" fontSize="large"></CheckCircleIcon> : null}</Avatar>}
        titleTypographyProps={{ fontSize: "2em" }}
        subheaderTypographyProps={{ fontSize: "1em" }}
        title={item.name}
        subheader={item.description}
      />
      {!selectDisabled ? (
        <CardActionArea onClick={() => setSelectedProduct(item)}>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Divider />
            <Typography style={{ fontSize: "3em", alignSelf: "center" }}>{formatter.format(item.oneTimePrice)}</Typography>
            <Typography style={{ fontSize: "0.75em", alignSelf: "center", fontWeight: "bold", marginBottom: 5 }}>One time setup payment</Typography>
            <List>
              {item.sellingPoints && item.sellingPoints.map((spItem, spIndex) => (
                <ListItem key={`${spIndex}-sp`} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemText>- {spItem}</ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider />
            <Container sx={{ display: "flex", flexDirection: "row", marginTop: 1 }}>
              <Typography style={{ fontSize: "2.75em", alignSelf: "center" }}>{formatter.format(item.subscriptionPrice)}</Typography>
              <Container sx={{ flexGrow: 1 }}>
                <Typography
                  style={{ fontSize: "0.75em", alignSelf: "center", fontWeight: "bold", marginBottom: 5, textDecorationLine: "underline" }}
                >
                  Annual Membership Maintenance
                </Typography>
                {item.subscriptionPoints && item.subscriptionPoints.map((item, index) => (
                  <ListItem key={`sp-${index}`} sx={{ paddingTop: 0, paddingLeft: 3, paddingBottom: 0 }}>
                    <ListItemText style={{ fontSize: "1em", fontStyle: "italic" }}>{item}</ListItemText>
                  </ListItem>
                ))}
              </Container>
            </Container>
          </CardContent>
        </CardActionArea>) : (
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Divider />
            <Typography style={{ fontSize: "3em", alignSelf: "center" }}>{formatter.format(item.oneTimePrice)}</Typography>
            <Typography style={{ fontSize: "0.75em", alignSelf: "center", fontWeight: "bold", marginBottom: 5 }}>One time setup payment</Typography>
            <List>
              {item.sellingPoints && item.sellingPoints.map((spItem, spIndex) => (
                <ListItem key={`${spIndex}-sp`} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemText>- {spItem}</ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider />
            <Container sx={{ display: "flex", flexDirection: "row", marginTop: 1 }}>
              <Typography style={{ fontSize: "2.75em", alignSelf: "center" }}>{formatter.format(item.subscriptionPrice)}</Typography>
              <Container sx={{ flexGrow: 1 }}>
                <Typography
                  style={{ fontSize: "0.75em", alignSelf: "center", fontWeight: "bold", marginBottom: 5, textDecorationLine: "underline" }}
                >
                  Annual Membership Maintenance
                </Typography>
                {item.subscriptionPoints && item.subscriptionPoints.map((item, index) => (
                  <ListItem key={`sp-${index}`} sx={{ paddingTop: 0, paddingLeft: 3, paddingBottom: 0 }}>
                    <ListItemText style={{ fontSize: "1em", fontStyle: "italic" }}>{item}</ListItemText>
                  </ListItem>
                ))}
              </Container>
            </Container>
          </CardContent>
          
      )}
    </Card>
  )
}