import React, { useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router';
import { FormNavBar } from './components/FormNavBar';
import { PatientInformation } from './components/PatientInformation';
import { OtherContacts } from './components/OtherContacts';
import { Directives } from './components/Directives';
import { InsuranceInfo } from './components/InsuranceInfo';
import { PatientPhoto } from './components/PatientPhoto';
import { Schedule } from './components/Schedule';
import './NewPatientFormManager.css';
import { Stack, Box, Typography, Divider } from '@mui/material';
import { HealthcarePartners } from './components/HealthcarePartners';
import { PaymentInfo } from './components/PaymentInfo';
import { ConsentForCareSignatures } from './components/ConsentForCareSignatures';
import { HIPAASignatures } from './components/HIPAASignatures';
import { AOBSignatures } from './components/AOBSignatures';
import { WebcamCapture } from './components/Webcam';
import { Signatures } from './components/Signatures';
import { IDInfo } from './components/IDCard';
import { ChatRounded } from '@mui/icons-material';
import {
  newPatientChartData,
  setNewPatientChartData,
} from '../../../../data/localState/newPatientChartData';
import { EducationalVideo } from './components/EducationalVideo';
import { TreatmentOptions } from './components/TreatmentOptions';
import { RecordingInstructions } from './components/RecordingInstructions';
import { ReviewScript } from './components/ReviewScript';
import { YourSampleCard } from './components/YourSampleCard';
import { sections } from '../../../../data/constants/sections';
import { getNavigation } from '../../../../data/mutations/NewPatient/getNavigation';
import { currentUser } from '../../../../data/localState/currentUser';

export const NewPatientFormManager = () => {
  return (
    <Stack direction="row" sx={{ backgroundColor: '#CADCE1', display: 'flex' }}>
      <Box sx={{ flex: 1, height: '100%', minWidth: '425px' }}>
        <FormNavBar />
      </Box>

      <Divider
        orientation="vertical"
        sx={{
          width: '1080px',
          position: 'absolute',
          top: 100,
          left: -690,
          color: '#4B8596',
          display: 'flex',
        }}
      />

      <Box sx={{ flex: 4, height: '80%' }}>
        <Routes>
          <Route path="basicInfo" element={<PatientInformation />} />
          <Route path="contacts" element={<OtherContacts />} />
          <Route path="doctorInfo" element={<HealthcarePartners />} />
          <Route path="payment" element={<PaymentInfo />} />
          <Route path="insurance" element={<InsuranceInfo />} />
          <Route path="directives" element={<Directives />} />
          <Route
            path="consentCareSignatures"
            element={<ConsentForCareSignatures />}
          />
          <Route path="hipaaSignatures" element={<HIPAASignatures />} />
          <Route path="IDInfo" element={<IDInfo />} />
          <Route path="aobSignatures" element={<AOBSignatures />} />
          <Route path="photo" element={<PatientPhoto />} />
          <Route path="educationalVideo" element={<EducationalVideo />} />
          <Route path="treatmentOptions" element={<TreatmentOptions />} />
          <Route
            path="recordingInstructions"
            element={<RecordingInstructions />}
          />
          <Route path="reviewScript" element={<ReviewScript />} />
          <Route path="video" element={<WebcamCapture />} />
          <Route path="signatures" element={<Signatures />} />
          <Route path="sampleCard" element={<YourSampleCard />} />
        </Routes>
      </Box>
    </Stack>
  );
};
