import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitVideoURIInformation = async (values) => {
  const basePatientId = values.uid;

  const VIDEO_URI = {
    videoDownloadURI: values.tempURI,
  };

  const vidDocRef = doc(db, "users", basePatientId, "Form", "VIDEO_URI");
  await setDoc(vidDocRef, VIDEO_URI);
};
