import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";
import { hca, livingWill, polst, religiousBeliefs } from "./PlaceHolders";

export const submitGoalsInformation = async (values) => {
  const basePatientId = values.uid;

  const GOALS = {
    hca: hca(values),
    livingWill: livingWill(values),
    polst: polst(values),
    beliefs: religiousBeliefs(values),
  };

  const goalsDocRef = doc(db, "users", basePatientId, "Form", "GOALS");
  await setDoc(goalsDocRef, { ...GOALS });
};
