import React, { useRef, useState, useEffect } from "react";
import { newPatientId } from "../../../../../data/localState/newPatientId";
// import { TakePicture } from "./TakePicture";
// import { uploadPhoto } from "./uploadPhoto";
import { useNavigate } from "react-router-dom";
import { uploadToCloudStorage } from "./uploadToCloudStorage";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../../data/constants/firebase";
import { Typography, Stack, useMediaQuery } from "@mui/material";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";

import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import { unlockFile } from "./unlockFile";
import { Formik, Field, Form } from "formik";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CardUploadView } from "./CardUploadView";
import { UploadingIndicator } from "./UploadingIndicator";
import { TakeIdPicture } from "./TakeNewIdPhoto";
export const PatientPhoto = () => {
  // const chart = newPatientChartData.use();
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [portraitURI, setPortraitURI] = useState(
    chart?.PHOTO_FOR_CARD?.portraitURI
  );
  const [token, setToken] = useState();
  const [uploading, setUploading] = useState(false);
  /* useEffect(() => {
    formRef?.current?.values?.portraitURI = portraitURI;
  }, [portraitURI]); */
  const handlePatientPhoto = async (e) => {
    setUploading(true);
    const blob = e.target.files[0];
    const file = new File([blob], "portrait", { type: blob.type });
    const payload = {
      uid: patientId,
      folder: "images",
      file: file,
      token: token,
    };
    const tempURI = await uploadToCloudStorage(payload);
    setPortraitURI(tempURI);
    updateFirestore(tempURI);
    setUploading(false);
  };

  const handlePatientTakenPhoto = async (e) => {
    setUploading(true);
    const b64toBlob = (base64) => fetch(base64).then((res) => res.blob());
    const blob = await b64toBlob(e);
    const file = new File([blob], "portrait", { type: blob.type });
    const payload = {
      uid: patientId,
      folder: "images",
      file: file,
      token: token,
    };
    const tempURI = await uploadToCloudStorage(payload);
    setPortraitURI(tempURI);
    updateFirestore(tempURI);
    setUploading(false);
  };

  const updateFirestore = async (uri) => {
    const photoDocRef = doc(db, "users", patientId, "Form", "PHOTO_FOR_CARD");
    await setDoc(photoDocRef, { portraitURI: uri });
  };

  useEffect(() => {
    const CardPhoto = (async () => {
      const src = await unlockFile({
        uid: patientId,
        folder: "images",
        name: "portrait",
      });
    })();
    setPortraitURI(chart?.PHOTO_FOR_CARD?.portraitURI);
  }, [chart]);

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  return (
    <div>
      <UploadingIndicator
        open={uploading}
        size={"10em"}
        thickness={5}
        fontSize="2em"
      />
      {chart ? (
        <Formik
          innerRef={formRef}
          enableReinitialize={false}
          initialValues={{
            uid: patientId,
            firstName: chart?.PATIENT_INFO_1?.firstName || "",
            portraitURI: chart?.PHOTO_FOR_CARD?.portraitURI || "",
          }}
          // validationSchema={PatientInfoSchema}
          onSubmit={(values, { setSubmitting }) => {
            const newChartData = { ...previousChartData, ...values };
            setNewPatientChartData(newChartData);
            //investigate this and emulate for EducationalVideo if need be
            // submitInsuranceInformation(values);
            navigate("/signup/educationalVideo");
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
            <Form>
              <Stack
                direction="column"
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "32px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 700,
                    margin: "29px 0 29px 0",
                    width: "fit-content",
                  }}
                >
                  Upload Patient Photo For Card
                </Typography>

                <Stack
                  direction="column"
                  sx={{ justifyContent: "center", alignItems: "center" }}
                >
                  <p className="text-xl mt-4 text-mideoMaroon text-center">
                    NOTE: PLEASE CENTER YOUR FACE IN PHOTO
                  </p>
                  <TakeIdPicture
                    handlePatientTakenPhoto={handlePatientTakenPhoto}
                  />
                  <CardUploadView
                    uri={portraitURI}
                    handleUpload={handlePatientPhoto}
                    handlePhotoTaken={handlePatientTakenPhoto}
                    altText={"Photo Portrait"}
                    inputId={"potraitUri"}
                    inputName={"PortraitURI"}
                    title={"Photo for Card:"}
                  />
                </Stack>
                <div style={{ margin: "0 auto", width: "fit-content" }}>
                  <button
                    type="submit"
                    disabled={portraitURI === null}
                    className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue{" "}
                    <ChevronRightIcon sx={{ fontSize: "32px" }} />
                  </button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
