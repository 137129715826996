import React, { useEffect, useState } from "react";
import {
  Link,
  useMatch,
  useResolvedPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Divider, Box, Typography } from "@mui/material";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./FormNavBar.css";
import { transparent } from "tailwindcss/colors";
import { currentUser } from "../../../../../data/localState/currentUser";
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";

import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { sections } from "../../../../../data/constants/sections";
import { getNavigation } from "../../../../../data/mutations/NewPatient/getNavigation";
import { updateNavigation } from "../../../../../data/mutations/NewPatient/updateNavigation";

export const FormNavBar = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let match;
  let resolved;
  const [chart, setChart] = useState();
  const [patientId, setPatientId] = useState(currentUser.use()?.uid);

  const patient = currentUser.use();

  const [completedSections, setCompletedSections] = useState();
  const [activeSection, setActiveSection] = useState();
  const [updatedSections, setUpdatedSections] = useState(sections);

  useEffect(() => {
    const init = async () => {
      let userSections = sections;
      const tmpSections = await getNavigation(patientId);
      if (tmpSections) {
        userSections = tmpSections.sections;
      }

      const activeSectionIndex = userSections.findIndex((item) => item.active);

      let completeSections = userSections.filter((item) => item.completed);

      setActiveSection(activeSectionIndex);
      setCompletedSections(completeSections);
      setUpdatedSections(userSections);
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      let userSections = sections;
      const tmpSections = await getNavigation(patientId);
      if (tmpSections) {
        userSections = tmpSections.sections;
      }

      const localActiveSection = sections.find((item) =>
        item.paths.includes(location.pathname)
      );
      let localCompleteSections = sections[0];
      if (localActiveSection) {
        localCompleteSections = sections.slice(
          0,
          sections.findIndex((item) => item === localActiveSection)
        );
      }

      const activeSectionIndex = sections.findIndex(
        (item) => item === localActiveSection
      );

      let completeSections = userSections.filter((item) => item.completed);

      let localUpdatedSections = userSections.map((item, index) => ({
        ...item,
        visible: item.requiresFacilitator
          ? chart?.FACILITATOR_INFO
            ? chart.FACILITATOR_INFO.facilitatorRequired ||
              chart.FACILITATOR_INFO.facilitator
            : false
          : true,
      }));

      if (completeSections.length <= localCompleteSections.length) {
        localUpdatedSections = updatedSections.map((item, index) => ({
          ...item,
          paths: sections[index].paths,
          completed: index < activeSectionIndex,
          active: index === activeSectionIndex,
          visible: item.requiresFacilitator
            ? chart?.FACILITATOR_INFO
              ? chart.FACILITATOR_INFO.facilitatorRequired ||
                chart.FACILITATOR_INFO.facilitator
              : false
            : true,
        }));
        completeSections = localCompleteSections;
      }
      updateNavigation({ uid: patientId, sections: localUpdatedSections });
      setActiveSection(activeSectionIndex);
      setCompletedSections(localCompleteSections);
      setUpdatedSections(localUpdatedSections);
    };
    init();
  }, [location, chart]);

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  const [stepPercentage, setStepPercentage] = useState(0);
  const CustomNavLink = ({ children, to, ...props }) => {
    resolved = useResolvedPath(to);
    match = useMatch({ path: resolved.pathname, end: true });

    if (chart?.facilitator === "true") {
      if (match && match.pathname === "/signup/basicInfo") {
        setStepPercentage(6.45);
      } else if (match && match.pathname === "/signup/contacts") {
        setStepPercentage(19.22);
      } else if (match && match.pathname === "/signup/doctorInfo") {
        setStepPercentage(31.33);
      } else if (match && match.pathname === "/signup/insurance") {
        setStepPercentage(44.1);
      } else if (match && match.pathname === "/signup/IDInfo") {
        setStepPercentage(56.15);
      } else if (match && match.pathname === "/signup/educationalVideo") {
        setStepPercentage(68.66);
      } else if (match && match.pathname === "/signup/recordingInstructions") {
        setStepPercentage(81.57);
      } else if (match && match.pathname === "/signup/signatures") {
        setStepPercentage(94.18);
      } else if (match && match.pathname === "/signup/sampleCard") {
        setStepPercentage(100);
      }
    } else {
      if (match && match.pathname === "/signup/basicInfo") {
        setStepPercentage(6.45);
      } else if (match && match.pathname === "/signup/contacts") {
        setStepPercentage(19.22);
      } else if (match && match.pathname === "/signup/doctorInfo") {
        setStepPercentage(31.33);
      } else if (match && match.pathname === "/signup/IDInfo") {
        setStepPercentage(44.1);
      } else if (match && match.pathname === "/signup/hipaaSignatures") {
        setStepPercentage(56.15);
      } else if (match && match.pathname === "/signup/educationalVideo") {
        setStepPercentage(68.66);
      } else if (match && match.pathname === "/signup/recordingInstructions") {
        setStepPercentage(81.57);
      } else if (match && match.pathname === "/signup/signatures") {
        setStepPercentage(94.18);
      } else if (match && match.pathname === "/signup/sampleCard") {
        setStepPercentage(100);
      }
    }

    return (
      <Link
        style={{
          backgroundColor: transparent,
          textDecoration: "none",
          paddingLeft: "12px",
          paddingRight: "12px",
          paddingTop: "12px",
          paddingBottom: "12px",
          borderRadius: "50%",
        }}
        to={to}
        {...props}
      >
        {children}
      </Link>
    );
  };

  return (
    <Box>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 2,
          },
        }}
        position="right"
      >
        {updatedSections.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  zIndex: 2,
                  backgroundColor:
                    index === activeSection
                      ? "#6E7176"
                      : item.completed
                      ? "#1B405A"
                      : "white",
                  textDecoration: "none",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                }}
                onClick={() => {
                  if ((item.completed || item.active) && item.visible) {
                    if (item.restricted) {
                      let allowedSections = updatedSections.filter(
                        (item) => !item.restricted
                      );
                      navigate(
                        allowedSections[allowedSections.length - 1].paths[0]
                      );
                    } else {
                      navigate(item.paths[0]);
                    }
                  }
                }}
              />
              {index < sections.length - 1 ? (
                <TimelineConnector
                  sx={{
                    zIndex: 0,
                    width: "1em",
                    marginTop: "-2em",
                    marginBottom: "-2em",
                    backgroundColor: item.completed ? "#1B405A" : "white",
                  }}
                />
              ) : null}
            </TimelineSeparator>
            <TimelineContent
              className={`stepMenuTitle ${
                index === activeSection ? "active" : null
              }`}
            >
              {item.name}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};
