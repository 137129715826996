import React, { useRef, useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextInput,
} from "../../../../Features/Forms";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import { useNavigate } from "react-router-dom";
import { submitInsuranceInformation } from "../../../../../data/mutations/NewPatient/submitInsuranceInformation";
import { getDiscountCodeAPI } from "../../../../../data/mutations/NewPatient/domainCoupon";
import { unlockFile } from "./unlockFile";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../data/constants/firebase";
import {
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  TypographyField,
  Stack,
  useMediaQuery,
  Button,
  Box,
  Skeleton,
  Alert,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import { listProductsApi } from "../../../../../data/mutations/NewPatient/api";
import { ProductCard } from "./ProductCard";
import {
  useStripe,
  useElements,
  CardElement,
  AddressElement,
  Elements,
} from "@stripe/react-stripe-js";
import { PaymentForm } from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { AlertDialog } from "./AlertDialog";
import { ScheduleConsultDialog } from "./ScheduleConsultDialog";

const stripePromise = loadStripe(
  "pk_test_51HgY8BA1hg8BPrh2z1aov1JOhVjPMgmmO7RaoXoHaVrD5n5VdxtN3Sla7Wpvz7AzUVeTCU5RgatGqsAasMHxu31700Sj2lYQv7"
);

export const PaymentInfo = () => {
  const stripe = useStripe();
  const elements = useElements();

  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [toggleFacilitator, setToggleFacilitator] = useState(null);

  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
      if (oldChart.chart.PAYMENT_INFO?.alreadyPaid) {
        setAlreadyPaid(oldChart.chart.PAYMENT_INFO?.alreadyPaid);
      }
    })();
  }, [patientId]);

  const [productCost, setProductCost] = useState(0);
  const [alreadyPaid, setAlreadyPaid] = useState();
  const [cardName, setCardName] = useState();
  const [cardEmail, setCardEmail] = useState();
  const [cardPhone, setCardPhone] = useState();
  const [couponCode, setCouponCode] = useState();
  const [cardDetails, setCardDetails] = useState();
  const [paymentInProcess, setPaymentInProcess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [allowedCoupon, setAllowedCoupon] = useState("NONE");

  const [viewSiteId, setViewSiteId] = useState(true);
  const [viewFacilitatorId, setViewFacilitatorId] = useState(true);

  const [toggleMedicareDescriptors, setToggleMedicareDescriptors] = useState();
  const [toggleMedicare, setToggleMedicare] = useState();
  const [toggleState, setToggleState] = useState(false);
  const [nonMedicareABNChoice, setToggleNonMedicareABNChoice] = useState(null);
  const [medicareABNChoice, setToggleMedicareABNChoice] = useState(null);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [originalProductCost, setOriginalProductCost] = useState(399);
  const [discount, setDiscount] = useState(0);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [selectionConfirmed, setSelectionConfirmed] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: "",
    description: "",
  });

  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const baseURL = {
    production: "https://us-central1-web-console-mideoid.cloudfunctions.net",
    development: "https://us-central1-dev-mideo.cloudfunctions.net",
  };

  const API_URL = `${
    process.env.REACT_APP_ENVIRONMENT
      ? baseURL[process.env.REACT_APP_ENVIRONMENT]
      : baseURL.production
  }/createPurchaseIntent?amount=${productCost * 100}`;
  const UPDATEPI_URL = `${
    process.env.REACT_APP_ENVIRONMENT
      ? baseURL[process.env.REACT_APP_ENVIRONMENT]
      : baseURL.production
  }/updatePurchaseIntent?id=${paymentIntentId}&amount=${productCost * 100}`;

  const fetchPaymentIntent = async () => {
    const response = await fetch(`${API_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      // console.log(res);
      return res.json();
    });
    setClientSecret(response.client_secret);
    setPaymentIntentId(response.id);
  };

  const updatePaymentIntent = async () => {
    console.log("Fetching Payment Intent");
    const response = await fetch(`${UPDATEPI_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      // console.log(res);
      return res.json();
    });
    setClientSecret(response.client_secret);
    setPaymentIntentId(response.id);
  };

  useEffect(() => {
    if (alreadyPaid) {
      formRef.current.values.alreadyPaid = "true";
    } else if (alreadyPaid === false) {
      formRef.current.values.alreadyPaid = "false";
    }
  }, [alreadyPaid]);

  useEffect(() => {
    if (formRef?.current?.values) {
      if (formRef?.current?.values.facilitator === "true") {
        setToggleFacilitator(true);
      } else if (formRef?.current?.values.facilitator === "false") {
        setToggleFacilitator(false);
      } else {
        setToggleFacilitator(null);
      }
    }
    if (formRef?.current?.values) {
      if (formRef?.current?.values.alreadyPaid === "true") {
        setAlreadyPaid(true);
      } else if (formRef?.current?.values.alreadyPaid === "false") {
        setAlreadyPaid(false);
      } else {
        setAlreadyPaid(null);
      }
    }

    const init = async () => {
      const localProducts = await listProductsApi();
      setProducts(localProducts);
      setLoadingProducts(false);
    };
    init();
  }, []);

  useEffect(() => {
    setSelectedProduct(
      products?.find(
        (item) =>
          item.key === chart?.PAYMENT_INFO?.selectedProduct ||
          item.key === chart?.PRODUCT_INFO?.selectedProduct
      )
    );
    setToggleFacilitator(
      products?.find(
        (item) =>
          item.key === chart?.PAYMENT_INFO?.selectedProduct ||
          item.key === chart?.PRODUCT_INFO?.selectedProduct
      )?.facilitatorRequired
    );
  }, [products, chart]);

  useEffect(() => {
    if (couponCode === "Mideo123!") {
      setAlertMessage({
        title: "Coupon Accepted!",
        description: "100% discount applied",
      });
      setAlertOpen(true);
      setAlreadyPaid(true);
    } else if (couponCode === allowedCoupon) {
      setAlertMessage({
        title: "Coupon Accepted!",
        description: `Your coupon code has been accepted. $${discount.toFixed(
          2
        )} discount applied`,
      });
      setAlertOpen(true);
      //useDiscountCodeApi(user.uid);
      setProductCost(originalProductCost - discount);
      if (originalProductCost - discount < 1) {
        setAlreadyPaid(true);
      }
    } else {
      setProductCost(originalProductCost);
    }
  }, [couponCode]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handleSubmitPaymentInfo = async (values) => {
    const PAYMENT_INFO = {
      alreadyPaid: true,
      amountPaid: values.amountPaid || productCost,
      discountApplied: values.discountApplied || discount,
      couponCode: values.couponCode || (allowedCoupon ? allowedCoupon : null),
      uid: values.uid,
      selectedProduct: values.selectedProduct || selectedProduct.key,
    };
    const patientInfoChartRef = doc(
      db,
      "users",
      values.uid,
      "Form",
      "PAYMENT_INFO"
    );
    await setDoc(patientInfoChartRef, PAYMENT_INFO);
  };

  const handleSubmitFacilitatorInfo = async (values) => {
    const FACILITATOR_INFO = {
      facilitatorRequired: toggleFacilitator,
      facilitator: toggleFacilitator,
      facilitatorID: values.facilitatorID,
      siteID: values.siteID,
    };
    const facilitatorInfoChartRef = doc(
      db,
      "users",
      values.uid,
      "Form",
      "FACILITATOR_INFO"
    );
    await setDoc(facilitatorInfoChartRef, FACILITATOR_INFO);
  };

  useEffect(() => {
    if (selectionConfirmed) {
      if (paymentIntentId !== "") {
        updatePaymentIntent();
      } else {
        fetchPaymentIntent();
      }
    }
  }, [selectionConfirmed]);

  useEffect(() => {
    if (selectionConfirmed) {
      if (selectedProduct) {
        setToggleFacilitator(selectedProduct.facilitatorRequired);
        setProductCost(selectedProduct.oneTimePrice);
        setOriginalProductCost(selectedProduct.oneTimePrice);
        if (selectedProduct.oneTimePrice) {
          const init = async () => {
            const response = await getDiscountCodeAPI(
              patientId,
              chart.PATIENT_INFO_2.email,
              selectedProduct.key
            );
            if (response.discountCode && !response.used) {
              setAllowedCoupon(response.discountCode);
              setDiscount(response.discount);
              setAlertMessage({
                title: "Coupon Code Sent!",
                description: `A coupon code has been sent to your organizational email address at ${chart.PATIENT_INFO_2.email}.`,
              });
              setAlertOpen(true);
            }
          };
          if (!toggleFacilitator && chart) {
            init();
          }
        }
      }
    } else if (alreadyPaid) {
      if (selectedProduct) {
        setToggleFacilitator(selectedProduct.facilitatorRequired);
        setProductCost(selectedProduct.oneTimePrice);
        setOriginalProductCost(selectedProduct.oneTimePrice);
      }
    }
  }, [selectionConfirmed, selectedProduct]);

  const handleSubmitPayment = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    /*  console.log(elements.getElement(CardElement))
    console.log(elements.getElement(AddressElement)) */
  };

  const handleScheduling = () => {
    window.open(
      "https://consumer.scheduling.athena.io/?locationId=26368-1",
      "_blank"
    );
  };

  return (
    <div className="flex flex-col items-center">
      <AlertDialog
        openState={{ open: alertOpen, setOpen: setAlertOpen }}
        options={alertMessage}
      />
      <ScheduleConsultDialog
        open={scheduleDialogOpen}
        handleClose={() => setScheduleDialogOpen(false)}
        handleScheduling={() => handleScheduling()}
      />
      <Container>
        <Typography
          variant="h4"
          sx={{
            color: "#1B405A",
            fontSize: "32px",
            fontFamily: "Helvetica Neue",
            fontWeight: 700,
            margin: "29px 0 29px 0",
            width: "fit-content",
          }}
        >
          Product & Payment Information
        </Typography>

        <Typography
          variant="h4"
          sx={{
            color: "#1B405A",
            fontSize: "16px",
            fontFamily: "Helvetica Neue",
            fontWeight: 500,
            margin: "29px 0 31px 0",
            width: "fit-content",
          }}
        >
          Select the product you wish to purchase.
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && (
            <>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={50}
                sx={{ marginBottom: 1 }}
              />
              <Skeleton variant="rectangular" width={"100%"} height={300} />
            </>
          )}
          {selectionConfirmed || (alreadyPaid && selectedProduct?.name) ? (
            <ProductCard
              item={selectedProduct}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              selectDisabled={alreadyPaid}
            />
          ) : (
            <>
              {products.map((item, index) => (
                <Grid key={index} item xs={12} md={6} lg={6}>
                  <ProductCard
                    item={item}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
        {!alreadyPaid ? (
          <div style={{ margin: "0 auto", width: "fit-content" }}>
            <button
              className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900"
              onClick={() => {
                setSelectionConfirmed(!selectionConfirmed);
              }}
              disabled={
                selectedProduct
                  ? selectedProduct.oneTimePrice
                    ? false
                    : true
                  : true
              }
            >
              {!selectionConfirmed
                ? "Confirm Selection"
                : "Choose Another Product"}
            </button>
          </div>
        ) : null}
      </Container>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: patientId,
          firstName: chart?.FACILITATOR_INFO?.firstName || "",
          facilitator: chart?.FACILITATOR_INFO?.facilitator || "",
          facilitatorID: chart?.FACILITATOR_INFO?.facilitatorID || "",
          siteID: chart?.FACILITATOR_INFO?.siteID || "",
          selectedProduct: chart?.PAYMENT_INFO?.selectedProduct || "",
          couponCode: chart?.PAYMENT_INFO?.couponCode || "",
          alreadyPaid: chart?.PAYMENT_INFO?.alreadyPaid || "",
          amountPaid: chart?.PAYMENT_INFO?.amountPaid || "",
          discountApplied: chart?.PAYMENT_INFO?.discountApplied || "",
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={(values, { setSubmitting }) => {
          const newChartData = {
            ...previousChartData,
            ...values,
            selectedProduct:
              chart?.PAYMENT_INFO?.selectedProduct || selectedProduct.key,
            couponCode: chart?.PAYMENT_INFO?.couponCode || couponCode,
            alreadyPaid: chart?.PAYMENT_INFO?.alreadyPaid || alreadyPaid,
            amountPaid: chart?.PAYMENT_INFO?.amountPaid || productCost,
            discountApplied: chart?.PAYMENT_INFO?.discountApplied || discount,
          };
          setNewPatientChartData(newChartData);
          handleSubmitPaymentInfo(newChartData);
          handleSubmitFacilitatorInfo(newChartData);
          toggleFacilitator
            ? values.facilitatorID === "" || values.siteID === ""
              ? setScheduleDialogOpen(true)
              : navigate("/signup/insurance")
            : navigate("/signup/IDInfo");
        }}
        onBlur={(values) => console.log("BLUR")}
      >
        {({
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form style={{ width: "95%" }}>
            {toggleFacilitator && (selectionConfirmed || alreadyPaid) ? (
              <>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "24px",
                    TypographyAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "29px auto 31px auto",
                    width: "fit-content",
                  }}
                >
                  The selected product includes working with an Advance Care
                  Planning Educator (ACPE) to help you with the process.
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "16px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "29px 0 31px 0",
                    width: "fit-content",
                  }}
                >
                  Enter their information here. If you don't have an ACPE, leave
                  this blank and you will be directed to schedule an appointment
                  with one after purchase.
                </Typography>

                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormikTextInput
                      label="Facilitator ID"
                      name="facilitatorID"
                      type={viewFacilitatorId ? "password" : "text"}
                      handleChange={handleChange}
                    />
                    <IconButton
                      onClick={() => setViewFacilitatorId(!viewFacilitatorId)}
                    >
                      {viewFacilitatorId ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormikTextInput
                      label="Site ID"
                      name="siteID"
                      type={viewSiteId ? "password" : "text"}
                      handleChange={handleChange}
                    />
                    <IconButton onClick={() => setViewSiteId(!viewSiteId)}>
                      {viewSiteId ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </Stack>
                </Stack>
              </>
            ) : null}
            {selectedProduct && !alreadyPaid && selectionConfirmed ? (
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #1B405A",
                  borderRadius: "20px",
                  padding: 2,
                  marginTop: "32px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#720D0F",
                    fontSize: "24px",
                    TypographyAlign: "center",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    margin: "16px auto 0px auto",
                    width: "fit-content",
                  }}
                >
                  Payment Form:
                </Typography>
                <Typography
                  sx={{
                    color: "#1B405A",
                    fontSize: "18px",
                    margin: "8px 0px",
                  }}
                >
                  The total cost for your MIDEO Card is $
                  {productCost.toFixed(2)}
                </Typography>
                <Typography
                  style={{
                    margin: "32px auto 16px auto",
                    color: "#1B405A",
                    textAlign: "center",
                  }}
                >
                  To begin the payment process, enter your payment information
                  below and press "Pay":
                </Typography>
                <>
                  <input
                    placeholder={"Coupon Code"}
                    name={"couponCode"}
                    type={"text"}
                    style={{
                      borderRadius: "4px",
                      margin: "7px 0",
                      width: "270px",
                      height: "56px",
                      border: "1px solid #0000003B",
                    }}
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                  />
                </>
                {clientSecret && (
                  <Elements style={{ width: "100%" }} stripe={stripePromise}>
                    <PaymentForm
                      paymentIntentId={paymentIntentId}
                      clientSecret={clientSecret}
                      paymentInProcess={paymentInProcess}
                      setPaymentInProcess={setPaymentInProcess}
                      handlePaymentSuccess={() => setAlreadyPaid(true)}
                    />
                  </Elements>
                )}
              </Stack>
            ) : null}
            <div style={{ margin: "0 auto", width: "fit-content" }}>
              <button
                onClick={handleSubmit}
                type="submit"
                disabled={!alreadyPaid}
                className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save & Continue <ChevronRightIcon sx={{ fontSize: "32px" }} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {toggleFacilitator !== null && alreadyPaid ? (
        <Stack
          direction="column"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#720D0F",
              fontSize: "24px",
              TypographyAlign: "center",
              fontFamily: "Helvetica Neue",
              fontWeight: 500,
              margin: "32px auto 8px auto",
              width: "fit-content",
            }}
          >
            Payment Form:
          </Typography>
          <Typography style={{ color: "#1B405A" }}>
            Payment Completed
          </Typography>
          <Typography
            style={{
              margin: "16px 8px",
              color: "#1B405A",
              textAlign: "center",
            }}
          >
            You have successfully made your payment. Thank you!
          </Typography>
        </Stack>
      ) : null}
    </div>
  );
};
