import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useReducer,
} from "react";
import Webcam from "react-webcam";
import { uploadToCloudStorage } from "./uploadToCloudStorage";
import {
  Typography,
  TypographyField,
  Stack,
  useMediaQuery,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Add, Remove, TextDecrease, TextIncrease } from "@mui/icons-material";
import { Formik, Field, Form } from "formik";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import { submitVideoURIInformation } from "../../../../../data/mutations/NewPatient/submitVideoURIInformation";
import { AlertDialog } from "./AlertDialog";
import { UploadingIndicator } from "./UploadingIndicator";

export const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [finalVideoURI, setFinalVideoURI] = useState();
  //const chart = newPatientChartData.use();
  //const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [token, setToken] = useState(false);

  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [script, setScript] = useState();
  const [textSize, textSizeDispatch] = useReducer(
    (state, action) => {
      if (action.type === "increment") {
        return {
          size: state.size + 2,
        };
      } else if (action.type === "decrement") {
        return {
          size: state.size - 2,
        };
      }

      throw Error("Unknown Action");
    },
    { size: 16 }
  );

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    title: "",
    description: "",
  });
  const [previewUrl, setPreviewUrl] = useState();
  const [previewing, setPreviewing] = useState(false);

  const [progress, setProgress] = useState(-1);

  let userAgent = navigator.userAgent;
  let browserName;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    console.log(browserName);
  }, [browserName]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  const handleStartCaptureClick = React.useCallback(() => {
    setRecordedChunks([]);
    if (webcamRef.current.state.hasUserMedia) {
      if (browserName === "safari") {
        console.log("inside option 1", browserName);
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: "video/mp4;codec=avc1",
        });

        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        console.log(
          "mediaRecorderRef.current in 1start",
          mediaRecorderRef.current
        );
        mediaRecorderRef.current.start();
      } else if (browserName !== "safari") {
        console.log("inside option 2", browserName);
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: "video/webm;codec=avc1.42E01F",
        });

        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        console.log(
          "mediaRecorderRef.current in 2start",
          mediaRecorderRef.current
        );
        mediaRecorderRef.current.start();
      }
    } else {
      setAlertOptions({
        title: "Webcam Not Detected!",
        description:
          "We tried to start recording your Informed Decision on Video, but were unable to find a webcam.",
      });
      setAlertOpen(true);
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  useEffect(() => {
    if (!capturing) {
      handleDownload();
    }
  }, [recordedChunks]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    console.log("mediaRecorderRef.current is stop", mediaRecorderRef.current);
    mediaRecorderRef.current.stop();
    setCapturing(false);
    handleDownload();
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      setPreviewing(true);
      if (browserName === "safari") {
        const blob = new Blob(recordedChunks, {
          type: "video/mp4",
        });
        const url = URL.createObjectURL(blob);
        const video = document.getElementById("video-replay");
        video.src = url;
        video.load();
      } else {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });
        const url = URL.createObjectURL(blob);
        const video = document.getElementById("video-replay");
        video.src = url;
        video.load();
      }
    }
  }, [recordedChunks]);

  const uploadVideo = async () => {
    const uid = chart?.PATIENT_INFO_1?.uid;
    if (recordedChunks.length) {
      setPreviewing(true);
      if (browserName === "safari") {
        const blob = new Blob(recordedChunks, {
          type: "video/mp4",
        });
        const url = URL.createObjectURL(blob);
        const video = document.getElementById("video-replay");
        video.src = url;
        video.load();
      } else {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });
        const url = URL.createObjectURL(blob);
        const video = document.getElementById("video-replay");
        video.src = url;
        video.load();
      }
    }
    if (recordedChunks.length) {
      if (navigator.userAgent.match(/safari/i)) {
        const blob = new Blob(recordedChunks, {
          type: "video/mp4",
        });
        const file = new File([blob], "originalVideo", { type: blob.type });
        const payload = {
          uid,
          folder: "",
          file: file,
          token: token,
        };
        const tempURI = await uploadToCloudStorage(payload, setProgress, true);
        setFinalVideoURI(tempURI);
        submitVideoURIInformation({ tempURI, uid });
        setRecordedChunks([]);
        navigate("/signup/signatures");
      } else {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });
        const file = new File([blob], "originalVideo", { type: blob.type });
        const payload = {
          uid,
          folder: "",
          file: file,
          token: token,
        };
        const tempURI = await uploadToCloudStorage(payload, setProgress, true);
        setFinalVideoURI(tempURI);
        submitVideoURIInformation({ tempURI, uid });
        setRecordedChunks([]);
        navigate("/signup/signatures");
      }
    }
  };
  const handleCreatePreview = () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      setPreviewUrl(URL.createObjectURL(blob));
    }
  };

  return (
    <div>
      <div>
        <AlertDialog
          openState={{ open: alertOpen, setOpen: setAlertOpen }}
          options={alertOptions}
        />
      </div>
      <UploadingIndicator
        open={progress > -1 && progress < 100}
        size={"10em"}
        thickness={5}
        value={progress}
        fontSize="2em"
      />
      {chart ? (
        <Formik
          innerRef={formRef}
          enableReinitialize={false}
          initialValues={{
            uid: patientId,
            firstName: chart?.firstName || "",
            telePrompterScript: chart?.SCRIPT_REVIEW?.telePrompterScript || "",
            videoDownloadURI: chart?.VIDEO_URI?.videoDownloadURI || "",
          }}
          // validationSchema={PatientInfoSchema}
          onSubmit={(values, { setSubmitting }) => {
            uploadVideo();
            values.videoDownloadURI = finalVideoURI;
            const newChartData = { ...previousChartData, ...values };
            setNewPatientChartData(newChartData);
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
            <Form>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box style={{ marginTop: "32px" }}>
                  {chart ? (
                    <Box
                      style={{
                        height: "auto",
                        width: "75%",
                        margin: "32px auto 0px auto",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: textSize.size,
                          margin: "16px 8px",
                          color: "#1B405A",
                          textAlign: "center",
                        }}
                      >
                        {chart?.SCRIPT_REVIEW?.telePrompterScript}
                      </Typography>
                    </Box>
                  ) : null}
                  <p className="text-xl mt-4 text-mideoMaroon text-center">
                    NOTE: PLEASE CENTER YOUR FACE IN VIDEO
                  </p>
                </Box>
                <Stack direction={"row"} spacing={5}>
                  <IconButton
                    onClick={() => textSizeDispatch({ type: "increment" })}
                  >
                    <TextIncrease />
                  </IconButton>
                  <IconButton
                    onClick={() => textSizeDispatch({ type: "decrement" })}
                  >
                    <TextDecrease />
                  </IconButton>
                </Stack>
                {!previewing && browserName && (
                  <Webcam
                    style={{
                      borderColor: "red",
                      borderWidth: capturing ? 7 : 0,
                    }}
                    audio={true}
                    muted={true}
                    ref={webcamRef}
                    videoConstraints={{
                      height: { min: 608 },
                    }}
                  />
                )}
                <>
                  <video
                    hidden={!previewing}
                    id="video-replay"
                    controls
                  ></video>
                  {previewing && (
                    <button
                      type="button"
                      onClick={() => {
                        setPreviewing(false);
                        setRecordedChunks([]);
                        const video = document.getElementById("video-replay");
                        video.pause();
                      }}
                      className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Re-Record
                    </button>
                  )}
                </>

                {!previewing && (
                  <button
                    type="button"
                    onClick={
                      capturing
                        ? handleStopCaptureClick
                        : handleStartCaptureClick
                    }
                    className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {`${capturing ? "Stop" : "Start"} Recording MIDEO Video`}
                  </button>
                )}

                <div style={{ margin: "0 auto", width: "fit-content" }}>
                  <button
                    type="submit"
                    disabled={!recordedChunks.length}
                    className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue{" "}
                    <ChevronRightIcon sx={{ fontSize: "32px" }} />
                  </button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
