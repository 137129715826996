import React from "react";
import { Link } from "react-router-dom";

export const FormIntro = () => {
  return (
    <>
      <p>Intro & Marketing Material</p>
      <Link to="signup/basicInfo"> Sign me up! </Link>
    </>
  );
};
