import { db } from "../../constants/firebase";
import { addDoc, collection } from "firebase/firestore";


export const sendCardCreatedNotification = (payload) => {
  const chart = payload;
  const uid = chart.PATIENT_INFO_1.uid;
  const first = chart.PATIENT_INFO_1.firstName;
  const last = chart.PATIENT_INFO_1.lastName;

  const docRef = collection(db, "notifications")
  addDoc(docRef, {
    createdById: "system",
    createdByName: "SYSTEM",
    isArchived: false,
    message: `${first} ${last} has registered as a new user.`,
    patientId: uid,
    timeCreated: Date.now(),
    type: "newPatient",
  })
}