import { db } from "../../constants/firebase";
import { getAuth } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";


export const updateFilterOptions = async (payload) => {
  const user = getAuth().currentUser;

  let filterKey;
  let filterValue;

 
  const docRef = doc(db, "users", user.uid)
  await setDoc(docRef,
    {
      filterOptions: {
        ...payload,
      },
    },
    { merge: true }
  )
};