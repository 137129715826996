import React, { useEffect, useRef, useState } from "react";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { submitFinalSignaturesInformation } from "../../../../../data/mutations/NewPatient/submitFinalSignaturesInformation";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { SignatureOnFile } from "./SignatureOnFile";
import { SignatureBox } from "./SignatureBox";
import { currentUser } from "../../../../../data/localState/currentUser";
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import {
  Container,
  FormControlLabel,
  FormGroup,
  Input,
  Switch,
  TextField,
} from "@mui/material";
export const Signatures = () => {
  const [patientId, setPatientId] = useState(currentUser.use().uid);
  const [chart, setChart] = useState();

  const [user, setUser] = useState();
  const [witnessPresent, setWitnessPresent] = useState(true);
  const [witness2Present, setWitness2Present] = useState(true);
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [formHasChanges, setFormHasChanges] = useState(false);

  const patient = currentUser.use();
  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
      setUser(oldChart.user);
    })();
  }, [patientId]);

  const [PatientSigModalIsOpen, setPatientSigModalIsOpen] = useState(false);
  const [patientSignatureDataURI, setPatientSignatureDataURI] = useState(false);
  const [PatientSignatureExists, setPatientSignatureExists] = useState(false);

  const [WitnessSigModalIsOpen, setWitnessSigModalIsOpen] = useState(false);
  const [witnessSignatureDataURI, setWitnessSignatureDataURI] = useState(false);
  const [WitnessSignatureExists, setWitnessSignatureExists] = useState(false);

  const [Witness2SigModalIsOpen, setWitness2SigModalIsOpen] = useState(false);
  const [witness2SignatureDataURI, setWitness2SignatureDataURI] =
    useState(false);
  const [Witness2SignatureExists, setWitness2SignatureExists] = useState(false);

  const [token, setToken] = useState(false);
  const [upToDate, setUpToDate] = useState(true);

  useEffect(() => {
    setWitnessSignatureDataURI(chart?.WITNESS_SIGNATURE?.signature1DataURI);
    setWitness2SignatureDataURI(chart?.WITNESS_SIGNATURE?.signature2DataURI);
    setPatientSignatureDataURI(chart?.PATIENT_SIGNATURE?.signature1DataURI);
    setWitnessSignatureExists(
      chart?.WITNESS_SIGNATURE?.signature1DataURI &&
        chart?.WITNESS_SIGNATURE?.signature1DataURI !== ""
    );
    setWitness2SignatureExists(
      chart?.WITNESS_SIGNATURE?.signature2DataURI &&
        chart?.WITNESS_SIGNATURE?.signature2DataURI !== ""
    );
    setPatientSignatureExists(
      chart?.WITNESS_SIGNATURE?.signature1DataURI &&
        chart?.PATIENT_SIGNATURE?.signature1DataURI !== ""
    );
  }, [chart]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          uid: patientId,
          returning: chart?.returning || true,
          patientSignatureDataURI:
            chart?.PATIENT_SIGNATURE?.signature1DataURI || "",
          witnessSignatureDataURI:
            chart?.WITNESS_SIGNATURE?.signature1DataURI || "",
          witness2SignatureDataURI:
            chart?.WITNESS_SIGNATURE?.signature2DataURI || "",
          witnessName: chart?.WITNESS_SIGNATURE.witnessName || "",
          witnessEmail: chart?.WITNESS_SIGNATURE.witnessEmail || "",
          witness2Name: chart?.WITNESS_SIGNATURE.witness2Name || "",
          witness2Email: chart?.WITNESS_SIGNATURE.witness2Email || "",
        }}
        // validationSchema={PatientInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          values.patientSignatureDataURI = patientSignatureDataURI;
          values.witnessSignatureDataURI = witnessSignatureDataURI;
          values.witness2SignatureDataURI = witness2SignatureDataURI;
          const newChartData = { ...chart, ...user, ...values };
          console.log("Signatures", values);
          submitFinalSignaturesInformation(newChartData);
          navigate("/signup/sampleCard");
          // setUpToDate(false)
          // editChart(values)
        }}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <Form>
            <Typography
              variant="h4"
              sx={{
                color: "#1B405A",
                fontSize: "32px",
                fontFamily: "Helvetica Neue",
                fontWeight: 700,
                margin: "29px 0 0 0",
                width: "fit-content",
              }}
            >
              Patient & Witness Signatures
            </Typography>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h4">Patient Signature</Typography>
              <button
                type="button"
                onClick={(e) => setPatientSigModalIsOpen(true)}
                className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Capture Signature of Patient
              </button>
              {PatientSignatureExists && (
                <SignatureOnFile uri={patientSignatureDataURI} />
              )}
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                  paddingTop: "2em",
                }}
              >
                <Typography variant="h4">Witness Signature</Typography>
                <Typography>Is Witness Present?</Typography>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      flexBasis: 100,
                      marginRight: 2,
                      textAlign: "right",
                    }}
                  >
                    Not Present
                  </Typography>
                  <Switch
                    checked={witnessPresent}
                    onChange={(event) => {
                      setFieldValue("witnessName", "");
                      setFieldValue("witnessEmail", "");
                      setWitnessSignatureDataURI("");
                      setWitnessSignatureExists(false);
                      setWitnessPresent(event.target.checked);
                    }}
                  />
                  <Typography
                    sx={{
                      flexGrow: 1,
                      flexBasis: 100,
                      marginLeft: 2,
                      textAlign: "left",
                    }}
                  >
                    Present
                  </Typography>
                </Container>
                {witnessPresent ? (
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      sx={{ width: "33%" }}
                      label="Witness Name"
                      variant="filled"
                      value={values.witnessName}
                      id={"witnessName"}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      onClick={(e) => setWitnessSigModalIsOpen(true)}
                      className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Capture Signature of Witness
                    </button>
                  </Container>
                ) : (
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography>
                      Since you have indicated that your witness is not present,
                      we will have to request their signature through DocuSign.
                      Once you submit their information, you can continue the
                      application, but it will not be processed until their
                      signatures are recieved
                    </Typography>
                    <Container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        sx={{ width: "33%" }}
                        label="Witness Name"
                        variant="filled"
                        value={values.witnessName}
                        id={"witnessName"}
                        onChange={handleChange}
                      />
                      <TextField
                        sx={{ width: "33%" }}
                        label="Witness Email Address"
                        variant="filled"
                        value={values.witnessEmail}
                        id={"witnessEmail"}
                        onChange={handleChange}
                      />
                    </Container>
                  </Container>
                )}
              </Container>

              {WitnessSignatureExists && (
                <SignatureOnFile uri={witnessSignatureDataURI} />
              )}

              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                  paddingTop: "2em",
                }}
              >
                <Typography variant="h4">
                  Secondary Witness Signature
                </Typography>
                <Typography>Is Witness Present?</Typography>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      flexBasis: 100,
                      marginRight: 2,
                      textAlign: "right",
                    }}
                  >
                    Not Present
                  </Typography>
                  <Switch
                    checked={witness2Present}
                    onChange={(event) => {
                      setFieldValue("witness2Name", "");
                      setFieldValue("witness2Email", "");
                      setWitness2SignatureDataURI("");
                      setWitness2SignatureExists(false);
                      setWitness2Present(event.target.checked);
                    }}
                  />
                  <Typography
                    sx={{
                      flexGrow: 1,
                      flexBasis: 100,
                      marginLeft: 2,
                      textAlign: "left",
                    }}
                  >
                    Present
                  </Typography>
                </Container>
                {witness2Present ? (
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      sx={{ width: "33%" }}
                      label="Secondary Witness Name"
                      variant="filled"
                      value={values.witness2Name}
                      id={"witness2Name"}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      onClick={(e) => setWitness2SigModalIsOpen(true)}
                      className=" mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mideodkblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Capture Signature of Secondary Witness (Optional)
                    </button>
                  </Container>
                ) : (
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography>
                      Since you have indicated that your witness is not present,
                      we will have to request their signature through DocuSign.
                      Once you submit their information, you can continue the
                      application, but it will not be processed until their
                      signatures are recieved
                    </Typography>
                    <Container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        sx={{ width: "33%" }}
                        label="Secondary Witness Name"
                        variant="filled"
                        value={values.witness2Name}
                        id={"witness2Name"}
                        onChange={handleChange}
                      />
                      <TextField
                        sx={{ width: "33%" }}
                        label="Secondary Witness Email Address"
                        variant="filled"
                        value={values.witness2Email}
                        id={"witness2Email"}
                        onChange={handleChange}
                      />
                    </Container>
                  </Container>
                )}
              </Container>
              {Witness2SignatureExists && (
                <SignatureOnFile uri={witness2SignatureDataURI} />
              )}
              <div style={{ margin: "0 auto", width: "fit-content" }}>
                <button
                  type="submit"
                  disabled={
                    !PatientSignatureExists ||
                    (witnessPresent
                      ? !WitnessSignatureExists
                      : values.witnessName === "" || values.witnessEmail === "")
                  }
                  className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save & Continue <ChevronRightIcon sx={{ fontSize: "32px" }} />
                </button>
              </div>
            </Stack>

            <SignatureBox
              fileName={"PATIENT_SIGNATURE_PatientFinalSignature"}
              isOpen={PatientSigModalIsOpen}
              setIsOpen={setPatientSigModalIsOpen}
              patientId={patientId}
              setSignatureExists={setPatientSignatureExists}
              setSignatureUri={setPatientSignatureDataURI}
              setFormHasChanges={setFormHasChanges}
              contentLabel={"Patient Signature Modal"}
            />

            <SignatureBox
              fileName={"WITNESS_SIGNATURE_WitnessFinalSignature"}
              isOpen={WitnessSigModalIsOpen}
              setIsOpen={setWitnessSigModalIsOpen}
              patientId={patientId}
              setSignatureExists={setWitnessSignatureExists}
              setSignatureUri={setWitnessSignatureDataURI}
              setFormHasChanges={setFormHasChanges}
              contentLabel={"Witness Signature Modal"}
            />

            <SignatureBox
              fileName={"WITNESS_SIGNATURE_Witness2FinalSignature"}
              isOpen={Witness2SigModalIsOpen}
              setIsOpen={setWitness2SigModalIsOpen}
              patientId={patientId}
              setSignatureExists={setWitness2SignatureExists}
              setSignatureUri={setWitness2SignatureDataURI}
              setFormHasChanges={setFormHasChanges}
              contentLabel={"Secondary Witness Signature Modal"}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
