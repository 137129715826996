import { collection, doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";
import { sections } from "../../constants/sections";

export const getUser = async (uid) => {

  const docRef = doc(db, "users", uid);
  const document = await getDoc(docRef);
  if (document.exists) {
    return document.data();
  } else {
    return {}
  }
};
