import axios from "axios";

export const unlockFile = async (payload) => {  
  const uriToUnlock = `https://${process.env.REACT_APP_ENVIRONMENT ? (process.env.REACT_APP_ENVIRONMENT === 'development' ? 'us-central1-dev-mideo.cloudfunctions.net' : 'us-central1-web-console-mideoid.cloudfunctions.net') : 'us-central1-web-console-mideoid.cloudfunctions.net'}/httpsFunctions/generateSignedURI?uid=${payload.uid}&folder=${payload.folder}&name=${payload.name}`;
  //const uriToUnlock = `https://us-central1-dev-mideo.cloudfunctions.net/httpsFunctions/generateSignedURI?uid=${payload.uid}&folder=${payload.folder}&name=${payload.name}`;
  const getRequest = await axios({
    method: "get",
    url: uriToUnlock,
    json: true,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${payload.token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error("in postRequest. ERROR =", error);
    });
  return getRequest;
};
