import React from 'react';
import { Stack } from '@mui/system';
import Webcam from 'react-webcam';
import { Box, CircularProgress, Dialog, Typography } from '@mui/material';

export const TakeNewPhoto = (props) => {
  const webcamRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false)

  const capture = React.useCallback(async () => {
    setLoading(true)
    const imageSrc = webcamRef.current.getScreenshot();
    await props.handleTakePicture(imageSrc);
    console.log(imageSrc);
    setLoading(false)
    props.setVisible(false)
  }, [webcamRef]);

  return (
    <Dialog open={props.visible} onClose={() => props.setVisible(false)} >
      <Stack
        direction="column"
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant='h3' sx={{marginBottom: 5}}>
          {props.title}
        </Typography>
        {loading ? <CircularProgress variant='indeterminate' size={48} /> :
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
        }

        <Box>
          <button
            className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
            onClick={capture}
          >
            Capture photo
          </button>
        </Box>
      </Stack>
    </Dialog>
  );
};
