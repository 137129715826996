import React, { useRef, useState, useEffect, useReducer } from "react";
import { Formik, Field, Form } from "formik";
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextArea,
  FormikTextInput,
} from "../../../../Features/Forms";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import { useNavigate } from "react-router-dom";
import { submitScriptAttestmentInformation } from "../../../../../data/mutations/NewPatient/submitScriptAttestmentInformation";

import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import {
  Typography,
  TypographyField,
  Stack,
  useMediaQuery,
  Button,
  Box,
  Icon,
  IconButton,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { teleprompterScript } from "./TeleprompterScript";
import { Add, Remove, TextDecrease, TextIncrease } from "@mui/icons-material";

export const ReviewScript = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [toggleFacilitator, setToggleFacilitator] = useState(null);
  const [safeToSubmit, setSafeToSubmit] = useState(false);
  const [editScript, setEditScript] = useState(false);
  const [toggleScriptAttested, setToggleScriptAttested] = useState();
  const [originalScript, setOriginalScript] = useState();

  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [script, setScript] = useState("");
  const [textSize, textSizeDispatch] = useReducer(
    (state, action) => {
      if (action.type === "increment") {
        return {
          size: state.size + 2,
        };
      } else if (action.type === "decrement") {
        return {
          size: state.size - 2,
        };
      }

      throw Error("Unknown Action");
    },
    { size: 12 }
  );

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  useEffect(() => {
    if (chart) {
      if (
        chart.SCRIPT_REVIEW?.telePrompterScript &&
        chart.SCRIPT_REVIEW?.attestedCode ===
          chart.SELECTED_TREATMENT_OPTION?.code
      ) {
        setScript(chart.SCRIPT_REVIEW.telePrompterScript);
        setOriginalScript(chart.SCRIPT_REVIEW.telePrompterScript);
      } else {
        setScript(teleprompterScript(chart));
        setOriginalScript(teleprompterScript(chart));
      }
    }
  }, [chart]);

  useEffect(() => {
    if (formRef?.current?.values?.telePrompterScript) {
      setScript(formRef.current.values.telePrompterScript);
    }
  }, [editScript]);

  useEffect(() => {
    if (formRef?.current?.values?.editScript === false) {
      setEditScript(false);
    } else if (formRef?.current?.values?.editScript === true) {
      setEditScript(true);
    }
  }, [formRef]);

  return (
    <div className="flex flex-col items-center">
      {chart && script !== "" ? (
        <Formik
          innerRef={formRef}
          enableReinitialize={false}
          initialValues={{
            uid: chart?.PATIENT_INFO_1?.uid,
            firstName: chart?.PATIENT_INFO_1?.firstName || "",
            scriptAttested: chart?.SCRIPT_REVIEW?.scriptAttested || "",
            editScript: chart?.SCRIPT_REVIEW?.editScript || false,
            telePrompterScript: script,
            attestedCode: chart?.SELECTED_TREATMENT_OPTION?.code || "",
            attestedSelfReporting:
              chart?.SCRIPT_REVIEW?.attestedSelfReporting ||
              chart?.PATIENT_INFO_1.selfReporting,
            attestedHasLivingWill:
              chart?.SCRIPT_REVIEW?.attestedHasLivingWill ||
              chart?.OTHER_DOCUMENTS.livingWillURI != null
                ? true
                : false,
          }}
          // validationSchema={PatientInfoSchema}
          onSubmit={(values, { setSubmitting }) => {
            const newChartData = { ...previousChartData, ...values };
            setNewPatientChartData(newChartData);
            //investigate this and emulate for EducationalVideo if need be
            submitScriptAttestmentInformation({
              ...values,
              editedScript:
                teleprompterScript(chart) !== values.telePrompterScript,
            });
            navigate("/signup/video");
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
            <Form style={{ width: "100%" }}>
              <Typography
                id="video-educational-title"
                variant="h4"
                sx={{
                  color: "#1B405A",
                  fontSize: "32px",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 700,
                  margin: "29px 0 29px 0",
                  width: "fit-content",
                }}
              >
                Review Your Script
              </Typography>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{ height: "auto", width: "70%", marginTop: "32px" }}
                >
                  {!editScript &&
                  (formRef?.current?.values?.telePrompterScript !== null ||
                    "") ? (
                    <Typography
                      sx={{
                        fontSize: textSize.size,
                        margin: "16px 8px",
                        color: "#1B405A",
                        textAlign: "center",
                      }}
                    >
                      {script}
                    </Typography>
                  ) : null}
                  {editScript ? (
                    <FormikTextArea
                      label="Those present at the time of completion."
                      name="telePrompterScript"
                      required={true}
                      type="textarea"
                      editable={true}
                      rows="34"
                      style={{
                        fontSize: textSize.size,
                        borderRadius: "4px",
                        margin: "7px 0",
                        width: "100%",
                        border: "1px solid #0000003B",
                      }}
                      handleChange={handleChange}
                    />
                  ) : null}
                </Box>
                <Stack direction={"row"} spacing={5}>
                  <IconButton
                    onClick={() => textSizeDispatch({ type: "increment" })}
                  >
                    <TextIncrease />
                  </IconButton>
                  <IconButton
                    onClick={() => textSizeDispatch({ type: "decrement" })}
                  >
                    <TextDecrease />
                  </IconButton>
                </Stack>
                <Button
                  name="editScript"
                  disabled={!chart?.FACILITATOR_INFO?.facilitator}
                  value={!editScript}
                  onClick={(e) => {
                    handleChange(e);
                    setEditScript(!editScript);
                    setToggleScriptAttested(null);
                  }}
                  variant="contained"
                  sx={{
                    width: "18vw",
                    minWidth: "fit-content",
                    height: "50px",
                    padding: 1,
                    borderRadius: "4px 0px 0px 4px",
                    textTransform: "none",
                    color: editScript ? "#FFFFFF" : "#00000099",
                    fontSize: "16px",
                    backgroundColor: editScript ? "#4B8596" : "#FFFFFF",
                  }}
                >
                  {editScript ? "Save Script" : "Edit Script"}
                </Button>
                {editScript && (
                  <Button
                    name="discardScript"
                    disabled={!chart?.FACILITATOR_INFO?.facilitator}
                    onClick={(e) => {
                      formRef.current.setFieldValue(
                        "telePrompterScript",
                        originalScript
                      );
                      setEditScript(false);
                      setToggleScriptAttested(null);
                    }}
                    variant="contained"
                    sx={{
                      width: "18vw",
                      minWidth: "fit-content",
                      height: "50px",
                      padding: 1,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color: "#00000099",
                      fontSize: "16px",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Discard Edits
                  </Button>
                )}
                <Typography
                  sx={{
                    margin: "16px 8px",
                    color: "#1B405A",
                    textAlign: "center",
                  }}
                >
                  I attest that I've reviewed and approve the script:
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    width: "fit-content",
                    height: "fit-content",
                    margin: "7px auto 38px auto",
                  }}
                >
                  <Button
                    name="scriptAttested"
                    disabled={editScript}
                    value={true}
                    onClick={(e) => {
                      setToggleScriptAttested(true);
                      handleChange(e);
                    }}
                    variant="contained"
                    sx={{
                      width: "18vw",
                      height: "60px",
                      padding: 0,
                      borderRadius: "4px 0px 0px 4px",
                      textTransform: "none",
                      color:
                        toggleScriptAttested === true ? "#FFFFFF" : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleScriptAttested === true ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    I ATTEST
                  </Button>
                  <Button
                    name="scriptAttested"
                    value={false}
                    disabled={editScript}
                    onClick={(e) => {
                      setToggleScriptAttested(false);
                      handleChange(e);
                    }}
                    variant="contained"
                    sx={{
                      width: "18vw",
                      height: "60px",
                      padding: 0,
                      borderRadius: "0px 4px 4px 0px",
                      textTransform: "none",
                      color:
                        toggleScriptAttested === false
                          ? "#FFFFFF"
                          : "#00000099",
                      fontSize: "16px",
                      backgroundColor:
                        toggleScriptAttested === false ? "#4B8596" : "#FFFFFF",
                    }}
                  >
                    I DO NOT ATTEST
                  </Button>
                </Stack>
                <div style={{ margin: "0 auto", width: "fit-content" }}>
                  <button
                    type="submit"
                    disabled={toggleScriptAttested !== true || editScript}
                    className="mb-6 mt-6 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-md font-large rounded-md text-white bg-mideoformblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    I'm Ready to Record{" "}
                    <ChevronRightIcon sx={{ fontSize: "32px" }} />
                  </button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
