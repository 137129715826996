import { setDoc, doc } from "firebase/firestore";
import { db } from "../../constants/firebase";

import {
  abdominal,
  cardiac,
  chiefComplaint,
  consultReason,
  endocrine,
  examGeneral,
  examSkin,
  extremity,
  gastrointestinal,
  generalConstitutional,
  genitourinary,
  heart,
  heent,
  hemaltologic,
  hhent,
  immunologic,
  lungs,
  musculoskeletal,
  neck,
  neuro,
  neurological,
  psych,
  psychiatric,
  respiratory,
  skin,
} from "./PlaceHolders/";

export const submitChartDefaults = async (chart) => {
  const patientId = chart.PATIENT_INFO_1.uid
  
  const CONSULTATION_DEFAULTS = {
    abdominal: abdominal(),
    cardiac: cardiac(),
    complaint: chiefComplaint(chart),
    reason: consultReason(chart),
    endocrine: endocrine(),
    examGeneral: examGeneral(),
    examSkin: examSkin(),
    extremity: extremity(),
    gastrointestinal: gastrointestinal(),
    general: generalConstitutional(),
    genitourinary: genitourinary(),
    heart: heart(),
    heent: heent(),
    all: immunologic(),
    hematologic: hemaltologic(),
    hhent: hhent(),
    musculoskeletal: musculoskeletal(),
    lungs: lungs(),
    neck: neck(),
    neuro: neuro(),
    neurological: neurological(),
    psych: psych(),
    psychiatric: psychiatric(),
    respiratory: respiratory(),
    skin: skin(),
  };

  const DIAGNOSTICS_DEFAULTS = {
    recordsRequested: "Yes",
    reviewed: "Yes",
    education: "50 mins.",
  };

  const STATUS = {
    cardExpiryDate: "",
    cardIssueDate: "",
    isOpen: true,
  };

  const consultationDoc = doc(db, "users", patientId, "Chart", "CONSULTATION")
  setDoc(consultationDoc, CONSULTATION_DEFAULTS, { merge: true })
  
  const diagnosticsDoc = doc(db, "users", patientId, "Chart", "DIAGNOSTICS")
  setDoc(diagnosticsDoc, DIAGNOSTICS_DEFAULTS, { merge: true })
    
  const statusDoc = doc(db, "users", patientId, "Chart", "STATUS")
  setDoc(statusDoc, STATUS, { merge: true })
  
};
