import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitContactInformation = async (values) => {
  const basePatientId = values.uid;

  const HEALTHCARE_AGENT_INFO = {
    agent: values.agent,
    street: values.agentAddress,
    city: values.agentCity,
    state: values.agentState,
    ZIP: values.agentZIP,
    country: values.agentCountry,
    homePhone: values.agentHomePhone,
    cellPhone: values.agentCellPhone,
    email: values.agentEmail,
    agentToPatient: values.agentToPatient,
    patientToAgent: values.patientToAgent,
    returning: true,
  };

  const DOCTOR_INFO = {
    doctor: values.doctor,
    drStreetAddress: values.drStreetAddress,
    drCity: values.drCity,
    drPhone: values.drPhone,
    drState: values.drState,
    drZIP: values.drZIP,
    drCountry: values.drCountry,
    hospital: values.hospital,
    hospitalStreetAddress: values.hospitalStreetAddress,
    hospitalCity: values.hospitalCity,
    hospitalState: values.hospitalState,
    hospitalZIP: values.hospitalZIP,
    hospitalCountry: values.hospitalCountry,
    attorney: values.attorney,
    attorneyPhone: values.attorneyPhone,
    attorneyStreetAddress: values.attorneyStreetAddress,
    attorneyCity: values.attorneyCity,
    attorneyState: values.attorneyState,
    attorneyZIP: values.attorneyZIP,
    attorneyCountry: values.attorneyCountry,
    returning: true,
  };

  const hcaDocRef = doc(
    db,
    "users",
    basePatientId,
    "Form",
    "HEALTHCARE_AGENT_INFO"
  );
  await setDoc(hcaDocRef, HEALTHCARE_AGENT_INFO);

  const doctorDocRef = doc(db, "users", basePatientId, "Form", "DOCTOR_INFO");
  await setDoc(doctorDocRef, DOCTOR_INFO);
};
