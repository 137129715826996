import { doc, setDoc } from "firebase/firestore";
import { db } from "../../constants/firebase";

export const submitMedicareInformation = async (values) => {
  const MEDICARE_INFO = {
    recipient: values.medicare || "",
    medicareDescriptors:
      Array.from(values.medicareDescriptors, (item) => item || "") || [],
    planName: values.planName || "",
    medicareCardFrontURI: values.medicareCardFrontURI || "",
    medicareCardBackURI: values.medicareCardBackURI || "",
    medicareCardFrontURI2: values.medicareSupplementOtherCardFrontURI || "",
    medicareCardBackURI2: values.medicareSupplementOtherCardBackURI || "",
  };
  console.log(MEDICARE_INFO.medicareDescriptors);
  const medicareDocRef = doc(db, "users", values.uid, "Form", "MEDICARE_INFO");
  await setDoc(medicareDocRef, MEDICARE_INFO);
};
