import {
  collection,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  getFirestore,
} from "firebase/firestore";
// import { db } from "../../constants/firebase";
const db = getFirestore();

export const generateFirestoreBasePatient = async (patientId) => {
  const BASE_INFO = {
    uid:patientId,
    applicationStatus: "open",
    source: "web",
    cardStatus: {
      cardPrinted: {
        description: "Text verified",
        isComplete: false,
        sortOrder: 5,
      },
      codeCorrect: {
        description: "Confirmed QR code links to correct video.",
        isComplete: false,
        sortOrder: 6,
      },
      imageCorrect: {
        description: "Patient photo verified.",
        isComplete: false,
        sortOrder: 2,
      },
      releaseApproved: {
        description: "Card released.",
        isComplete: false,
        sortOrder: 7,
      },
      textVerified: {
        description: "Card text verified.",
        isComplete: false,
        sortOrder: 1,
      },
      videoProcessed: {
        description: "Video processed.",
        isComplete: false,
        sortOrder: 4,
      },
      videoVerified: {
        description: "Video verified.",
        isComplete: false,
        sortOrder: 3,
      },
    },
    filterOptions: {
      isArchived: false,
      createdOn: Date.now(),
    },
    isArchived: false,
    roles: {
      isPatient: true,
    },
  }
  
  const baseInfoChartRef = doc(
    db,
    "users",
    patientId
  );

  await setDoc(baseInfoChartRef, BASE_INFO, { merge: true });  
};
