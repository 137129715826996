import React, { useRef, useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextInput,
} from "../../../../Features/Forms";
import { newPatientId } from "../../../../../data/localState/newPatientId";
import { useNavigate } from "react-router-dom";

import {
  newPatientChartData,
  setNewPatientChartData,
} from "../../../../../data/localState/newPatientChartData";
import { getPatientChart } from "../../../../../data/mutations/NewPatient/getPatientChart";
import { currentUser } from "../../../../../data/localState/currentUser";
import {
  Typography,
  TypographyField,
  Stack,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SampleCard } from "./SampleCard";
import { submitSampleCardInformation } from "../../../../../data/mutations/NewPatient/submitSampleCardInformation";
import { updateFilterOptions } from "../../../../../data/mutations/NewPatient/updateFilter";
import { sendCardCreatedNotification } from "../../../../../data/mutations/NewPatient/sendCardCreatedNotification";
import { createCardInfoField } from "../../../../../data/mutations/NewPatient/createCardInfoField";
import { setInitialNeedsReview } from "../../../../../data/mutations/NewPatient/setIntialNeedsReview";
import { setInitialPatientRole } from "../../../../../data/mutations/NewPatient/setIntialPatientRole";
import { setInitialCardStatus } from "../../../../../data/mutations/NewPatient/setInitialCardStatus";
import { submitChartDefaults } from "../../../../../data/mutations/NewPatient/submitChartDefaults";
import { submitGoalsInformation } from "../../../../../data/mutations/NewPatient/submitGoalsInformation";
import { setInitialChartInfo } from "../../../../../data/mutations/NewPatient/setInitialChartInfo";

export const YourSampleCard = () => {
  // const patientId = newPatientId.use();
  const previousChartData = newPatientChartData.use();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const chart = newPatientChartData.use();
  const [toggleFacilitator, setToggleFacilitator] = useState(null);
  const [patientId, setPatientId] = useState();
  const patient = currentUser.use();
  const [chart, setChart] = useState();
  const [productCost, setProductCost] = useState(0);
  const [safeToSubmit, setSafeToSubmit] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [educationalVideoUri, setEducationalVideoUri] = useState(
    "https://storage.googleapis.com/mideoid-console-public/videos/instructional/MIDEO-App%20Patient%20Education.mp4"
  );

  useEffect(() => {
    if (patient) {
      setPatientId(patient.uid);
    }
  }, [patient]);

  useEffect(() => {
    const GetChart = (async () => {
      const oldChart = await getPatientChart(patientId);
      setChart(oldChart.chart);
    })();
  }, [patientId]);

  return (
    <div className="flex flex-col items-center">
      {chart ? (
        <Formik
          innerRef={formRef}
          enableReinitialize={false}
          initialValues={{
            uid: chart?.PATIENT_INFO_1?.uid,
            firstName: chart?.PATIENT_INFO_1?.firstName || "",
            facilitator: chart?.FACILITATOR_INFO?.facilitator || "",
            facilitatorID: chart?.FACILITATOR_INFO?.facilitatorID || "",
            siteID: chart?.FACILITATOR_INFO?.siteID || "",
            submitDate: chart?.SAMPLE_CARD?.submitDate || "",
          }}
          // validationSchema={PatientInfoSchema}
          onSubmit={(values, { setSubmitting }) => {
            values.submitDate = Date().toLocaleString();
            const newChartData = { ...chart, SAMPLE_CARD: { ...values } };
            setNewPatientChartData(newChartData);
            console.log("CHART", chart);
            setInitialCardStatus(chart);
            setInitialNeedsReview(chart);
            createCardInfoField(chart);
            setInitialChartInfo(newChartData);

            updateFilterOptions({
              DOB: chart?.PATIENT_INFO_1.DOB,
              gender: chart?.PATIENT_INFO_1.gender,
              applicationStatus: "closed",
              isArchived: false,
              ACPE: chart?.FACILITATOR_INFO?.facilitatorID,
              siteID: chart?.FACILITATOR_INFO?.siteID,
            });
            setInitialPatientRole(chart);
            submitGoalsInformation({ ...chart, uid: patientId });
            submitChartDefaults(chart);
            submitSampleCardInformation(values);
            sendCardCreatedNotification(chart);
            navigate("/");
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, handleSubmit }) => (
            <Form>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1B405A",
                    fontSize: "32px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 700,
                    margin: "29px 0 29px 0",
                    width: "fit-content",
                  }}
                >
                  Thank You!
                </Typography>
                <Typography
                  sx={{
                    margin: "16px 8px",
                    color: "#1B405A",
                    textAlign: "center",
                  }}
                >
                  THIS CARD IS NOT READY FOR USE UNTIL IT IS REVIEWED BY A
                  PHYSICIAN.
                </Typography>
                <Typography
                  style={{
                    margin: "16px 8px 0px 8px",
                    color: "#1B405A",
                    textAlign: "center",
                  }}
                >
                  Further instructions will be emailed and mailed to you.
                </Typography>

                <Button
                  name="submitDate"
                  type="submit"
                  value={Date().toLocaleString()}
                  onClick={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                  variant="contained"
                  sx={{
                    width: "28vw",
                    height: "100px",
                    padding: 0,
                    margin: "0 auto 0 auto",
                    borderRadius: "4px 4px 4px 4px",
                    TypographyTransform: "none",
                    color: toggleFacilitator === true ? "#FFFFFF" : "#00000099",
                    fontSize: "16px",
                    backgroundColor:
                      toggleFacilitator === true ? "#4B8596" : "#FFFFFF",
                  }}
                >
                  Submit Application & Exit
                </Button>
                <SampleCard />
              </Stack>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
